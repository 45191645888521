import { createStore } from 'vuex';

const store = createStore({
  state: {
    serviceCenters: '[]',
    banks: '[]',

    invitations: '[]',
    routes: '[]',
    incidents: '[]',
    vehicles: '[]',
    closures: '[]',
    pendingClosures: '[]',
    accountData: {},

    accountAvatar: '',
    vehicleTypes: '[]',
    permissions: [],
    drivers: [],

    apiKey: '',
  },

  mutations: {
    setAccountAvatar(state, accountAvatar) {
      state.accountAvatar = accountAvatar;
    },

    setAccountData(state, accountData) {
      state.accountData = accountData;
    },
    setApiKey(state, apiKey) {
      state.apiKey = apiKey;
    },
    setVehicleTypes(state, vehicleTypes) {
      state.vehicleTypes = JSON.stringify(vehicleTypes);
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setDrivers(state, drivers) {
      state.drivers = drivers;
    },
    addToDrivers(state, newValue) {
      state.drivers.push(newValue);
    },
    updateDriver(state, { i, data }) {
      state.drivers[i] = data;
    },
    updateDriverProperty(state, { i, property, newValue }) {
      if (property == 'daysExpirationCnh') {
        state.drivers[i].daysExpirationCnh = newValue;
      } else if (property == 'daysExpirationPgr') {
        state.drivers[i].daysExpirationPgr = newValue;
      } else if (property == 'serviceCenterId') {
        state.drivers[i].serviceCenterId = newValue;
      } else if (property == 'accountName') {
        state.drivers[i].accountName = newValue;
      } else if (property == 'whatsapp') {
        state.drivers[i].whatsapp = newValue;
      } else if (property == 'avatar') {
        state.drivers[i].avatar = newValue;
      } else if (property == 'statusId') {
        state.drivers[i].statusId = newValue;
      }
    },
    spliceDrivers(state, { i, qnt }) {
      state.drivers.splice(i, qnt);
    },

    setInvitations(state, invitations) {
      state.invitations = JSON.stringify(invitations);
    },
    setInvitation(state, invitation) {
      const invitations = JSON.parse(state.invitations);
      for (let i in invitations) {
        if (
          invitations[i].id == invitation.id &&
          invitations[i].invitationId == invitation.invitationId
        ) {
          invitations[i] = invitation;
          state.invitations = JSON.stringify(invitations);
          return;
        }
      }
    },

    insertFirstInvitations(state, invitation) {
      const invitations = JSON.parse(state.invitations);
      invitations.unshift(invitation);
      state.invitations = JSON.stringify(invitations);
    },

    setRoutes(state, routes) {
      state.routes = JSON.stringify(routes);
    },

    setRoute(state, route) {
      const routes = JSON.parse(state.routes);
      for (let i in routes) {
        if (routes[i].id == route.id && routes[i].routeId == route.routeId) {
          routes[i] = route;
          state.routes = JSON.stringify(routes);
          return;
        }
      }
    },

    setServiceCenters(state, serviceCenters) {
      state.serviceCenters = JSON.stringify(serviceCenters);
    },
    setBanks(state, banks) {
      state.banks = JSON.stringify(banks);
    },

    setVehicles(state, vehicles) {
      state.vehicles = JSON.stringify(vehicles);
    },
    setVehicle(state, vehicle) {
      const vehicles = JSON.parse(state.vehicles);
      for (let i in vehicles) {
        if (vehicles[i].id == vehicle.id) {
          vehicles[i] = vehicle;
          state.vehicles = JSON.stringify(vehicles);
          return;
        }
      }
    },
    addVehicle(state, vehicle) {
      const vehicles = JSON.parse(state.vehicles);
      vehicles.push(vehicle);
      state.vehicles = JSON.stringify(vehicles);
    },

    setIncidents(state, incidents) {
      state.incidents = JSON.stringify(incidents);
    },
    setIncident(state, incident) {
      const incidents = JSON.parse(state.incidents);
      for (let i in incidents) {
        if (incidents[i].id == incident.id) {
          incidents[i] = incident;
          state.incidents = JSON.stringify(incidents);
          return;
        }
      }
    },
    addIncident(state, incident) {
      const incidents = JSON.parse(state.incidents);
      incidents.push(incident);
      state.incidents = JSON.stringify(incidents);
    },

    setClosures(state, closures) {
      state.closures = JSON.stringify(closures);
    },

    setPendingClosures(state, pendingClosures) {
      state.pendingClosures = JSON.stringify(pendingClosures);
    },
    addPendingClosuresMessage(state, params) {
      const pendingClosures = JSON.parse(state.pendingClosures);
      for (let i in pendingClosures) {
        if (pendingClosures[i].id == params.registerId) {
          pendingClosures[i].messages.unshift(params.message);
        }
      }
      state.pendingClosures = JSON.stringify(pendingClosures);
    },

    clear(state) {
      state.token = null;
      state.accountData = {};
      state.serviceCenters = [];
      state.banks = [];
      state.invitations = '[]';
      state.routes = '[]';
      state.incidents = '[]';
      state.vehicles = '[]';
      state.closures = '[]';
      state.pendingClosures = '[]';
    },
  },

  actions: {},

  getters: {
    accountAvatar(state) {
      return state.accountAvatar;
    },
    apiKey(state) {
      return state.apiKey;
    },
    vehicleTypes(state) {
      return JSON.parse(state.vehicleTypes);
    },
    token(state) {
      return state.token;
    },
    accountData(state) {
      return state.accountData;
    },

    drivers(state) {
      return state.drivers;
    },

    permissions(state) {
      return state.permissions;
    },

    serviceCenters(state) {
      return JSON.parse(state.serviceCenters);
    },
    serviceCenter(state) {
      return (serviceCenterId) => {
        for (const serviceCenter of JSON.parse(state.serviceCenters)) {
          if (serviceCenter.id == serviceCenterId) {
            return serviceCenter;
          }
        }
      };
    },

    banks(state) {
      return JSON.parse(state.banks);
    },

    invitations(state) {
      return JSON.parse(state.invitations);
    },
    invitation(state) {
      return (invitationId) => {
        for (const invitation of JSON.parse(state.invitations)) {
          if (invitation.id == invitationId) {
            return invitation;
          }
        }
      };
    },

    routes(state) {
      return JSON.parse(state.routes);
    },
    route(state) {
      return (routeId) => {
        for (const route of JSON.parse(state.routes)) {
          if (route.routeId == routeId) {
            return route;
          }
        }
      };
    },

    vehicles(state) {
      return JSON.parse(state.vehicles);
    },
    vehicle(state) {
      return (vehicleId) => {
        for (const vehicle of JSON.parse(state.vehicles)) {
          if (vehicle.id == vehicleId) {
            return vehicle;
          }
        }
      };
    },

    incidents(state) {
      return JSON.parse(state.incidents);
    },
    incident(state) {
      return (incidentId) => {
        for (const incident of JSON.parse(state.incidents)) {
          if (incident.id == incidentId) {
            return incident;
          }
        }
      };
    },

    closures(state) {
      return JSON.parse(state.closures);
    },
    closure(state) {
      return (closureId) => {
        for (const closure of JSON.parse(state.closures)) {
          if (closure.id == closureId) {
            return closure;
          }
        }
      };
    },

    pendingClosures(state) {
      return JSON.parse(state.pendingClosures);
    },
    pendingClosureRoute(state) {
      return (routeId) => {
        for (const pendingClosure of JSON.parse(state.pendingClosures)) {
          if (pendingClosure.routeId == routeId) {
            return pendingClosure;
          }
        }
      };
    },
  },
});

export default store;
