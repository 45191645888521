function getToday() {
  let date = new Date();

  let day = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }

  let month = date.getMonth();
  month += 1;
  if (month < 10) {
    month = `0${month}`;
  }

  return `${date.getFullYear()}-${month}-${day}`;
}

function getTomorrow() {
  let date = new Date();

  let d = new Date(getToday());

  d.setDate(d.getDate() + 2);

  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return `${date.getFullYear()}-${month}-${day}`;
}

function getTodaysLater(days) {
  let now = new Date();

  let daysLater = new Date(now.setDate(now.getDate() + days));

  let day = daysLater.getDate();
  if (day < 10) {
    day = `0${day}`;
  }

  let month = daysLater.getMonth();
  month += 1;
  if (month < 10) {
    month = `0${month}`;
  }

  return `${daysLater.getFullYear()}-${month}-${day}`;
}

function getTime() {
  let date = new Date();

  let hours = date.getHours();
  if (hours < 10) {
    hours = `0${hours}`;
  }

  let minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  let seconds = date.getSeconds();
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${hours}:${minutes}:${seconds}`;
}

function differenceDays(dateOne, dateTwo) {
  const date1 = new Date(`${dateOne} 00:00`);
  const date2 = new Date(`${dateTwo} 00:00`);

  const diffMilisseconds = date2 - date1;

  const diffSeconds = diffMilisseconds / 1000;
  const diffMinutes = diffSeconds / 60;
  const diffHours = diffMinutes / 60;
  const diffDays = diffHours / 24;

  return parseInt(diffDays);
}

export default {
  getToday,
  getTodaysLater,
  getTomorrow,
  getTime,
  differenceDays,
};
