<script setup>
import { InvitationService } from '@/services/InvitationService';
import { ServiceCenterService } from '@/services/ServiceCenterService';
import VueElementLoading from 'vue-element-loading';
import date from '@/utils/date';
import { Modal } from 'bootstrap';
import nprogress from 'nprogress';
import { nextTick, onMounted, reactive, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { add, format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useStorage } from '@vueuse/core';
import globals from '@/utils/globals';

const headers = [
  {
    text: 'Data',
    value: 'routeDate',
  },
  {
    text: 'Onda',
    value: 'wave',
  },
  {
    text: 'Motoristas',
    value: 'numberVehicles',
  },
  {
    text: 'Base',
    value: 'serviceCenter',
  },
  {
    text: 'Ações',
    value: 'actions',
  },
];

const toast = useToast();

const serviceCenterService = new ServiceCenterService();
const invitationService = new InvitationService();

const loading = ref(false);
const isLoading = ref(false);

const invitationNumberStats = reactive({
  numberToSend: 0,
  numberToRemove: 0,
  numberConfirmed: 0,
  numberPending: 0,
  numberRefused: 0,
});

const masks = ref({
  modelValue: 'YYYY-MM-DD',
});

const filterInvitations = reactive({
  date: date.getToday(),
  serviceCenterId: null,
  today: '',
  tomorrow: '',
  dateCaption: '',
});

const newInvitationData = reactive({
  invitationType: '',
  routeDate: date.getToday(),
  wave: 1,
  routeTime: '',
  numberVehicles: null,
  serviceCenterId: null,
  comments: '',
  mainRouteId: 1,
});

const invitationDetails = reactive({
  comments: '',
  drivers: [],
  id: null,
  invitationType: 1,
  mainRouteId: null,
  message: '',
  now: '',
  numberVehicles: '',
  routeDate: date.getToday(),
  routeTime: '',
  serviceCenter: '',
  serviceCenterId: null,
  wave: '',
});

const myServiceCenters = ref([]);
const invitations = ref([]);
const driversNotInvited = ref([]);

const modals = reactive({
  modalInvited: null,
  modalSendInvites: null,
  modalUpdateInvitation: null,
  modalNewInvitation: null,
  modalFilter: null,
  modalSearchInvitationServiceCenter: null,
});

const modalInvitedRef = ref();
const modalInvitedInterval = ref(0);

const preferences = useStorage(
  `@${sessionStorage.getItem('accountName')}-preferences`,
  {
    baseId: 0,
    name: '',
  },
);

onMounted(async () => {
  initializeModals();
  await getMyServiceCenters();
  await getInvitations();

  if (preferences.value.baseId) {
    filterInvitations.serviceCenterId = preferences.value.baseId;
  }

  modalInvitedRef.value.addEventListener('shown.bs.modal', () => {
    modalInvitedInterval.value = setInterval(() => {
      refreshInvitations();
    }, 30000);
  });
  modalInvitedRef.value.addEventListener('hidden.bs.modal', () => {
    clearInterval(modalInvitedInterval.value);
  });
});

async function getMyServiceCenters() {
  nprogress.start();

  const { data, status } = await serviceCenterService.myServiceCenters();

  if (status && status === 200) {
    myServiceCenters.value = data;
  }

  nprogress.done();
}

async function refreshInvitations() {
  nprogress.start();

  const formattedTodayDate = Intl.DateTimeFormat('pt-BR', {
    dateStyle: 'full',
  }).format(new Date());

  filterInvitations.dateCaption = formattedTodayDate;

  const response = await invitationService.filterInvitations({
    date: filterInvitations.date,
    serviceCenterId: filterInvitations.serviceCenterId,
  });

  if (response && response.status === 200) {
    invitations.value = response.data;
    viewInvitationDrivers({ id: invitationDetails.id });
  }

  nprogress.done();
}

async function getInvitations() {
  loading.value = true;

  const formattedTodayDate = Intl.DateTimeFormat('pt-BR', {
    dateStyle: 'full',
  }).format(new Date());

  filterInvitations.dateCaption = formattedTodayDate;

  const response = await invitationService.filterInvitations({
    date: filterInvitations.date,
    serviceCenterId: filterInvitations.serviceCenterId,
  });

  if (response && response.status === 200) {
    invitations.value = response.data;
  }

  loading.value = false;
}

async function getFilteredInvitations({ date }) {
  isLoading.value = true;

  if (!date) {
    isLoading.value = false;
    return toast.error('Selecione uma data.');
  }

  const formattedDateCaption = Intl.DateTimeFormat('pt-BR', {
    dateStyle: 'full',
  }).format(parseISO(date));

  filterInvitations.dateCaption = formattedDateCaption;

  const response = await invitationService.filterInvitations({
    date,
    serviceCenterId: filterInvitations.serviceCenterId,
  });

  if (response && response.status === 200) {
    invitations.value = response.data;

    nextTick(() => {
      modals.modalFilter.hide();
    });
  }

  isLoading.value = false;
}

async function createInvitation() {
  let day = `${date.getToday()} ${date.getTime()}`;
  let routeDate = `${newInvitationData.routeDate} ${newInvitationData.routeTime}`;
  if (dateDiff(routeDate, day)) {
    return toast.error('Não é possível inserir data/horários que já passaram.');
  }

  isLoading.value = true;

  const response = await invitationService.createInvitation({
    wave: newInvitationData.wave,
    routeDate: newInvitationData.routeDate,
    routeTime: newInvitationData.routeTime,
    numberVehicles: newInvitationData.numberVehicles,
    serviceCenterId: newInvitationData.serviceCenterId,
    comments: newInvitationData.comments,
  });

  if (response && response.status === 201) {
    filterInvitations.date = newInvitationData.routeDate;
    toast.success('Convite criado com sucesso.');
    newInvitationData.routeDate = date.getToday();
    newInvitationData.wave = 1;
    newInvitationData.routeTime = '';
    newInvitationData.numberVehicles = null;
    newInvitationData.comments = '';
    await getInvitations();
    nextTick(() => {
      modals.modalNewInvitation.hide();
    });
    await checkNDrivers({ id: response.data.id });
  }

  isLoading.value = false;
}

async function updateInvitation() {
  isLoading.value = true;

  let day = `${date.getToday()} ${date.getTime()}`;

  let routeDate = `${invitationDetails.routeDate} ${invitationDetails.routeTime}`;
  if (dateDiff(routeDate, day)) {
    return toast.error('Não é possível inserir data/horários que já passaram.');
  }

  const response = await invitationService.updateInvitation({
    id: invitationDetails.id,
    wave: invitationDetails.wave,
    routeDate: invitationDetails.routeDate,
    routeTime: invitationDetails.routeTime,
    numberVehicles: invitationDetails.numberVehicles,
    serviceCenterId: invitationDetails.serviceCenterId,
    comments: invitationDetails.comments,
  });

  if (response && response.status == 204) {
    refreshNumbers({ id: invitationDetails.id });
    getInvitations();
    toast.success('Convite atualizado com sucesso.');
    nextTick(() => {
      modals.modalUpdateInvitation.hide();
    });
    // modals.modalInvited.show();
  }

  isLoading.value = false;
}

async function sendInvitations() {
  const invitationExists = invitations.value.find(
    (invitation) => invitation.id === invitationDetails.id,
  );

  if (!invitationExists) {
    return toast.error('Não é possível enviar convites.');
  }

  if (
    Number(invitationExists.numberVehicles) ===
    invitationNumberStats.numberConfirmed
  ) {
    return toast.error('O total de motoristas necessários já confirmaram.');
  }
  isLoading.value = true;

  let drivers = [];
  driversNotInvited.value.map((driver) => {
    if (driver.checked) {
      drivers.push({
        accountId: driver.accountId,
        email: driver.email,
      });
    }
  });

  const response = await invitationService.sendInvitations({
    id: invitationExists.id,
    drivers,
  });

  if (response && response.status === 201) {
    toast.success('Convites enviados com sucesso.');
    await getInvitations();
    refreshNumbers({ id: invitationExists.id });

    viewInvitationDrivers({ id: invitationExists.id });

    modals.modalSendInvites.hide();
  }

  isLoading.value = false;
}

function viewInvitationDrivers({ id }) {
  const invitationExists = invitations.value.find(
    (invitation) => invitation.id === id,
  );

  if (!invitationExists) {
    return toast.error('Não é possível visualizar os convidados.');
  }

  filterInvitations.date = invitationExists.routeDate;

  invitationDetails.id = invitationExists.id;
  invitationDetails.comments = invitationExists.comments;
  invitationDetails.drivers = invitationExists.drivers;
  invitationDetails.invitationType = invitationExists.invitationType;
  invitationDetails.mainRouteId = invitationExists.mainRouteId;
  invitationDetails.now = invitationExists.now;
  invitationDetails.numberVehicles = invitationExists.numberVehicles;
  invitationDetails.routeDate = invitationExists.routeDate;
  invitationDetails.routeTime = invitationExists.routeTime;
  invitationDetails.serviceCenter = invitationExists.serviceCenter;
  invitationDetails.serviceCenterId = invitationExists.serviceCenterId;
  invitationDetails.wave = invitationExists.wave;

  modals.modalInvited.show();
}

function viewInvitation({ id }) {
  const invitationExists = invitations.value.find(
    (invitation) => invitation.id === id,
  );

  if (!invitationExists) {
    return toast.error('Não é possível visualizar o convite.');
  }

  invitationDetails.id = invitationExists.id;
  invitationDetails.comments = invitationExists.comments;
  invitationDetails.numberVehicles = invitationExists.numberVehicles;
  invitationDetails.routeDate = invitationExists.routeDate;
  invitationDetails.routeTime = invitationExists.routeTime;
  invitationDetails.serviceCenterId = invitationExists.serviceCenterId;
  invitationDetails.wave = invitationExists.wave;

  nextTick(() => {
    modals.modalUpdateInvitation.show();
  });
}

async function deleteInvitation({ id }) {
  loading.value = true;

  const response = await invitationService.deleteInvitation({
    id,
  });

  if (response && response.status === 204) {
    await getInvitations();

    toast.success('Convite excluído com sucesso.');
  }

  loading.value = false;
}

async function deleteInvitationDriver({ driverId, invitationId }) {
  isLoading.value = true;

  const response = await invitationService.deleteInvitationDriver({
    driverId,
  });

  if (response && response.status === 204) {
    toast.success('Convite excluído com sucesso.');
    await getInvitations();
    viewInvitationDrivers({ id: invitationId });
    refreshNumbers({ id: invitationId });
  }

  isLoading.value = false;
}

function viewSendInvitation({ id }) {
  const invitationExists = invitations.value.find(
    (invitation) => invitation.id === id,
  );

  if (!invitationExists) {
    return toast.error('Não é possível enviar convites.');
  }

  if (
    Number(invitationExists.numberVehicles) ===
    invitationNumberStats.numberConfirmed
  ) {
    return toast.error('O total de motoristas necessários já confirmaram.');
  } else {
    checkNDrivers({ id: invitationExists.id });
    modals.modalInvited.hide();
    modals.modalSendInvites.show();
  }
}

async function checkNDrivers({ id }) {
  loading.value = true;

  const inviteExists = invitations.value.find((invite) => invite.id === id);

  if (!inviteExists) {
    return toast.error('Convite não encontrado.');
  }

  invitationDetails.id = inviteExists.id;

  driversNotInvited.value = [];

  const response = await invitationService.checkNDrivers({
    routeDate: inviteExists.routeDate,
    wave: inviteExists.wave,
    serviceCenterId: inviteExists.serviceCenterId,
  });

  if (response && response.status === 200) {
    driversNotInvited.value = response.data;
    refreshNumbers({ id: inviteExists.id });
    modals.modalSendInvites.show();
  }

  loading.value = false;
}

function refreshNumbers({ id }) {
  const inviteExists = invitations.value.find((invite) => invite.id === id);

  if (!inviteExists) {
    return toast.error('Convite não encontrado.');
  }

  invitationNumberStats.numberConfirmed = 0;
  invitationNumberStats.numberRefused = 0;
  invitationNumberStats.numberVehicles = 0;
  invitationNumberStats.numberPending = 0;
  invitationNumberStats.numberInvites = 0;

  invitationNumberStats.numberVehicles = Number(inviteExists.numberVehicles);
  inviteExists.drivers.map((driver) => {
    if (driver.status.accepted) {
      invitationNumberStats.numberConfirmed += 1;
    }
    if (driver.status.refused) {
      invitationNumberStats.numberRefused += 1;
    }
    if (driver.status.pending) {
      invitationNumberStats.numberPending += 1;
    }
  });
}

function setFilterData() {
  const formattedTodayDate = format(new Date(), "d 'de' MMMM 'de' yyyy", {
    locale: ptBR,
  });

  const formattedTomorrowDate = format(
    add(new Date(), {
      days: 1,
    }),
    "d 'de' MMMM 'de' yyyy",
    {
      locale: ptBR,
    },
  );

  filterInvitations.today = formattedTodayDate;
  filterInvitations.tomorrow = formattedTomorrowDate;

  modals.modalFilter.show();
}

function initializeModals() {
  modals.modalInvited = new Modal(document.getElementById('modal-invited'));

  modals.modalSendInvites = new Modal(
    document.getElementById('modal-send-invites'),
  );
  modals.modalUpdateInvitation = new Modal(
    document.getElementById('modal-update-invitation'),
  );
  modals.modalNewInvitation = new Modal(
    document.getElementById('modal-new-invitation'),
  );
  modals.modalFilter = new Modal(
    document.getElementById('modal-invitation-filter'),
  );
  // modals.modalSearchInvitationServiceCenter = new Modal(
  //   document.getElementById('modal-search-invitation-serviceCenter'),
  // );
}

function dateDiff(initial, now) {
  let a = Date.parse(initial);
  let b = Date.parse(now);

  let diff = a - b;

  if (diff > 0) {
    return false;
  }

  return true;
}
</script>

<template>
  <section class="flex-container bg-white rounded p-3 mb-3 mx-3 min-vh-100">
    <VueElementLoading
      :active="loading"
      spinner="spinner"
      color="#FF6700"
      size="60"
    />
    <div class="d-grid d-md-flex gap-1">
      <button
        type="button"
        class="btn btn-sm btn-orange"
        data-bs-toggle="modal"
        data-bs-target="#modal-new-invitation"
      >
        <font-awesome-icon icon="fa-regular fa-plus" />
        Convidar
      </button>
      <button
        type="button"
        class="btn btn-sm btn-orange ms-0 ms-md-auto"
        @click="setFilterData"
      >
        <font-awesome-icon icon="fa-regular fa-filter" />
        Filtrar
      </button>
    </div>

    <caption class="d-flex">
      <small>
        Convites para
        <b class="ms-1">{{ filterInvitations.dateCaption }}</b>
      </small>
    </caption>

    <EasyDataTable
      ref="tableInvitatios"
      :headers="headers"
      :items="invitations"
      :rows-per-page="10"
      rows-of-page-separator-message="de"
      rows-per-page-message="Linhas por página"
      table-class-name="customize-table"
      theme-color="#f35e1d"
      empty-message="Nenhum convite para esta data."
    >
      <template #item-routeDate="{ routeDate }">
        {{
          Intl.DateTimeFormat('pt-BR', {
            dateStyle: 'short',
          }).format(new Date(parseISO(routeDate)))
        }}
      </template>

      <template #item-actions="item">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-sm btn-orange"
            @click="viewInvitationDrivers({ id: item.id })"
          >
            Ver convidados
          </button>
          <button
            type="button"
            class="btn btn-sm btn-orange dropdown-toggle dropdown-toggle-split"
            id="dropdownMenuInviviewInvitation"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-bs-reference="parent"
          >
            <span class="sr-only">Dropdown</span>
          </button>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuInviviewInvitation"
          >
            <a
              class="dropdown-item"
              @click="viewInvitation({ id: item.id })"
              href="#"
            >
              Editar
            </a>
            <a
              class="dropdown-item"
              @click="deleteInvitation({ id: item.id })"
              href="#"
            >
              Excluir
            </a>
          </div>
        </div>
      </template>
    </EasyDataTable>
  </section>
  <div class="modals">
    <div
      class="modal fade"
      id="modal-new-invitation"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-fullscreen-sm-down modal-dialog-centered"
        role="document"
      >
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title">
              <font-awesome-icon icon="fa-regular fa-plus" />
              Informe os detalhes do convite
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>

          <form @submit.prevent="createInvitation">
            <div class="modal-body">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <label
                        for="newInvitationServiceCenter"
                        class="form-label"
                      >
                        Selecione a Base:
                      </label>
                      <select
                        v-model="newInvitationData.serviceCenterId"
                        class="form-select form-select-sm"
                        id="newInvitationServiceCenter"
                      >
                        <option
                          v-for="serviceCenter in myServiceCenters"
                          :key="serviceCenter.id"
                          :value="serviceCenter.id"
                        >
                          {{ serviceCenter.serviceCenter }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <label for="newInvitationWave" class="form-label">
                        Qual é a onda?
                      </label>
                      <select
                        class="form-select form-select-sm"
                        id="newInvitationWave"
                        v-model="newInvitationData.wave"
                      >
                        <option value="1">1º Onda</option>
                        <option value="2">2º Onda</option>
                        <option value="3">3ª Onda</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="newInvitationRouteDate" class="form-label">
                        Qual é a data prevista para a rota?
                      </label>
                      <VDatePicker
                        :masks="masks"
                        v-model.string="newInvitationData.routeDate"
                        mode="date"
                      >
                        <template #default="{ inputValue, inputEvents }">
                          <input
                            id="newInvitationRouteDate"
                            class="form-control form-control-sm"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </VDatePicker>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="newInvitationrouteTime" class="form-label">
                        Qual é a previsão de horário do carregamento?
                      </label>
                      <input
                        id="newInvitationrouteTime"
                        class="form-control form-control-sm"
                        type="time"
                        v-model="newInvitationData.routeTime"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label
                        for="newInvitationnumberVehicles"
                        class="form-label"
                      >
                        Quantos convites são necessários?
                      </label>
                      <input
                        v-model.number="newInvitationData.numberVehicles"
                        type="number"
                        min="0"
                        class="form-control form-control-sm"
                        id="newInvitationnumberVehicles"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="newInvitationComments" class="form-label">
                    Descreva detalhes e outras informações importantes:
                  </label>
                  <textarea
                    id="newInvitationComments"
                    maxlength="250"
                    v-model="newInvitationData.comments"
                    class="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                data-bs-dismiss="modal"
                v-if="!isLoading"
              >
                Cancelar
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                  v-if="isLoading"
                ></span>
                <span v-if="isLoading">Aguarde...</span>
                <span v-if="!isLoading">Criar convite</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-send-invites"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-fullscreen-sm-down"
        role="document"
      >
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title">
              <font-awesome-icon icon="fa-regular fa-calendar-circle-user" />
              Quem você vai convidar?
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body">
            <VueElementLoading
              :active="loading"
              spinner="spinner"
              color="#FF6700"
              size="60"
            />
            <h5 class="text-center" v-if="driversNotInvited.length === 0">
              Não existem mais motoristas para convidar nessa Base.
            </h5>
            <div v-else>
              <div
                class="bg-secondary text-white text-center p-2 rounded mt-2 mb-3"
              >
                <font-awesome-icon icon="fa-regular fa-info" class="me-2" />
                Todos os convites pendentes serão automaticamente excluídos após
                passar o horário do carregamento ou fechar a conta
              </div>

              <div class="table-responsive">
                <table class="table">
                  <thead class="bg-white text-black border-bottom">
                    <tr>
                      <th>#</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Disponibilidade</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="align-middle"
                      v-for="(driver, index) in driversNotInvited"
                      :key="driver.driverAccountId"
                    >
                      <td>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="driver.checked"
                          :id="index"
                        />
                      </td>
                      <td class="align-middle">
                        <img
                          v-if="driver.avatar !== ''"
                          class="rounded shadow"
                          style="width: 32px; height: 32px"
                          :src="driver.avatar"
                        />
                        <img
                          v-else
                          class="rounded shadow"
                          style="width: 32px; height: 32px"
                          src="/assets/img/unknow.png"
                        />

                        <span
                          v-if="
                            driver.accountTypeId ===
                            globals.ACCOUNT_TYPE_SECONDARY_DRIVER
                          "
                        >
                          {{ driver.primaryDriver.accountName }}
                          <font-awesome-icon :icon="['far', 'arrow-right']" />
                          {{ driver.accountName }}
                          <span class="badge bg-secondary">secundário</span>
                        </span>
                        <span v-else class="ms-2">
                          {{ driver.accountName }}
                        </span>
                      </td>
                      <td>
                        <span v-if="driver.available" class="badge bg-success">
                          Já confirmou disponibilidade
                        </span>
                        <span
                          v-else-if="driver.available === false"
                          class="badge bg-danger"
                        >
                          Não está disponível
                        </span>
                        <span v-else class="badge bg-secondary">
                          Não informado
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
              v-if="!isLoading"
            >
              Cancelar
            </button>
            <button
              @click="sendInvitations"
              type="button"
              class="btn btn-sm btn-success"
              :disabled="driversNotInvited.length === 0"
            >
              <span
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
                v-if="isLoading"
              ></span>
              <span v-if="isLoading">Aguarde...</span>
              <span v-if="!isLoading">Enviar convites</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-invited"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      ref="modalInvitedRef"
    >
      <div
        class="modal-dialog modal-lg modal-fullscreen-sm-down"
        role="document"
      >
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title">
              <font-awesome-icon icon="fa-regular fa-users" />
              Convidados
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body">
            <VueElementLoading
              :active="isLoading"
              spinner="spinner"
              color="#FF6700"
              size="60"
            />
            <button
              type="button"
              class="btn btn-sm btn-orange"
              @click="() => viewSendInvitation({ id: invitationDetails.id })"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              Convidar mais motoristas
            </button>

            <div
              class="alert alert-primary d-flex align-items-center my-2"
              role="alert"
            >
              <font-awesome-icon
                icon="fa-regular fa-circle-info"
                class="me-2"
              />
              <div>
                ATENÇÃO. Todos os convites pendentes serão automaticamente
                excluídos após passar o horário do carregamento ou a quantidade
                de motoristas necessários confirmar. Os convites também expiram
                após algumas horas.
              </div>
            </div>

            <span class="badge bg-secondary">
              Motoristas necessários:
              {{ invitationDetails.numberVehicles }}
            </span>
            <span class="badge bg-secondary">
              Data:
              {{
                Intl.DateTimeFormat('pt-BR').format(
                  new Date(parseISO(invitationDetails.routeDate)),
                )
              }}
            </span>

            <div
              class="bg-danger text-white text-center fw-bold p-3 rounded mt-2 mb-3"
              v-if="invitationNumberStats.numberToSend > 0"
            >
              <font-awesome-icon
                icon="fa-regular fa-triangle-exclamation"
                class="me-2"
              />
              Você deve convidar mais
              {{ invitationNumberStats.numberToSend }} para fechar a conta.
            </div>

            <div
              class="bg-danger text-white text-center fw-bold p-3 rounded mt-2 mb-3"
              v-if="invitationNumberStats.numberToRemove > 1"
            >
              <font-awesome-icon
                icon="fa-regular fa-triangle-exclamation"
                class="me-2"
              />
              Existem {{ invitationNumberStats.numberToRemove }} convites
              sobrando para fechar a conta.
            </div>

            <div
              class="bg-danger text-white text-center fw-bold p-3 rounded mt-2 mb-3"
              v-if="invitationNumberStats.numberToRemove === 1"
            >
              <font-awesome-icon
                icon="fa-regular fa-triangle-exclamation"
                class="me-2"
              />
              Existe {{ invitationNumberStats.numberToRemove }} convite sobrando
              para fechar a conta.
            </div>

            <div v-if="invitationDetails.drivers.length">
              <div class="table-responsive">
                <table class="table">
                  <thead class="bg-white text-black border-bottom">
                    <tr>
                      <th scope="col border-bottom-0">Motorista</th>
                      <th scope="col border-bottom-0">Onda</th>
                      <th scope="col border-bottom-0">Status</th>
                      <th scope="col border-bottom-0">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="driver in invitationDetails.drivers"
                      :key="driver.id"
                    >
                      <td>
                        {{ driver.accountName }}
                        <span
                          v-if="
                            driver.accountTypeId ===
                            globals.ACCOUNT_TYPE_SECONDARY_DRIVER
                          "
                          class="badge bg-secondary"
                        >
                          Secundário
                        </span>
                      </td>
                      <td>
                        {{ invitationDetails.wave }}
                      </td>
                      <td>
                        <span
                          v-if="driver.status.pending"
                          class="badge bg-secondary"
                        >
                          Pendente
                        </span>
                        <span
                          v-if="driver.status.accepted"
                          class="badge bg-success"
                        >
                          Confirmado
                        </span>
                        <span
                          v-if="driver.status.refused"
                          class="badge bg-danger"
                        >
                          Recusado
                        </span>
                      </td>
                      <td>
                        <button
                          v-if="!driver.status.accepted"
                          type="button"
                          class="btn btn-sm btn-danger"
                          @click="
                            () =>
                              deleteInvitationDriver({
                                driverId: driver.id,
                                invitationId: invitationDetails.id,
                              })
                          "
                        >
                          Retirar convite
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-center"
              v-else
              style="height: 300px"
            >
              <div class="text-center">
                <font-awesome-icon
                  icon="fa-regular fa-circle-info"
                  class="me-2 text-muted"
                  size="3x"
                />
                <p class="text-muted fw-semibold">
                  Nenhum motorista convidado.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-update-invitation"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-fullscreen-sm-down modal-dialog-centered"
        role="document"
      >
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title">
              <font-awesome-icon icon="fa-regular fa-clipboard-list" />
              Detalhes do grupo de convite
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <form @submit.prevent="updateInvitation">
            <div class="modal-body">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <label
                        for="updateInvitationServiceCenter"
                        class="form-label"
                      >
                        Selecione a Base:
                      </label>
                      <select
                        v-model="invitationDetails.serviceCenterId"
                        class="form-select form-select-sm"
                        id="updateInvitationServiceCenter"
                      >
                        <option
                          v-for="serviceCenter in myServiceCenters"
                          :key="serviceCenter.id"
                          :value="serviceCenter.id"
                        >
                          {{ serviceCenter.serviceCenter }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <label for="updateInvitationWave" class="form-label">
                        Qual é a onda?
                      </label>
                      <select
                        class="form-select form-select-sm"
                        id="updateInvitationWave"
                        v-model="invitationDetails.wave"
                      >
                        <option value="1">1º Onda</option>
                        <option value="2">2º Onda</option>
                        <option value="3">3ª Onda</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="updateInvitationRouteDate" class="form-label">
                        Qual é a data prevista para a rota?
                      </label>
                      <VDatePicker
                        :masks="masks"
                        v-model.string="invitationDetails.routeDate"
                        mode="date"
                      >
                        <template #default="{ inputValue, inputEvents }">
                          <input
                            id="updateInvitationRouteDate"
                            class="form-control form-control-sm"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </VDatePicker>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="updateInvitationrouteTime" class="form-label">
                        Qual é a previsão de horário do carregamento?
                      </label>
                      <input
                        id="updateInvitationrouteTime"
                        class="form-control form-control-sm"
                        type="time"
                        v-model="invitationDetails.routeTime"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label
                        for="updateInvitationnumberVehicles"
                        class="form-label"
                      >
                        Quantos convites são necessários?
                      </label>
                      <input
                        v-model.number="invitationDetails.numberVehicles"
                        type="number"
                        min="0"
                        class="form-control form-control-sm"
                        id="updateInvitationnumberVehicles"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="updateInvitationComments" class="form-label">
                    Descreva detalhes e outras informações importantes:
                  </label>
                  <textarea
                    id="updateInvitationComments"
                    maxlength="250"
                    v-model="invitationDetails.comments"
                    class="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                data-bs-dismiss="modal"
                v-if="!isLoading"
              >
                Cancelar
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                  v-if="isLoading"
                ></span>
                <span v-if="isLoading">Aguarde...</span>
                <span v-if="!isLoading">Salvar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-invitation-filter"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-fullscreen-sm-down" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title">
              <font-awesome-icon
                icon="fa-regular fa-clipboard-list"
                class="me-2"
              />
              Filtrar convites
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="d-grid gap-1 my-3">
                <span>
                  <strong class="me-2">Convites para hoje:</strong>
                  <a
                    href="#"
                    role="button"
                    class="link-primary"
                    @click="
                      () =>
                        getFilteredInvitations({
                          date: date.getToday(),
                        })
                    "
                  >
                    {{ filterInvitations.today }}
                  </a>
                </span>
                <span>
                  <strong class="me-2">Convites para amanhã:</strong>
                  <a
                    href="#"
                    role="button"
                    class="link-primary"
                    @click="
                      () =>
                        getFilteredInvitations({
                          date: date.getTomorrow(),
                        })
                    "
                  >
                    {{ filterInvitations.tomorrow }}
                  </a>
                </span>
              </div>
              <div class="form-group">
                <label for="filterInvitationDate" class="form-label">
                  Qual é a data para a rota?
                </label>
                <VDatePicker
                  :masks="masks"
                  v-model.string="filterInvitations.date"
                  mode="date"
                >
                  <template #default="{ inputValue, inputEvents }">
                    <input
                      class="form-control form-control-sm"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </VDatePicker>
              </div>
              <div class="form-group">
                <label for="searchInvitationServiceCenter" class="form-label">
                  Selecione a Base (Opcional):
                </label>
                <select
                  v-model="filterInvitations.serviceCenterId"
                  class="form-select form-select-sm"
                  id="searchInvitationServiceCenter"
                >
                  <option
                    v-for="serviceCenter in myServiceCenters"
                    :key="serviceCenter.id"
                    :value="serviceCenter.id"
                  >
                    {{ serviceCenter.serviceCenter }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-sm btn-success"
              @click="
                () => getFilteredInvitations({ date: filterInvitations.date })
              "
            >
              <span
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
                v-if="isLoading"
              ></span>
              <span v-if="isLoading">Aguarde...</span>
              <span v-if="!isLoading">Buscar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.link-primary {
  text-decoration: underline;
  text-underline-offset: 1px;
  font-weight: 700;
}
</style>
