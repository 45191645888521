<template>
  <div class="text-center">
    <div
      class="circular-progress-bar"
      :id="`circular-progress-bar-${elementId}`"
    ></div>
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from 'uuid';
import ProgressBar from 'progressbar.js';
import { reactive, defineProps, ref, onMounted } from 'vue';

const props = defineProps({
  options: {
    value: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'success',
    },
  },
});

const elementId = ref(uuidv4());
const colors = reactive({
  success: '#00a650',
  warning: '#f73',
  danger: '#f23d4f',
});

onMounted(() => {
  let bar = new ProgressBar.Circle(
    `#circular-progress-bar-${elementId.value}`,
    {
      strokeWidth: 6,
      easing: 'easeInOut',
      duration: 1500,
      color: colors[props.options.color],
      trailColor: '#eee',
      trailWidth: 6,
      svgStyle: null,
      text: {
        autoStyleContainer: true,
      },
    },
  );

  bar.setText(`<b>${percentText(props.options.value)}</b><small>%</small>`);
  bar.text.style.fontSize = '.8rem';

  bar.animate(props.options.value);
});

function percentText(value) {
  value = Math.round(value * 100);

  // value = value.toString();
  // if(value.length > 2){
  // 	value = value.substring(0, 2);
  // }

  return value;
}
</script>

<style scoped>
.circular-progress-bar {
  width: 50px;
  height: 50px;
}
</style>
