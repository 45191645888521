<script setup>
import { RoutesService } from '@/services/RoutesService';
import VueElementLoading from 'vue-element-loading';
import * as L from 'leaflet';
import { onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
// import 'leaflet-draw';
// import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-routing-machine';
import 'leaflet.fullscreen/Control.FullScreen.js';
import 'leaflet.fullscreen/Control.FullScreen.css';
// import polyUtil from 'polyline-encoded';
import { useToast } from 'vue-toastification';

const osrmUrl = process.env.VUE_APP_BASE_URL_API;

const route = useRoute();
const router = useRouter();
const toast = useToast();
const routesService = new RoutesService();

let map;
// let polylineDrawer;
// let drawControl;
let control;

const loading = reactive({
  state: true,
  text: 'Carregando...',
});

const editRouteData = reactive({
  autoGenerated: false,
  stops: [],
  coordinates: [],
  waypoints: [],
  waypointsResponse: [],
  waypointsControl: [],
  polyline: '',
  distanceMeters: 0,
  serviceCenterLocation: {
    lat: 0,
    lng: 0,
  },
  finalLocation: {
    lat: 0,
    lng: 0,
  },
  initialLocation: {
    lat: 0,
    lng: 0,
  },
  markerIfApp: [],
  initiPoly: [],
  flag: '',
});

onMounted(async () => {
  if (!route.params.id) {
    return router.back(1);
  }
  initMap();

  await getRouteById({ routeId: route.params.id });

  // initEditPolyline();
  initControl();
});

async function getRouteById({ routeId }) {
  loading.state = true;
  loading.text = 'Carregando rota...';
  const response = await routesService.getGeolocation({
    routeId,
  });

  if (response.status === 200) {
    editRouteData.autoGenerated = response.data.autoGenerated;
    editRouteData.stops = response.data.stops;
    editRouteData.waypoints = response.data.waypoints;
    editRouteData.polyline = response.data.polyline;
    editRouteData.distanceMeters = response.data.distanceMeters;
    editRouteData.serviceCenterLocation = response.data.serviceCenterLocation;
    editRouteData.finalLocation = response.data.finalLocation;
    editRouteData.initialLocation = response.data.initialLocation;
    editRouteData.coordinates = response.data.coordinates;
    editRouteData.flag = response.data.flag;
  }

  // const decodePolyline = polyUtil.decode(response.data.polyline);

  // editRouteData.coordinates.map((item) => {
  //   editRouteData.initiPoly.push({
  //     lat: item.lat,
  //     lng: item.lng,
  //   });
  // });

  editRouteData.stops = response.data.stops;

  loading.text = 'Carregando pontos...';
  createWaypoints();

  loading.state = false;
  // createMarkersMeliAndBase();
}

// function createMarkersMeliAndBase() {
//   editRouteData.stops.map((item, index) => {
//     let icon = L.divIcon({
//       iconSize: null,
//       html: `<div class="map-label">
//         <div class="map-label-content">${index + 1}</div>
//           <div class="map-label-arrow"></div></div>`,
//     });

//     editRouteData.markerIfApp.push(
//       L.marker([item.lat, item.lng], {
//         icon: icon,
//         draggable: false,
//         title: 'MELISTOP',
//       }).addTo(map),
//     );
//   });

//   // editRouteData.markerIfApp.push(
//   //   L.marker(
//   //     [
//   //       editRouteData.serviceCenterLocation.lat,
//   //       editRouteData.serviceCenterLocation.lng,
//   //     ],
//   //     {
//   //       draggable: false,
//   //       icon: L.divIcon({
//   //         iconSize: null,
//   //         html: `<div class="map-label">
//   //       <div class="map-label-content base">BASE</div>
//   //         <div class="map-label-arrow base"></div></div>`,
//   //       }),
//   //       title: 'BASE',
//   //     },
//   //   )
//   //     .addTo(map)
//   //     .bindPopup(`<b>INICIO</b>`),
//   // );

//   // editRouteData.markerIfApp.push(
//   //   L.marker(
//   //     [editRouteData.finalLocation.lat, editRouteData.finalLocation.lng],
//   //     {
//   //       draggable: true,
//   //       icon: L.divIcon({
//   //         iconSize: null,
//   //         html: `<div class="map-label">
//   //       <div class="map-label-content final">FIM</div>
//   //         <div class="map-label-arrow final"></div></div>`,
//   //       }),
//   //       title: 'FIM',
//   //     },
//   //   )
//   //     .addTo(map)
//   //     .bindPopup(`<b>FIM</b>`)
//   //     .addOneTimeEventListener('dragend', (e) => {
//   //       console.log('END MARKER', e.target._latlng);
//   //     }),
//   // );

//   // editRouteData.markerIfApp.push(
//   //   L.marker(
//   //     [editRouteData.initialLocation.lat, editRouteData.initialLocation.lng],
//   //     {
//   //       draggable: true,
//   //       icon: L.divIcon({
//   //         iconSize: null,
//   //         html: `<div class="map-label">
//   //       <div class="map-label-content initial">INICIO</div>
//   //         <div class="map-label-arrow initial"></div></div>`,
//   //       }),
//   //       title: 'INICIO',
//   //     },
//   //   )
//   //     .addTo(map)
//   //     .bindPopup(`<b>INICIO</b>`)
//   //     .addOneTimeEventListener('dragend', (e) => {
//   //       console.log('INITIAL MARKER', e.target._latlng);
//   //     }),
//   // );
// }

function initMap() {
  loading.state = true;
  loading.text = 'Carregando mapa...';

  map = L.map('map', {
    zoomControl: true,
    dragging: true,
    maxZoom: 18,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: 'topleft',
    },
  }).setView([-13.8277929, -46.4530738], 5);

  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(map);

  loading.state = false;
}

// function initEditPolyline() {
//   // FeatureGroup is to store editable layers
//   let drawnItems = new L.FeatureGroup();
//   map.addLayer(drawnItems);

//   drawControl = new L.Control.Draw({
//     edit: {
//       featureGroup: drawnItems,
//     },
//     draw: false,
//   });
//   map.addControl(drawControl);

//   polylineDrawer = new L.Draw.Polyline(map, {
//     allowIntersection: true,
//     metric: true,
//     shapeOptions: {
//       color: '#f35e1d',
//       opacity: 1,
//       weight: 4,
//     },
//   });

//   const bounds = L.latLngBounds();
//   polylineDrawer.enable();

//   editRouteData.initiPoly.map((item) => {
//     polylineDrawer.addVertex(L.latLng(item.lat, item.lng));
//   });

//   editRouteData.initiPoly.map((item) => {
//     bounds.extend(L.latLng(item.lat, item.lng));
//   });

//   createMarkersIfApp();

//   map.on('draw:created', (e) => {
//     let type = e.layerType;
//     let layer = e.layer;

//     if (type === 'polyline') {
//       let coords = layer.getLatLngs();
//       let length = 0;
//       for (let i = 0; i < coords.length - 1; i++) {
//         length += coords[i].distanceTo(coords[i + 1]);
//       }
//       editRouteData.distanceMeters = (length / 1000).toFixed(2);
//       layer.bindPopup(`${editRouteData.distanceMeters}Km`);
//     }

//     drawnItems.addLayer(layer);
//   });

//   map.on('draw:edited', (e) => {
//     let layers = e.layers;
//     console.log('edited', e.layers);
//     layers.eachLayer(function (layer) {
//       let coords = layer.getLatLngs();
//       let length = 0;
//       for (let i = 0; i < coords.length - 1; i++) {
//         length += coords[i].distanceTo(coords[i + 1]);
//       }
//       editRouteData.distanceMeters = (length / 1000).toFixed(2);

//       console.log(layer._latlngs);
//       editRouteData.polyline = polyUtil.encode(layer._latlngs);
//     });
//   });

//   polylineDrawer.completeShape();

//   map.fitBounds(bounds);
// }

function createWaypoints() {
  loading.state = true;
  loading.text = 'Criando pontos de parada...';

  editRouteData.waypointsControl = [];

  if (editRouteData.flag === 'app') {
    // if flag is app, create waypoints from coordinates
    editRouteData.waypointsControl.push(
      L.Routing.waypoint(
        [
          editRouteData.initialLocation.lat || editRouteData.coordinates[0].lat,
          editRouteData.initialLocation.lng || editRouteData.coordinates[0].lng,
        ],
        'INITIAL',
        {
          allowUTurn: true,
        },
      ),
    );
    editRouteData.coordinates.map((item) => {
      editRouteData.waypointsControl.push(
        L.Routing.waypoint([item.lat, item.lng], 'STOP', {
          allowUTurn: true,
        }),
      );
    });
    editRouteData.waypointsControl.push(
      L.Routing.waypoint(
        [
          editRouteData.finalLocation.lat ||
            editRouteData.coordinates[editRouteData.stops.length - 1].lat,
          editRouteData.finalLocation.lng ||
            editRouteData.coordinates[editRouteData.stops.length - 1].lng,
        ],
        'FINAL',
        {
          allowUTurn: true,
        },
      ),
    );
  } else if (editRouteData.flag === 'appUpdated') {
    // if flag is appUpdated, create waypoints from waypoints
    editRouteData.waypointsControl.push(
      L.Routing.waypoint(
        [
          editRouteData.initialLocation.lat || editRouteData.coordinates[0].lat,
          editRouteData.initialLocation.lng || editRouteData.coordinates[0].lng,
        ],
        'INITIAL',
        {
          allowUTurn: true,
        },
      ),
    );
    editRouteData.waypoints.map((item) => {
      editRouteData.waypointsControl.push(
        L.Routing.waypoint([item.lat, item.lng], 'STOP', {
          allowUTurn: true,
        }),
      );
    });
    editRouteData.waypointsControl.push(
      L.Routing.waypoint(
        [
          editRouteData.finalLocation.lat ||
            editRouteData.coordinates[editRouteData.stops.length - 1].lat,
          editRouteData.finalLocation.lng ||
            editRouteData.coordinates[editRouteData.stops.length - 1].lng,
        ],
        'FINAL',
        {
          allowUTurn: true,
        },
      ),
    );
  } else if (editRouteData.flag === 'meli') {
    // if flag is meli, create waypoints from stops
    editRouteData.waypointsControl.push(
      L.Routing.waypoint(
        [
          editRouteData.serviceCenterLocation.lat,
          editRouteData.serviceCenterLocation.lng,
        ],
        'INITIAL',
        {
          allowUTurn: true,
        },
      ),
    );
    editRouteData.stops.map((item) => {
      editRouteData.waypointsControl.push(
        L.Routing.waypoint([item.lat, item.lng], 'STOP', {
          allowUTurn: true,
        }),
      );
    });
    editRouteData.waypointsControl.push(
      L.Routing.waypoint(
        [
          editRouteData.serviceCenterLocation.lat,
          editRouteData.serviceCenterLocation.lng,
        ],
        'FINAL',
        {
          allowUTurn: true,
        },
      ),
    );
  } else if (editRouteData.flag === 'meliUpdated') {
    // uf flag is meliUpdated, create waypoints from waypoints
    editRouteData.waypointsControl.push(
      L.Routing.waypoint(
        [editRouteData.initialLocation.lat, editRouteData.initialLocation.lng],
        'INITIAL',
        {
          allowUTurn: true,
        },
      ),
    );

    editRouteData.waypoints.map((item) => {
      editRouteData.waypointsControl.push(
        L.Routing.waypoint([item.lat, item.lng], 'STOP', {
          allowUTurn: true,
        }),
      );
    });
    editRouteData.waypointsControl.push(
      L.Routing.waypoint(
        [editRouteData.finalLocation.lat, editRouteData.finalLocation.lng],
        'FINAL',
        {
          allowUTurn: true,
        },
      ),
    );
  } else if (
    editRouteData.flag === 'manual' ||
    editRouteData.flag === 'manualUpdated'
  ) {
    editRouteData.waypointsControl.push(
      L.Routing.waypoint(
        [
          editRouteData.serviceCenterLocation.lat,
          editRouteData.serviceCenterLocation.lng,
        ],
        'STOP',
        {
          allowUTurn: true,
        },
      ),
    );

    // uf flag is meliUpdated, create waypoints from waypoints
    editRouteData.waypointsControl.push(
      L.Routing.waypoint(
        [editRouteData.initialLocation.lat, editRouteData.initialLocation.lng],
        'INITIAL',
        {
          allowUTurn: true,
        },
      ),
    );

    editRouteData.waypoints.map((item) => {
      editRouteData.waypointsControl.push(
        L.Routing.waypoint([item.lat, item.lng], 'STOP', {
          allowUTurn: true,
        }),
      );
    });

    editRouteData.waypointsControl.push(
      L.Routing.waypoint(
        [editRouteData.finalLocation.lat, editRouteData.finalLocation.lng],
        'FINAL',
        {
          allowUTurn: true,
        },
      ),
    );

    editRouteData.waypointsControl.push(
      L.Routing.waypoint(
        [
          editRouteData.serviceCenterLocation.lat,
          editRouteData.serviceCenterLocation.lng,
        ],
        'BASE',
        {
          allowUTurn: true,
        },
      ),
    );
  }

  L.marker(
    [
      editRouteData.serviceCenterLocation.lat,
      editRouteData.serviceCenterLocation.lng,
    ],
    {
      icon: L.divIcon({
        iconSize: null,
        html: `<div class="map-label">
        <div class="map-label-content base">BASE</div>
          <div class="map-label-arrow base"></div></div>`,
      }),
      draggable: false,
      title: 'BASE',
    },
  ).addTo(map);

  // loop in stops meli
  if (editRouteData.stops.length) {
    editRouteData.stops.map((item, index) => {
      L.marker([item.lat, item.lng], {
        icon: L.divIcon({
          iconSize: [0, 0],
          html: `<div class="map-label">
        <div class="map-label-content">${index + 1}</div>
          <div class="map-label-arrow"></div></div>`,
        }),
        draggable: false,
        title: 'MELISTOP',
      }).addTo(map);
    });
  }

  // editRouteData.waypointsControl.push(
  //   L.Routing.waypoint(
  // [
  //   editRouteData.serviceCenterLocation.lat,
  //   editRouteData.serviceCenterLocation.lng,
  // ],
  //     'BASE',
  //     {
  //       allowUTurn: true,
  //     },
  //   ),
  // );

  loading.state = false;
}

function makeIcon(i, wp) {
  let url = '/assets/stop24.png';
  if (wp.name === 'MELISTOP') {
    return L.divIcon({
      iconSize: null,
      html: `<div class="map-label ${''}">
        <div class="map-label-content">${
          editRouteData.stops.findIndex(
            (stop) => stop.lat === wp.latLng.lat && stop.lng === wp.latLng.lng,
          ) + 1
        }</div>
          <div class="map-label-arrow"></div></div>`,
    });
  } else if (wp.name === 'INITIAL' || wp.name === 'FINAL') {
    return L.divIcon({
      iconSize: null,
      html: `<div class="map-label ">
        <div class="map-label-content ${
          wp.name === 'INITIAL' ? 'initial' : 'final'
        }">${wp.name === 'INITIAL' ? 'Inicial' : 'Final'}</div>
          <div class="map-label-arrow ${
            wp.name === 'INITIAL' ? 'initial' : 'final'
          }"></div></div>`,
    });
  } else if (wp.name === 'BASE') {
    return L.divIcon({
      iconSize: null,
      html: `<div class="map-label">
        <div class="map-label-content base">BASE</div>
          <div class="map-label-arrow base"></div></div>`,
    });
  } else {
    return L.icon({
      iconUrl: url,
      iconSize: [18, 18],
      className: 'leaflet-stop-icon',
    });
  }
}

// function clearMap() {
//   // remove tollbar draw
//   map.removeControl(drawControl);
//   // clear polyline and markers
//   map.eachLayer(function (layer) {
//     if (layer instanceof L.Polyline || layer instanceof L.Marker) {
//       map.removeLayer(layer);
//     }
//     // map.removeLayer(layer);
//     // console.log('layer', layer);
//   });
//   // clear markers
//   // for (let i = 0; i < editRouteData.markerIfApp.length; i++) {
//   //   map.removeLayer(editRouteData.markerIfApp[i]);
//   // }
// }

async function initControl() {
  control = L.Routing.control(
    {
      waypoints: editRouteData.waypointsControl,
      lineOptions: {
        styles: [
          { color: 'black', opacity: 0.15, weight: 9 },
          { color: 'white', opacity: 1, weight: 10 },
          { color: '#f35e1d', opacity: 1, weight: 4 },
        ],
        addWaypoints: true,
      },
      waypointMode: 'connect',

      router: L.Routing.osrmv1({
        serviceUrl: `${osrmUrl}/osrm/route/v1`,
        language: 'pt-BR',
        useHints: false,
        polylinePrecision: 5,
        routingOptions: {
          allowUTurns: true,
          geometryOnly: true,
        },
      }),
      formatter: new L.Routing.Formatter({
        units: 'metric',
        roundingSensitivity: 2000,
      }),
      routeWhileDragging: false,
      routeDragInterval: 200,
      useZoomParameter: false,
      showAlternatives: false,
      reverseWaypoints: false,

      // geocoder: L.Control.Geocoder.nominatim(),
      // geocoder: new L.Control.Geocoder.Google({
      //   apiKey: '',
      // }),
      // show: true,
      // collapsible: true,
      // collapseBtnClass: 'collapsible-icon-route',
      // dragStyles: [
      //   { color: 'black', opacity: 0.35, weight: 9 },
      //   { color: 'white', opacity: 0.8, weight: 7 },
      // ],
      createMarker: function (i, wp, n) {
        let options = {
          draggable: wp.name === 'BASE' ? false : true,
          icon: makeIcon(i, wp, n),
          title: wp.name,
        };

        let marker = L.marker(wp.latLng, options);
        marker.on('click', function (e) {
          if (
            e.sourceTarget.options.title === 'INITIAL' ||
            e.sourceTarget.options.title === 'FINAL' ||
            e.sourceTarget.options.title === 'BASE'
          ) {
            return toast.error('Parada não pode ser excluída.');
          }

          control.spliceWaypoints(i, 1);

          // if (confirm('Deseja remover a parada?') == true) {
          //   stopConfirmation.value = 'SIM!';
          //   control.spliceWaypoints(i, 1);
          // } else {
          //   stopConfirmation.value = 'NÃO!';
          // }
        });
        return marker;
      },

      itinerary: {
        show: true,
      },
      summaryTemplate:
        '<div class="d-flex align-items-center gap-1 justify-content-evenly"><span class="fw-semibold">Distância: {distance}</span> <div class="vr"></div> <span class="fw-semibold ">Duração: {time}</span></div>',
    },
    {},
  )
    .on('routesfound', function (e) {
      if (e && e.routes.length) {
        editRouteData.waypointsResponse = [];
        // editRouteData.polyline = polyUtil.encode(layer._latlngs);
        e.waypoints.forEach((waypoint) => {
          editRouteData.waypointsResponse.push({
            lat: waypoint.latLng.lat,
            lng: waypoint.latLng.lng,
          });
        });
        editRouteData.distanceMeters = e.routes[0].summary.totalDistance;
        editRouteData.initialLocation = {
          lat: e.routes[0].coordinates[0].lat,
          lng: e.routes[0].coordinates[0].lng,
        };
        editRouteData.finalLocation = {
          lat: e.routes[0].coordinates[e.routes[0].coordinates.length - 1].lat,
          lng: e.routes[0].coordinates[e.routes[0].coordinates.length - 1].lng,
        };

        editRouteData.polyline = e.routes[0].geometry;
      }
    })

    .addTo(map);

  // L.Routing.errorControl(control).addTo(map);
}

// function switchRouteEdition(event) {
//   const target = event.target;
//   clearMap();
//   if (target.value === 'editByRoteirization') {
//     initControl();
//   } else {
//     map.removeControl(control);
//     initEditPolyline();
//   }
// }

async function handleSaveNewCoord() {
  loading.state = true;
  loading.text = 'Salvando novas coordenadas...';

  const response = await routesService.updateGeolocation({
    routeId: route.params.id,
    waypoints: editRouteData.waypointsResponse,
    polyline: editRouteData.polyline,
    distanceMeters: parseInt(editRouteData.distanceMeters),
    finalLocation: editRouteData.finalLocation,
    initialLocation: editRouteData.initialLocation,
    stops: editRouteData.stops,
  });

  if (response && response.status === 204) {
    toast.success('Nova rota salva com sucesso!');
    // modalUpdateValues.value.hide();
    router.push('/administrador/rotas');
  }

  loading.state = false;
}
</script>

<template>
  <section class="flex-container bg-white rounded p-4 mx-3 min-vh-100">
    <VueElementLoading
      :active="loading.state"
      spinner="spinner"
      color="#FF6700"
      size="60"
      :text="loading.text"
      is-full-screen
    />
    <!-- <div class="d-flex my-2 justify-content-between align-items-center">
    <div class="d-flex gap-1">
        <div
          class="btn-group btn-group-sm"
          role="group"
          aria-label="Basic radio toggle button group"
          @change="switchRouteEdition($event)"
        >
          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio1"
            autocomplete="off"
            checked
            value="editByPolyline"
          />
          <label class="btn btn-outline-orange" for="btnradio1">
            Editar rota
          </label>

          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio2"
            autocomplete="off"
            value="editByRoteirization"
          />
          <label class="btn btn-outline-orange" for="btnradio2">
            Roteirizar
          </label>
        </div>
      </div>
      <span>
        <strong>KM:</strong>
        {{ editRouteData.distanceMeters }}
      </span>
    </div> -->

    <div id="map"></div>
    <div class="d-flex mt-4 justify-content-end gap-1">
      <button
        class="btn btn-sm btn-secondary"
        @click="() => router.push('/administrador/rotas')"
        :disabled="loading.state"
      >
        Voltar
      </button>
      <button
        class="btn btn-sm btn-success"
        @click="handleSaveNewCoord"
        :disabled="loading.state"
      >
        Salvar
      </button>
    </div>
  </section>
</template>

<style>
#map {
  min-height: calc(100vh - 200px);
}

/* .leaflet-routing-container {
  display: none;
} */

.leaflet-routing-alt > table {
  display: none;
}

/* .leaflet-routing-alt {
  justify-content: center;
}

.leaflet-routing-alt > h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 auto;
} */

/*Wraperclass for the divicon*/
.map-label {
  position: absolute;
  bottom: 0;
  left: -50%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
/*Wrap the content of the divicon (text) in this class*/
.map-label-content {
  order: 1;
  position: relative;
  left: -50%;
  background-color: #fff;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: #f35e1d;
  padding: 0px 8px;
  white-space: nowrap;
  font-weight: 600;
}
/*Add this arrow*/
.map-label-arrow {
  order: 2;
  width: 0px;
  height: 0px;
  left: 50%;
  border-style: solid;
  border-color: #f35e1d transparent transparent transparent;
  border-width: 10px 6px 0 6px; /*[first number is height, second/fourth are rigth/left width]*/
  margin-left: -6px;
}

.map-label-arrow.base {
  border-color: #1df35d transparent transparent transparent;
  border-width: 50px 6px 0 6px; /*[first number is height, second/fourth are rigth/left width]*/
}

.map-label-content.base {
  border-color: #1df35d;
}

.map-label-arrow.initial {
  border-color: #1e3895 transparent transparent transparent;
  border-width: 25px 6px 0 6px; /*[first number is height, second/fourth are rigth/left width]*/
}

.map-label-content.initial {
  border-color: #1e3895;
}

.map-label-arrow.final {
  border-color: #1e3895 transparent transparent transparent;
  border-width: 5px 6px 0 6px; /*[first number is height, second/fourth are rigth/left width]*/
}

.map-label-content.final {
  border-color: #1e3895;
}

.leaflet-editing-icon {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -115px;

  border-radius: 50%;
  border: 4px solid #fff;
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
}
</style>
