import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    permissions: {},
  }),

  getters: {
    getUser() {
      return this.user;
    },
    getPermissions() {
      return this.permissions;
    },
  },

  actions: {
    setUser(user) {
      this.user = user;
    },
    setPermissions(permissions) {
      this.permissions = permissions;
    },
  },
  persist: {
    key: '@MELI:user',
    storage: sessionStorage,
    paths: ['user', 'permissions'],
  },
});
