<template>
  <section class="flex-container bg-white rounded p-3 mb-3 mx-3">
    <VueElementLoading
      :active="loading"
      spinner="spinner"
      color="#FF6700"
      size="60"
    />

    <div class="d-block">
      <div class="d-inline-block form-inline pb-3">
        <a
          href="#"
          class="btn btn-sm btn-orange me-2"
          data-bs-toggle="modal"
          data-bs-target="#modal-new-complementary"
        >
          <font-awesome-icon icon="fa-regular fa-plus" />
          Registrar complementar
        </a>
      </div>
    </div>

    <div class="content">
      <p v-if="complementaries.length == 0" class="text-center p-5 mb-4">
        Nenhuma complementar.
      </p>

      <div v-else class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col border-bottom-0">ID</th>
              <!--th scope="col border-bottom-0">Data da geração</th-->
              <th scope="col border-bottom-0">Período</th>
              <th scope="col border-bottom-0">Ações</th>
            </tr>
          </thead>
          <tbody class="border-top-0">
            <tr
              class="align-middle"
              v-for="(complementary, index) in complementaries"
              :key="complementary.id"
            >
              <td>{{ complementary.id }}</td>

              <td>
                {{ new Date(complementary.initialDate).toLocaleDateString() }}
                Até
                {{ new Date(complementary.finalDate).toLocaleDateString() }}
              </td>
              <td>
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-sm btn-orange"
                    data-bs-toggle="tooltip"
                    data-bs-html="true"
                    :title="tooltipText(complementary.serviceCenters)"
                  >
                    Ver bases
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-orange dropdown-toggle dropdown-toggle-split"
                    id="dropdownMenuIncident"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-bs-reference="parent"
                  >
                    <span class="sr-only">Dropdown</span>
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuIncident"
                  >
                    <a
                      v-if="complementary.linkCSV != ''"
                      class="dropdown-item"
                      :href="complementary.linkCSV"
                      download
                    >
                      Baixar
                    </a>
                    <a
                      class="dropdown-item"
                      @click="deleteComplementary(index)"
                      href="#"
                    >
                      Excluir
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modals">
      <div
        class="modal modal-md fade"
        id="modal-new-complementary"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content border-0">
            <div class="modal-header text-white bg-blue">
              <h5 class="modal-title">
                <font-awesome-icon icon="fa-regular fa-plus" />
                Criar complementar
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Fechar"
              ></button>
            </div>
            <div class="modal-body">
              <form>
                <VueElementLoading
                  :active="isLoading"
                  spinner="spinner"
                  color="#FF6700"
                  size="60"
                />
                <div class="form-group">
                  <label for="complementary-initial-date" class="form-label">
                    Data inicial:
                  </label>
                  <VDatePicker
                    :masks="masks"
                    v-model.string="newComplementaryData.initialDate"
                    mode="date"
                  >
                    <template #default="{ inputValue, inputEvents }">
                      <input
                        class="form-control form-control-sm"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </VDatePicker>
                </div>
                <div class="form-group">
                  <label for="complementary-final-date" class="form-label">
                    Data final:
                  </label>
                  <VDatePicker
                    :masks="masks"
                    v-model.string="newComplementaryData.finalDate"
                    mode="date"
                  >
                    <template #default="{ inputValue, inputEvents }">
                      <input
                        class="form-control form-control-sm"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </VDatePicker>
                </div>
                <div class="form-group">
                  <label for="selector-service-center">Bases:</label>
                  <Multiselect
                    ref="multiselectServiceCenter"
                    id="selector-service-center"
                    v-model="newComplementaryData.serviceCenterIds"
                    :close-on-select="true"
                    :preserve-search="true"
                    value-prop="id"
                    label="serviceCenter"
                    mode="tags"
                    :searchable="true"
                    :options="serviceCenters"
                    class="mb-2 form-group-sm multi-select-orange"
                    :noOptionsText="'Sem resultados'"
                    noResultsText="Sem resultados para a pesquisa"
                    :canClear="true"
                    trackBy="serviceCenter"
                  >
                    <template #beforelist>
                      <li
                        @click.prevent="handleSelectAll"
                        aria-label="Meios de pagamentos"
                        class="multiselect-option"
                        role="option"
                        data-pointed="true"
                      >
                        <span>Selecionar todos</span>
                      </li>
                    </template>
                  </Multiselect>
                </div>

                <div class="form-group text-end mt-4">
                  <button
                    type="button"
                    class="btn btn-sm btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-success ms-3"
                    @click="createComplementary()"
                  >
                    Criar complementar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { Modal, Tooltip } from 'bootstrap';
import NProgress from 'nprogress';
import { onMounted, reactive, ref } from 'vue';
import VueElementLoading from 'vue-element-loading';
import { useToast } from 'vue-toastification';
import Multiselect from '@vueform/multiselect';
import { ComplementaryService } from '../services/ComplementaryService';
import { ServiceCenterService } from '../services/ServiceCenterService';

const complementaryService = new ComplementaryService();
const serviceCenterService = new ServiceCenterService();

const toast = useToast();

const serviceCenters = ref([]);

const complementaries = ref([]);
const multiselectServiceCenter = ref();

const loading = ref(false);
const isLoading = ref(false);

const masks = ref({
  modelValue: 'YYYY-MM-DD',
});

const newComplementaryData = reactive({
  initialDate: '',
  finalDate: '',
  serviceCenterIds: [],
});

const modals = reactive({
  modalNewComplementary: null,
});

onMounted(async () => {
  loading.value = true;
  setModals();
  const getServiceCenters = await serviceCenterService.getServiceCenters();
  serviceCenters.value = getServiceCenters.data;
  await listComplementaries();
  updateTooltips();
  loading.value = false;
});

function updateTooltips() {
  let tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]'),
  );
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });
}

async function createComplementary() {
  if (!newComplementaryData.initialDate || !newComplementaryData.finalDate) {
    toast.error('Insira as datas do periodo');
  }
  isLoading.value = true;

  const formattedInitialDate = `${newComplementaryData.initialDate}T00:00:00`;
  const formattedFinalDate = `${newComplementaryData.finalDate}T23:59:59`;

  const params = {
    initialDate: formattedInitialDate,
    finalDate: formattedFinalDate,
    serviceCenterIds: newComplementaryData.serviceCenterIds,
  };

  const response = await complementaryService.createComplementary(params);

  if (response && response.status === 201) {
    toast.success('Complementar criada com sucesso.');

    await listComplementaries();
    updateTooltips();

    newComplementaryData.finalDate = '';
    newComplementaryData.initialData = '';
    newComplementaryData.serviceCenterIds = [];

    modals.modalNewComplementary.hide();
  }

  isLoading.value = false;
}

async function listComplementaries() {
  NProgress.start();

  const response = await complementaryService.listComplementaries();

  if (response && response.status === 200) {
    complementaries.value = response.data;
  }

  NProgress.done();
}

function tooltipText(serviceCenters) {
  let text = '';
  if (serviceCenters.length > 0) {
    for (let i = 0; i < serviceCenters.length; i++) {
      text += `${serviceCenters[i].name}<br>`;
    }
  }
  return text;
}

async function deleteComplementary(index) {
  loading.value = true;

  const response = await complementaryService.deleteComplementary({
    id: complementaries.value[index].id,
  });

  if (response && response.status === 204) {
    complementaries.value.splice(index, 1);
    toast.success('Complementar excluída com sucesso!');
    updateTooltips();
  }

  loading.value = false;
}

function setModals() {
  modals.modalNewComplementary = new Modal(
    document.getElementById('modal-new-complementary'),
  );
}

function handleSelectAll() {
  multiselectServiceCenter.value.selectAll();
  multiselectServiceCenter.value.close();
}
</script>

<style scoped>
.border-left {
  border-left: 5px solid #f26321 !important;
}

.arrowMiddle {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
