import errors from '@/utils/errors';
import { api } from '../api';

class IncidentService {
  async createIncidentMessage({ id, form }) {
    try {
      const { data, status } = await api.post(
        `admin/incidentMessage/${id}`,
        form,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível criar ocorrência.');
    }
  }

  async createIncident({ form }) {
    try {
      const { data, status } = await api.post('admin/incident', form, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível criar ocorrência.');
    }
  }

  async updateIncident({ id, params }) {
    try {
      const { data, status } = await api.put(`admin/incident/${id}`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível atualizar ocorrência.');
    }
  }

  async updateIncidentStatus({ id, params }) {
    try {
      const { data, status } = await api.put(
        `admin/incidentStatus/${id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(
        error,
        'Não foi possível atualizar o status da ocorrência.',
      );
    }
  }

  async listIncidents({ url, headers }) {
    try {
      const { data, status } = await api.get(url, headers);

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível carregar ocorrências.');
    }
  }

  async deleteIncident({ id }) {
    try {
      const { data, status } = await api.delete(`/admin/incident/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível excluir ocorrência.');
    }
  }

  async deleteIncidentMessage({ id }) {
    try {
      const { data, status } = await api.delete(`admin/incidentMessage/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível excluir mensagem.');
    }
  }

  async viewIncidentMessagesRefresh({ id }) {
    try {
      const { data, status } = await api.get(`/admin/incidentMessages/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível carregar mensagens.');
    }
  }

  async viewIncidentMessages({ id }) {
    try {
      const { data, status } = await api.get(`/admin/incidentMessages/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível carregar mensagens.');
    }
  }

  async addResponsible({ id, params }) {
    try {
      const { data, status } = await api.post(
        `/admin/incidentResponsible/${id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível adicionar responsável.');
    }
  }

  async listIncidentsNewPage({ url }) {
    try {
      const { data, status } = await api.get(url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível carregar ocorrências.');
    }
  }

  async getManagers() {
    try {
      const { data, status } = await api.get('/admin/managers/', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível carregar os gestores.');
    }
  }

  async getIncidentStatuses() {
    try {
      const { data, status } = await api.get(`/admin/getIncidentStatuses/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(
        error,
        'Não foi possível carregar status das ocorrências.',
      );
    }
  }

  async getRoutesCurrent() {
    try {
      const { data, status } = await api.get(
        `meli/routesCurrent/${sessionStorage.getItem('accountId')}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível carregar rotas.');
    }
  }
}

export { IncidentService };
