import { api } from '../api';
import errors from '@/utils/errors';

class ServiceCenterService {
  async getServiceCenterStatus() {
    try {
      const { data, status } = await api.get('/meli/getServiceCentersStatus', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar os ServiceCenterStatus.');
    }
  }

  async getServiceCenters() {
    try {
      const { data, status } = await api.get('/admin/getServiceCenters', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível obter as Bases.');
    }
  }

  async myServiceCenters() {
    try {
      const { data, status } = await api.get(
        `/admin/MyServiceCenters/${sessionStorage.getItem('accountId')}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar as Bases.');
    }
  }
}

export { ServiceCenterService };
