import router from '@/routes';
import { useToast } from 'vue-toastification';

const toast = useToast();

const errors = {
  handler: function (error, message) {
    let status;
    try {
      if (Number.isInteger(error)) {
        status = error;
        error.response.message = `Erro ${status}`;
      } else {
        status = error.response.status;
      }
    } catch {
      return toast.error(message);
    }

    if (status == 400) {
      toast.error(error.response.data.message);
    } else if (status == 401) {
      localStorage.clear();
      sessionStorage.clear();
      if (window.location.href.includes('localhost')) {
        router.push('/login');
      } else if (window.location.href.includes('staging')) {
        window.location.href = 'https://stagingloggers.com.br/login';
      } else {
        window.location.href = 'https://loggers.com.br/login';
      }
    } else if (status == 403) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Você não possui permissão para executar esta ação.');
      }
    } else if (status == 404) {
      toast.error('Destino não encontrado.');
    } else if (status == 409) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(message);
      }
    } else if (status == 500) {
      toast.error(message);
    } else if (status == 502) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(message);
      }
    } else if (status == 504) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(message);
      }
    } else {
      toast.error('Por favor, tente novamente mais tarde.');
    }
  },
};

export default errors;
