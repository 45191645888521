import { api } from '../api';
import errors from '@/utils/errors';
import qs from 'qs';

class DriverService {
  // eslint-disable-next-line no-unused-vars
  async getDrivers({ driverStatus, serviceCenters, page = 0 }) {
    try {
      // page=${page}
      const { data, status } = await api.get(`/admin/drivers`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        params: {
          status: driverStatus,
          serviceCenters,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar os motoristas.');
    }
  }
}

export { DriverService };
