import errors from '@/utils/errors';
import { api } from '../api';

class ComplementaryService {
  async createComplementary(params) {
    try {
      const { data, status } = await api.post('meli/complementaries', params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível criar complementar.');
    }
  }

  async listComplementaries() {
    try {
      const { data, status } = await api.get(`meli/complementaries/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível carregar as complementares.');
    }
  }

  async deleteComplementary({ id }) {
    try {
      const { data, status } = await api.delete(`/meli/complementaries/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível excluir complementar.');
    }
  }
}

export { ComplementaryService };
