<script setup>
import { SettingService } from '../services/SettingService';
import { onMounted, reactive } from 'vue';
import VueElementLoading from 'vue-element-loading';
import Multiselect from '@vueform/multiselect';
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/stores/UserStore';

const toast = useToast();
const settingService = new SettingService();
const userStore = useUserStore();

const state = reactive({
  managers: [],

  loading: false,
  isLoading: false,

  notifyAccountsId: [],
  notifyInvitationsRoutesId: [],
  notifyAntecipationsId: [],
  notifyContestationsId: [],
  notifyIncidentsId: [],
  notifyCostCenterId: [],

  notifyAccounts: [],
  notifyCostCenter: [],
  notifyAntecipations: [],
  notifyContestations: [],
  notifyIncidents: [],
  notifyInvitationsRoutes: [],
});

onMounted(async () => {
  await listManagers();
});

async function addNotifyAccounts(option) {
  state.isLoading = true;

  const response = await settingService.updateNotifyAccounts({
    accountId: option.accountId,
    notifyAccounts: true,
  });

  if (response && response.status === 204) {
    toast.success('Notificação adicionada com sucesso.');
  }

  state.isLoading = false;
}

async function removeNotifyAccounts(option) {
  state.isLoading = true;

  const response = await settingService.updateNotifyAccounts({
    accountId: option.accountId,
    notifyAccounts: false,
  });

  if (response && response.status === 204) {
    toast.success('Notificação removida com sucesso.');
  }

  state.isLoading = false;
}

async function listManagers() {
  state.loading = true;

  const response = await settingService.listManagers();

  if (response && response.status === 200) {
    state.managers = response.data;

    state.notifyAccounts = response.data.filter(
      (manager) => manager.notifications.notifyAccounts !== true,
    );

    state.notifyAccountsId = response.data.filter(
      (manager) => manager.notifications.notifyAccounts === true,
    );

    state.notifyAntecipations = response.data.filter(
      (manager) => manager.notifications.notifyAntecipations !== true,
    );

    state.notifyAntecipationsId = response.data.filter(
      (manager) => manager.notifications.notifyAntecipations === true,
    );

    state.notifyContestationsId = response.data.filter(
      (manager) => manager.notifications.notifyContestations === true,
    );

    state.notifyContestations = response.data.filter(
      (manager) => manager.notifications.notifyContestations !== true,
    );

    state.notifyIncidents = response.data.filter(
      (manager) => manager.notifications.notifyIncidents !== true,
    );

    state.notifyIncidentsId = response.data.filter(
      (manager) => manager.notifications.notifyIncidents === true,
    );

    state.notifyInvitationsRoutes = response.data.filter(
      (manager) => manager.notifications.notifyInvitationsRoutes !== true,
    );

    state.notifyInvitationsRoutesId = response.data.filter(
      (manager) => manager.notifications.notifyInvitationsRoutes === true,
    );

    state.notifyCostCenter = response.data.filter(
      (manager) => manager.notifications.notifyCostCenter !== true,
    );

    state.notifyCostCenterId = response.data.filter(
      (manager) => manager.notifications.notifyCostCenters === true,
    );
  }

  state.loading = false;
}

async function addNotifyInvitationsRoutes(option) {
  state.isLoading = true;

  const response = await settingService.updateNotifyInvitationsRoutes({
    accountId: option.accountId,
    notifyInvitationsRoutes: true,
  });

  if (response && response.status === 204) {
    toast.success('Notificação adicionada com sucesso.');
  }

  state.isLoading = false;
}

async function removeNotifyInvitationsRoutes(option) {
  state.isLoading = true;

  const response = await settingService.updateNotifyInvitationsRoutes({
    accountId: option.accountId,
    notifyInvitationsRoutes: false,
  });

  if (response && response.status === 204) {
    toast.success('Notificação removida com sucesso.');
  }

  state.isLoading = false;
}

async function addNotifyContestations(option) {
  state.isLoading = true;

  const response = await settingService.updateNotifyContestations({
    accountId: option.accountId,
    notifyContestations: true,
  });

  if (response && response.status === 204) {
    toast.success('Notificação adicionada com sucesso.');
  }

  state.isLoading = false;
}

async function removeNotifyContestations(option) {
  state.isLoading = true;

  const response = await settingService.updateNotifyContestations({
    accountId: option.accountId,
    notifyContestations: false,
  });

  if (response && response.status === 204) {
    toast.success('Notificação removida com sucesso.');
  }

  state.isLoading = false;
}

async function addNotifyAntecipations(option) {
  state.isLoading = true;

  const response = await settingService.updateNotifyAntecipations({
    accountId: option.accountId,
    notifyAntecipations: true,
  });

  if (response && response.status === 204) {
    toast.success('Notificação adicionada com sucesso.');
  }

  state.isLoading = false;
}

async function removeNotifyAntecipations(option) {
  state.isLoading = true;

  const response = await settingService.updateNotifyAntecipations({
    accountId: option.accountId,
    notifyAntecipations: false,
  });

  if (response && response.status === 204) {
    toast.success('Notificação removida com sucesso.');
  }

  state.isLoading = false;
}

async function addNotifyIncidents(option) {
  state.isLoading = true;

  const response = await settingService.updateNotifyIncidents({
    accountId: option.accountId,
    notifyIncidents: true,
  });

  if (response && response.status === 204) {
    toast.success('Notificação adicionada com sucesso.');
  }

  state.isLoading = false;
}

async function removeNotifyIncidents(option) {
  state.isLoading = true;

  const response = await settingService.updateNotifyIncidents({
    accountId: option.accountId,
    notifyIncidents: false,
  });

  if (response && response.status === 204) {
    toast.success('Notificação removida com sucesso.');
  }

  state.isLoading = false;
}

async function addNotifyCostCenter(option) {
  state.isLoading = true;

  const response = await settingService.updateNotifyCostCenters({
    accountId: option.accountId,
    notifyCostCenters: true,
  });

  if (response && response.status === 204) {
    toast.success('Notificação adicionada com sucesso.');
  }

  state.isLoading = false;
}

async function removeNotifyCostCenter(option) {
  state.isLoading = true;

  const response = await settingService.updateNotifyCostCenters({
    accountId: option.accountId,
    notifyCostCenters: false,
  });

  if (response && response.status === 204) {
    toast.success('Notificação removida com sucesso.');
  }

  state.isLoading = false;
}
</script>

<template>
  <section class="flex-container bg-white rounded p-4 mx-3 min-vh-100">
    <VueElementLoading
      :active="state.loading"
      spinner="spinner"
      color="#FF6700"
      size="60"
    />
    <div class="accordion" id="accordionManagerSettings">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            Configurações de notificações
          </button>
        </h2>
        <div
          id="collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div class="container-fluid p-0">
              <ul
                class="nav nav-pills nav-fill mb-3"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link flex-sm-fill active"
                    id="pills-drivers-account-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-drivers-account"
                    type="button"
                    role="tab"
                    aria-controls="pills-drivers-account"
                    aria-selected="true"
                  >
                    Contas de Motorista
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-invitations-routes-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-invitations-routes"
                    type="button"
                    role="tab"
                    aria-controls="pills-invitations-routes"
                    aria-selected="false"
                  >
                    Convites e Rotas
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-notifyContestations-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-notifyContestations"
                    type="button"
                    role="tab"
                    aria-controls="pills-notifyContestations"
                    aria-selected="false"
                  >
                    Contestações
                  </button>
                </li>
                <li
                  v-if="userStore.user.partnerId === 1"
                  class="nav-item"
                  role="presentation"
                >
                  <button
                    class="nav-link"
                    id="pills-notifyAntecipations-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-notifyAntecipations"
                    type="button"
                    role="tab"
                    aria-controls="pills-notifyAntecipations"
                    aria-selected="false"
                  >
                    Adiantamentos
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-incidents-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-incidents"
                    type="button"
                    role="tab"
                    aria-controls="pills-incidents"
                    aria-selected="false"
                  >
                    Ocorrências
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-cost-center-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-cost-center"
                    type="button"
                    role="tab"
                    aria-controls="pills-cost-center"
                    aria-selected="false"
                  >
                    Centro de Custo
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-drivers-account"
                  role="tabpanel"
                  aria-labelledby="pills-drivers-account-tab"
                  tabindex="0"
                >
                  <div class="d-flex flex-lg-row flex-sm-column">
                    <Multiselect
                      v-if="state.notifyAccounts.length > 0"
                      id="notifyAccounts"
                      v-model="state.notifyAccountsId"
                      :object="true"
                      value-prop="accountId"
                      label="accountName"
                      mode="tags"
                      :close-on-select="true"
                      :searchable="true"
                      :create-option="false"
                      :options="state.notifyAccounts"
                      class="mb-2 form-group-sm mult-select-orange"
                      @deselect="removeNotifyAccounts"
                      @select="addNotifyAccounts"
                      :disabled="state.isLoading"
                      :loading="state.isLoading"
                      :noOptionsText="'Sem resultados'"
                      noResultsText="Sem resultados para a pesquisa"
                      :canClear="false"
                      trackBy="accountName"
                    >
                      <template
                        v-slot:tag="{ option, handleTagRemove, disabled }"
                      >
                        <div
                          class="multiselect-tag is-user"
                          :class="{
                            'is-disabled': disabled,
                          }"
                        >
                          <img
                            :src="option.avatar || '/assets/img/unknow.png'"
                          />
                          {{ option.accountName }}
                          <span
                            v-if="!disabled"
                            class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)"
                          >
                            <span class="multiselect-tag-remove-icon"></span>
                          </span>
                        </div>
                      </template>
                    </Multiselect>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div
                      v-if="state.isLoading"
                      class="spinner-border text-orange"
                      role="status"
                    ></div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-invitations-routes"
                  role="tabpanel"
                  aria-labelledby="pills-invitations-routes-tab"
                  tabindex="0"
                >
                  <div class="d-flex flex-lg-row flex-sm-column">
                    <Multiselect
                      v-if="state.notifyInvitationsRoutes.length > 0"
                      id="notifyInvitationsRoutes"
                      v-model="state.notifyInvitationsRoutesId"
                      :object="true"
                      value-prop="accountId"
                      label="accountName"
                      mode="tags"
                      :close-on-select="true"
                      :searchable="true"
                      :create-option="false"
                      :options="state.notifyInvitationsRoutes"
                      class="mb-2 form-group-sm mult-select-orange"
                      @deselect="removeNotifyInvitationsRoutes"
                      @select="addNotifyInvitationsRoutes"
                      :disabled="state.isLoading"
                      :loading="state.isLoading"
                      :noOptionsText="'Sem resultados'"
                      noResultsText="Sem resultados para a pesquisa"
                      :canClear="false"
                      trackBy="accountName"
                    >
                      <template
                        v-slot:tag="{ option, handleTagRemove, disabled }"
                      >
                        <div
                          class="multiselect-tag is-user"
                          :class="{
                            'is-disabled': disabled,
                          }"
                        >
                          <img
                            :src="option.avatar || '/assets/img/unknow.png'"
                          />
                          {{ option.accountName }}
                          <span
                            v-if="!disabled"
                            class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)"
                          >
                            <span class="multiselect-tag-remove-icon"></span>
                          </span>
                        </div>
                      </template>
                    </Multiselect>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div
                      v-if="state.isLoading"
                      class="spinner-border text-orange"
                      role="status"
                    ></div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-notifyContestations"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                  tabindex="0"
                >
                  <div class="d-flex flex-lg-row flex-sm-column">
                    <Multiselect
                      v-if="state.notifyContestations.length > 0"
                      id="notifyContestations"
                      v-model="state.notifyContestationsId"
                      :object="true"
                      value-prop="accountId"
                      label="accountName"
                      mode="tags"
                      :close-on-select="true"
                      :searchable="true"
                      :create-option="false"
                      :options="state.notifyContestations"
                      class="mb-2 form-group-sm mult-select-orange"
                      @deselect="removeNotifyContestations"
                      @select="addNotifyContestations"
                      :disabled="state.isLoading"
                      :loading="state.isLoading"
                      :noOptionsText="'Sem resultados'"
                      noResultsText="Sem resultados para a pesquisa"
                      :canClear="false"
                      trackBy="accountName"
                    >
                      <template
                        v-slot:tag="{ option, handleTagRemove, disabled }"
                      >
                        <div
                          class="multiselect-tag is-user"
                          :class="{
                            'is-disabled': disabled,
                          }"
                        >
                          <img
                            :src="option.avatar || '/assets/img/unknow.png'"
                          />
                          {{ option.accountName }}
                          <span
                            v-if="!disabled"
                            class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)"
                          >
                            <span class="multiselect-tag-remove-icon"></span>
                          </span>
                        </div>
                      </template>
                    </Multiselect>
                  </div>

                  <div class="d-flex justify-content-center">
                    <div
                      v-if="state.isLoading"
                      class="spinner-border text-orange"
                      role="status"
                    ></div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-notifyAntecipations"
                  role="tabpanel"
                  aria-labelledby="pills-notifyAntecipations-tab"
                  tabindex="0"
                  v-if="userStore.user.partnerId === 1"
                >
                  <div class="d-flex flex-lg-row flex-sm-column">
                    <Multiselect
                      v-if="state.notifyAntecipations.length > 0"
                      id="notifyInvitationsRoutes"
                      v-model="state.notifyAntecipationsId"
                      :object="true"
                      value-prop="accountId"
                      label="accountName"
                      mode="tags"
                      :close-on-select="true"
                      :searchable="true"
                      :create-option="false"
                      :options="state.notifyAntecipations"
                      class="mb-2 form-group-sm mult-select-orange"
                      @deselect="removeNotifyAntecipations"
                      @select="addNotifyAntecipations"
                      :disabled="state.isLoading"
                      :loading="state.isLoading"
                      :noOptionsText="'Sem resultados'"
                      noResultsText="Sem resultados para a pesquisa"
                      :canClear="false"
                      trackBy="accountName"
                    >
                      <template
                        v-slot:tag="{ option, handleTagRemove, disabled }"
                      >
                        <div
                          class="multiselect-tag is-user"
                          :class="{
                            'is-disabled': disabled,
                          }"
                        >
                          <img
                            :src="option.avatar || '/assets/img/unknow.png'"
                          />
                          {{ option.accountName }}
                          <span
                            v-if="!disabled"
                            class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)"
                          >
                            <span class="multiselect-tag-remove-icon"></span>
                          </span>
                        </div>
                      </template>
                    </Multiselect>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div
                      v-if="state.isLoading"
                      class="spinner-border text-orange"
                      role="status"
                    ></div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-incidents"
                  role="tabpanel"
                  aria-labelledby="pills-incidents-tab"
                  tabindex="0"
                >
                  <div class="d-flex flex-lg-row flex-sm-column">
                    <Multiselect
                      v-if="state.notifyIncidents.length > 0"
                      id="notifyInvitationsRoutes"
                      v-model="state.notifyIncidentsId"
                      :object="true"
                      value-prop="accountId"
                      label="accountName"
                      mode="tags"
                      :close-on-select="true"
                      :searchable="true"
                      :create-option="false"
                      :options="state.notifyIncidents"
                      class="mb-2 form-group-sm mult-select-orange"
                      @deselect="removeNotifyIncidents"
                      @select="addNotifyIncidents"
                      :disabled="state.isLoading"
                      :loading="state.isLoading"
                      :noOptionsText="'Sem resultados'"
                      noResultsText="Sem resultados para a pesquisa"
                      :canClear="false"
                      trackBy="accountName"
                    >
                      <template
                        v-slot:tag="{ option, handleTagRemove, disabled }"
                      >
                        <div
                          class="multiselect-tag is-user"
                          :class="{
                            'is-disabled': disabled,
                          }"
                        >
                          <img
                            :src="option.avatar || '/assets/img/unknow.png'"
                          />
                          {{ option.accountName }}
                          <span
                            v-if="!disabled"
                            class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)"
                          >
                            <span class="multiselect-tag-remove-icon"></span>
                          </span>
                        </div>
                      </template>
                    </Multiselect>
                  </div>

                  <div class="d-flex justify-content-center">
                    <div
                      v-if="state.isLoading"
                      class="spinner-border text-orange"
                      role="status"
                    ></div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-cost-center"
                  role="tabpanel"
                  aria-labelledby="pills-cost-center-tab"
                  tabindex="0"
                >
                  <div class="d-flex flex-lg-row flex-sm-column">
                    <Multiselect
                      v-if="state.notifyCostCenter.length > 0"
                      id="notifyInvitationsRoutes"
                      v-model="state.notifyCostCenterId"
                      :object="true"
                      value-prop="accountId"
                      label="accountName"
                      mode="tags"
                      :close-on-select="true"
                      :searchable="true"
                      :create-option="false"
                      :options="state.notifyCostCenter"
                      class="mb-2 form-group-sm mult-select-orange"
                      @deselect="removeNotifyCostCenter"
                      @select="addNotifyCostCenter"
                      :disabled="state.isLoading"
                      :loading="state.isLoading"
                      :noOptionsText="'Sem resultados'"
                      noResultsText="Sem resultados para a pesquisa"
                      :canClear="false"
                      trackBy="accountName"
                    >
                      <template
                        v-slot:tag="{ option, handleTagRemove, disabled }"
                      >
                        <div
                          class="multiselect-tag is-user"
                          :class="{
                            'is-disabled': disabled,
                          }"
                        >
                          <img
                            :src="option.avatar || '/assets/img/unknow.png'"
                          />
                          {{ option.accountName }}
                          <span
                            v-if="!disabled"
                            class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)"
                          >
                            <span class="multiselect-tag-remove-icon"></span>
                          </span>
                        </div>
                      </template>
                    </Multiselect>
                  </div>

                  <div class="d-flex justify-content-center">
                    <div
                      v-if="state.isLoading"
                      class="spinner-border text-orange"
                      role="status"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.mult-select-orange {
  box-shadow: none !important;
}

.multiselect-tag.is-user {
  padding: 5px 8px;
  border-radius: 22px;
  background: #f26321;
  margin: 3px 3px 8px;
}

.multiselect-tag.is-user img {
  width: 18px;
  border-radius: 50%;
  height: 18px;
  margin-right: 8px;
  border: 2px solid #ffffffbf;
}

.multiselect-tag.is-user i:before {
  color: #ffffff;
  border-radius: 50%;
}

.user-image {
  margin: 0 6px 0 0;
  border-radius: 50%;
  height: 22px;
}

.accordion-button:not(.collapsed) {
  background: #ffffff;
  color: #000;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.nav-link {
  color: #000;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background: #f26321;
}
</style>
