<template>
  <input ref="inputRef" type="text" />
</template>

<script setup>
import { watch, defineProps } from 'vue';
import { useCurrencyInput } from 'vue-currency-input';

const props = defineProps({
  modelValue: Number,
});

const { inputRef, setValue } = useCurrencyInput({
  locale: 'pt-BR',
  currency: 'BRL',
  currencyDisplay: 'narrowSymbol',
  precision: 2,
  hideCurrencySymbolOnFocus: false,
  hideGroupingSeparatorOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: false,
  autoDecimalDigits: true,
  autoSign: true,
  useGrouping: true,
  accountingSign: false,
});

watch(
  () => props.modelValue,
  (value) => {
    setValue(value);
  },
);
</script>
