import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createApp } from 'vue';
import VueTheMask from 'vue-the-mask';
import Toast from 'vue-toastification';
import App from './App.vue';
import routes from './routes';
import { VueQueryPlugin } from '@tanstack/vue-query';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'vue3-easy-data-table/dist/style.css';

import store from '@/store';
import '@vueform/multiselect/themes/default.css';

import 'vue-advanced-cropper/dist/style.css';
import 'vue-toastification/dist/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './theme/core.css';
import { createPinia } from 'pinia';

const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
};

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

library.add(fas, far);
dom.watch();

app.use(routes);
app.use(setupCalendar, {
  color: 'orange',
});
app.component('VCalendar', Calendar);
app.component('VDatePicker', DatePicker);
app.use(pinia);
app.component('EasyDataTable', Vue3EasyDataTable);
app.use(Toast, {
  position: 'bottom-right',
  transition: 'Vue-Toastification__bounce',
  maxToasts: 2,
  newestOnTop: true,
});
app.use(store);
app.use(VueTheMask);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(VueQueryPlugin, vueQueryPluginOptions);

app.config.performance = true;
app.mount('#app');
