import { api } from '@/api';
import errors from '@/utils/errors';

class SettingService {
  async listManagers() {
    try {
      const { data, status } = await api.get('admin/managers', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar os managers.');
    }
  }

  async updateNotifyAccounts({ accountId, notifyAccounts }) {
    try {
      const { data, status } = await api.put(
        `/admin/managers/${accountId}/notifications`,
        {
          notifyAccounts,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(
        error,
        'Não foi possível adicionar notificação para o administrador.',
      );
    }
  }

  async updateNotifyInvitationsRoutes({ accountId, notifyInvitationsRoutes }) {
    try {
      const { data, status } = await api.put(
        `/admin/managers/${accountId}/notifications`,
        {
          notifyInvitationsRoutes,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(
        error,
        'Não foi possível adicionar notificação para o administrador.',
      );
    }
  }

  async updateNotifyContestations({ accountId, notifyContestations }) {
    try {
      const { data, status } = await api.put(
        `/admin/managers/${accountId}/notifications`,
        {
          notifyContestations,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(
        error,
        'Não foi possível adicionar notificação para o administrador.',
      );
    }
  }

  async updateNotifyAntecipations({ accountId, notifyAntecipations }) {
    try {
      const { data, status } = await api.put(
        `/admin/managers/${accountId}/notifications`,
        {
          notifyAntecipations,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(
        error,
        'Não foi possível adicionar notificação para o administrador.',
      );
    }
  }

  async updateNotifyIncidents({ accountId, notifyIncidents }) {
    try {
      const { data, status } = await api.put(
        `/admin/managers/${accountId}/notifications`,
        {
          notifyIncidents,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(
        error,
        'Não foi possível adicionar notificação para o administrador.',
      );
    }
  }

  async updateNotifyCostCenters({ accountId, notifyCostCenters }) {
    try {
      const { status } = await api.put(
        `/admin/managers/${accountId}/notifications`,
        {
          notifyCostCenters,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { status };
    } catch (error) {
      errors.handler(
        error,
        'Não foi possível adicionar notificação para o administrador.',
      );
    }
  }
}

export { SettingService };
