import accounting from 'accounting';

function capitalize(value) {
  if (!value) return '';

  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}

function dateDisplay(value) {
  return `${value.substring(8, 10)}/${value.substring(5, 7)}/${value.substring(
    0,
    4,
  )}`;
}

/**
 * Converte um Datetime para DD/MM/YYYY às HH:MM:SS
 * @param {Date} value
 */
function dateTimeDisplay(value) {
  let day = [
    `0${value.getDate()}`.slice(-2),
    `0${value.getMonth() + 1}`.slice(-2),
    value.getFullYear(),
  ].join('/');

  let hours = value.getHours();
  if (hours < 10) {
    hours = `0${hours}`;
  }

  let minutes = value.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  let seconds = value.getSeconds();
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  let time = `${hours}:${minutes}:${seconds}`;

  return `${day} às ${time}`;
}

function displayTimestamp(timestamp) {
  // Recebe um timestamp em UTC

  timestamp = new Date(timestamp);
  let date = timestamp.getDate();
  let month = timestamp.getMonth() + 1;
  let year = timestamp.getFullYear();
  let hour = timestamp.getHours();
  let minute = timestamp.getMinutes();

  if (date < 10) date = `0${date}`;

  if (month < 10) month = `0${month}`;

  if (hour < 10) hour = `0${hour}`;

  if (minute < 10) minute = `0${minute}`;

  return `${date.toString()}/${month.toString()}/${year.toString()} ${hour.toString()}:${minute.toString()}`;
}

function displayRelativeTimestamp(timestamp) {
  let timestampNow = new Date();
  let timestampMessage = new Date(timestamp);

  let differenceInMinutes = (timestampNow - timestampMessage) / 1000 / 60;

  if (differenceInMinutes < 1) {
    return 'agora mesmo';
  } else if (differenceInMinutes > 1 && differenceInMinutes < 55) {
    return `há ${Math.round(differenceInMinutes)} minutos`;
  } else if (differenceInMinutes > 55 && differenceInMinutes <= 65) {
    return 'há 1 hora';
  } else if (differenceInMinutes > 65 && differenceInMinutes <= 60 * 24) {
    return `há ${Math.round(differenceInMinutes / 60)} horas`;
  } else if (
    differenceInMinutes > 60 * 24 &&
    differenceInMinutes <= 60 * 24 * 2
  ) {
    return 'ontem';
  } else if (
    differenceInMinutes > 60 * 24 &&
    differenceInMinutes < 60 * 24 * 5
  ) {
    return `há ${Math.round(differenceInMinutes / 60 / 24)} dias`;
  } else {
    return displayTimestamp(timestampMessage);
  }
}

function firstNameAndFirstSurname(name) {
  if (name.split(' ')[1] == null) return name;
  return `${name.split(' ')[0]} ${name.split(' ')[1].substring(0, 1)}.`;
}

function firstName(name) {
  if (!name) return '';
  return name.split(' ')[0];
}

function floatToMoney(value) {
  return accounting.formatMoney(value, 'R$ ', 2, '.', ',');
}

function stringToCpf(value) {
  return `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(
    6,
    9,
  )}-${value.substring(9, 11)}`;
}

function stringToCnpj(value) {
  return `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(
    5,
    8,
  )}/${value.substring(8, 12)}-${value.substring(12, 14)}`;
}

function stringToCep(value) {
  return `${value.substring(0, 5)}.${value.substring(5, 8)}`;
}

function truncate(value, length) {
  if (value.length <= length) {
    return value;
  }

  return `${value.substring(0, length)}...`;
}

function serviceCenterName(value, length) {
  if (!value) {
    return;
  }
  if (value.length <= length) {
    return value;
  }

  return (value = value.split('-')[0]);
}

export default {
  dateTimeDisplay,
  capitalize,
  dateDisplay,
  displayRelativeTimestamp,
  floatToMoney,
  stringToCpf,
  stringToCnpj,
  stringToCep,
  firstNameAndFirstSurname,
  firstName,
  truncate,
  serviceCenterName,
};
