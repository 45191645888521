import { api } from '../api';
import errors from '@/utils/errors';
import date from '@/utils/date';
import qs from 'qs';

class OverviewService {
  async getCrawler() {
    try {
      const { data, status } = await api.get('/crawler', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar dados do MELI.');
    }
  }

  async searchRoutes({
    date = '',
    serviceCenterId = '',
    getMeliData = false,
    getMeliDataHistory = false,
  }) {
    try {
      const { data, status } = await api.get(
        `/meli/searchRoutes?name=&date=${date}&serviceCenterId=${serviceCenterId}&routeId=&getMeliData=${getMeliData}&getMeliDataHistory=${getMeliDataHistory}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar as rotas.');
    }
  }

  async searchVehicles({ statusIds, checklist, serviceCenterIds }) {
    try {
      const { data, status } = await api.get(`admin/vehicles?`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        params: {
          statusIds,
          checklist,
          serviceCenterIds,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível obter os veículos.');
    }
  }

  async getServiceCentersStatus() {
    try {
      const { data, status } = await api.get('/meli/getServiceCentersStatus', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar o status das Bases.');
    }
  }

  async getServiceCenters() {
    try {
      const { data, status } = await api.get('/getServiceCenters', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar as Bases.');
    }
  }

  async getRoutes() {
    try {
      const { data, status } = await api.get(
        `meli/searchRoutes?name=&date=${date.getToday()}&serviceCenterId=&routeId=&getMeliData=true`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar as rotas.');
    }
  }
}

export { OverviewService };
