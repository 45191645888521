import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
});

const sandbox = axios.create({
  baseURL: 'https://sandbox.loggers.com.br/v1/',
});

const customerApi = axios.create({
  baseURL: 'https://api.loggers.com.br/v1/',
});

export { api, sandbox, customerApi };
