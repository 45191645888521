import $ from 'jquery';
import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import Complementary from '@/pages/Complementary.vue';
import Incidents from '@/pages/Incidents.vue';
import Invitations from '@/pages/Invitations.vue';
import MapEditApp from '@/pages/MapEditApp.vue';
import NotFound from '@/pages/NotFound.vue';
import Overview from '@/pages/Overview.vue';
import Routes from '@/pages/Routes.vue';
import Settings from '@/pages/Settings.vue';

const IntegracaoMeli = () => import('@/pages/IntegrationMeli.vue');

const routes = [
  {
    path: '/',
    name: 'redirect',
    redirect: '/authentication',
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('@/pages/Authentication.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return window.location.href.includes('localhost')
        ? import('@/pages/Login.vue')
        : import('@/pages/Authentication.vue');
    },
  },
  {
    path: '/administrador',
    name: 'Administrador',
    component: function () {
      return import('@/pages/Manager.vue');
    },
    children: [
      {
        path: 'rotas',
        name: 'Rotas',
        components: {
          'stage-view': Routes,
        },
      },
      {
        path: 'rotas/mapa/:id',
        name: 'Editar Rota',
        components: {
          'stage-view': MapEditApp,
        },
      },
      {
        path: 'overview',
        name: 'Visão geral',
        components: {
          'stage-view': Overview,
        },
      },
      {
        path: 'ocorrencias',
        name: 'Ocorrências',
        components: {
          'stage-view': Incidents,
        },
      },
      {
        path: 'convites',
        name: 'Convites',
        components: {
          'stage-view': Invitations,
        },
      },
      {
        path: 'complementares',
        name: 'Complementares MELI',
        components: {
          'stage-view': Complementary,
        },
      },
      {
        path: 'configuracoes',
        name: 'Configurações',
        components: {
          'stage-view': Settings,
        },
      },
      {
        path: 'integracao/meli',
        name: 'Integração Meli',
        components: {
          'stage-view': IntegracaoMeli,
        },
      },
    ],
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {
  nextTick(() => {
    document.title = `BLD Logística | ${String(to.name)}`;
    $('#sidebar').addClass('active');
    $('#content').removeClass('active');
  });
});

export default router;
