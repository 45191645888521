<!-- eslint-disable no-unused-vars -->
<script setup>
import $ from 'jquery';
import { RoutesService } from '@/services/RoutesService';
import date from '@/utils/date';
import filters from '@/utils/vueFilters';
import {
  reactive,
  ref,
  onMounted,
  computed,
  onUnmounted,
  nextTick,
  watch,
  defineAsyncComponent,
} from 'vue';
import VueElementLoading from 'vue-element-loading';
import VueEasyLightbox from 'vue-easy-lightbox';
import Multiselect from '@vueform/multiselect';
import Popper from 'vue3-popper';
import { Cropper } from 'vue-advanced-cropper';
import { Timer } from 'easytimer.js';
import { io } from 'socket.io-client';
import { VeProgress } from 'vue-ellipse-progress';
import { Modal, Tab } from 'bootstrap';
import { ServiceCenterService } from '@/services/ServiceCenterService';
import jsonConvertor from '@/utils/jsonConvertor';
import L from 'leaflet';
import globals from '@/utils/globals';
import polyUtil from 'polyline-encoded';
import CurrencyInput from '@/components/CurrencyInput.vue';
import { useToast } from 'vue-toastification';
import { DriverService } from '@/services/DriverService';
import { useStore } from 'vuex';
import { useStorage, useVirtualList } from '@vueuse/core';
import readXlsxFile from 'read-excel-file';
import { useDropzone } from 'vue3-dropzone';
import { encode } from '@googlemaps/polyline-codec';
import NoDataComponent from '@/components/NoDataComponent.vue';
import { PluginService } from '@/services/PluginService';

// prettier-ignore
const PendentsTable = defineAsyncComponent(
  () => import('@/components/pages/Routes/PendentsTable.vue'),
);

const coordenadasHeaders = [
  {
    text: 'ID',
    value: 'meliId',
  },
  {
    text: 'Menssagem',
    value: 'message',
  },
];

const routesByXlsxErrosHeaders = [
  {
    text: 'Coluna',
    value: 'column',
  },
  {
    text: 'Linha',
    value: 'row',
  },
  {
    text: 'Erro',
    value: 'error',
  },
  {
    text: 'Valor',
    value: 'value',
  },
];

const { getRootProps, getInputProps, open, isDragActive, ...rest } =
  useDropzone({ onDrop, accept: '.xlsx', multiple: false });

const testKey = ref(0);

const toast = useToast();
const store = useStore();

const masks = ref({
  modelValue: 'YYYY-MM-DD',
});

const routesService = new RoutesService();
const serviceCenterService = new ServiceCenterService();
const driverService = new DriverService();
const pluginService = new PluginService();
const permissions = computed(() => store.getters['permissions']);

let map;
let mapRealTime;
let detailsPolyline;

const socket = reactive({
  state: io(process.env.VUE_APP_BASE_URL_API, {
    path: '/meli/socket.io',
    auth: {
      token: sessionStorage.getItem('token'),
    },
    autoConnect: false,
  }),
});

const refreshMarkersTime = ref(0);
const markersRealTime = ref([]);

const modelConfig = ref({
  type: 'string',
  mask: 'YYYY-MM-DD',
  timeAdjust: '12:00:00',
});

const loading = reactive({
  state: false,
  text: 'Loading...',
});

const isLoading = ref(false);
const isDeletingPhoto = ref(false);
const firstTimeRun = ref(true);
const isShowPendents = ref(false);

const hasMeli = ref(false);
const filterDriverServiceCenterId = ref(null);

const routeIndex = ref(null);

const filter = reactive({
  name: '',
  routeId: '',
  date: date.getToday(),
  serviceCenter: '',
});

const exportCsv = reactive({
  initialDate: '',
  serviceCenter: '',
  finalDate: '',
});

const lightboxImage = reactive({
  imgs: [],
  visible: false,
});

const cropperConfig = reactive({
  image: null,
  canvas: null,
});

const newRoute = reactive({
  initialDate: date.getToday(),
  initialHour: '12:00',
  finalDate: '',
  finalHour: '',
  driverAccountId: null,
  initialKilometer: '',
  finalKilometer: null,
  initialKilometerPhoto: '',
  initialKilometerBlob: '',
  finalKilometerPhoto: '',
  finalKilometerBlob: '',
  initialSelfiePhoto: '',
  initialSelfieBlob: '',
  meliPhoto: '',
  meliBlob: '',
  routeId: '',
  numberPackages: '',
  totalStops: '',
  mainRouteId: '',
  mainRouteDate: '',
  carPlate: '',
  wave: 1,
  ended: 0,
  statusId: 6,
  failures: 0,
  attachments: [],
  tolls: 0,
  isBackup: '0',
  kmTraveled: '',
  imagesPreview: [],
  polyline: '',
});

const meliRules = ref({
  kilometerValueRule: '',
  enableKilometer: false,
  flag: '',
  enableKilometerPhotos: false,
  geolocationSourcePriority: [],
});

const editRoute = reactive({
  id: null,
  geolocationSource: '',
  numberPackages: '',
  successCounter: 0,
  totalStops: '',
  failuresCounter: 0,
  initialKilometerPhoto: '',
  initialKilometerBlob: null,
  initialSelfiePhoto: '',
  initialSelfiePhotoBlob: null,
  finalKilometerPhoto: '',
  meliPhoto: '',
  meliPhotoBlob: null,
  daily: '',
  bonus: '',
  bonusKmTraveled: '',
  variableTotal: '',
  bonusAbove80Packages: '',
  tolls: 0,
  statusId: 7,
  meliId: '',
  finalDateTime: null,
  initialDateTime: '',
  initialDate: '',
  initialHour: '',
  finalDate: '',
  finalHour: '',
  wave: '',
  initialKilometer: '',
  finalKilometer: '',
  vehicle: {
    id: null,
    plate: '',
    type: {
      id: null,
      name: '',
    },
  },
  account: {
    id: 0,
    accountName: '',
    accountTypeId: 0,
    driver: {
      serviceCenter: {
        enableKilometer: false,
        enableKilometerPhotos: false,
        kilometerValueRule: '',
      },
    },
  },
  kilometerValueRule: '',
  primaryDriver: {
    accountId: 0,
    accountName: '',
  },
  attachments: [],
  imagesPreview: [],
  flag: '',
  stops: [],
  waypoints: [],
  polyline: '',
  distanceMeters: 0,
  hasRoute: false,
  serviceCenterLocation: {
    lat: 0,
    lng: 0,
  },
  finalLocation: {
    lat: 0,
    lng: 0,
  },
  initialLocation: {
    lat: 0,
    lng: 0,
  },
  selectedPolyline: '',
  kmTraveled: '',
  geolocation: { manual: false, app: false, meli: false },
  polylineByImport: '',
  draggablePhotos: [],
  draggablePhotosFinalKm: [],
});

const deleteRouteData = reactive({
  routeId: '',
  meliId: '',
  accountName: '',
});

const updateStatusRoute = reactive({
  routeId: '',
  statusId: '',
});

const markers = ref([]);

const modals = reactive({
  modalExport: null,
  modalConfirmDeleteRoute: null,
  modalRouteStatus: null,
  modalNewRoute: null,
  modalRoute: null,
  modalFilter: null,
  modalSelfiePhoto: null,
  modalInitialKilometerPhoto: null,
  modalFinalKilometerPhoto: null,
  modalMeliPhoto: null,
  modalUpdateSelfiePhoto: null,
  modalUpdateInitialKilometerPhoto: null,
  modalUpdateMeliPhoto: null,
  modalUpdateFinalKilometerPhoto: null,
  modalAttachments: null,
  modalUpdateAttachments: null,
  modalUpdateRouteValues: null,
  offcanvasRoutes: null,
  modalRoutesMap: null,
  modalEditInitialAddressRoute: null,
  offCanvasCreateStop: null,
  modalRestoreRoute: null,
  tabRoute: null,
  tabMap: null,
  modalManualCoordinates: null,
  modalCoordinatorsErros: null,
  modalSchemaXlsxErrors: null,
  modalUnlinkDriver: null,
});

const routesByXlsxSchema = reactive({
  id: {
    prop: 'id',
    type: String,
    required: true,
  },
  Latitude: {
    prop: 'latitude',
    type: String,
    required: true,
  },
  Longitude: {
    prop: 'longitude',
    type: String,
    required: true,
  },
});
const dragNDropImgs = reactive({
  origin: '',
  destiny: '',
  originId: null,
  destinyId: null,
});
const routesByXlsx = ref([]);
const routesByXlsxErros = ref([]);
const modalCoordinatorsErros = ref([]);
const modalCoordinatorsErrosValue = ref('');

const routeTotals = reactive({
  totalRoutesEnded: 0,
  totalRoutes: 0,
  totalRoutesTravel: 0,
  totalPackages: 0,
  totalSuccess: 0,
  totalReal: 0,
  totalDivided: 0,
  totalFailures: 0,
  totalGeolocation: 0,
  totalNoGeolocation: 0,
  totalNotNull: 0,
});
const inputSearch = ref('');

const routes = ref([]);
const serviceCenters = ref([]);
const drivers = ref([]);
const meliRoutes = ref([]);
const pendingRoutes = ref([]);

const filterDrivers = computed(() => {
  if (!filterDriverServiceCenterId.value) {
    return drivers.value;
  } else {
    return drivers.value.filter((driver) => {
      if (driver.serviceCenterId === filterDriverServiceCenterId.value)
        return driver;
    });
  }
});

const filteredItems = computed(() => {
  return routes.value.filter((i) =>
    i.routeId.startsWith(inputSearch.value.toLowerCase()),
  );
});

const { list, containerProps, wrapperProps } = useVirtualList(filteredItems, {
  itemHeight: 78,
  overscan: 5,
});

const preferences = useStorage(
  `@${sessionStorage.getItem('accountName')}-preferences`,
  {
    baseId: 0,
    name: '',
  },
);

const modalRouteRef = ref(null);
const modalNewRouteRef = ref(null);

const manualCoordinates = reactive({
  schema: {
    Latitude: {
      prop: 'lat',
      required: true,
      type: (value) => {
        const number = Number(value);
        if (isNaN(number)) {
          throw new Error('invalid');
        }
        return number;
      },
    },
    Longitude: {
      prop: 'lng',
      required: true,
      type: (value) => {
        const number = Number(value);
        if (isNaN(number)) {
          throw new Error('invalid');
        }
        return number;
      },
    },
  },
  errors: [],
  rows: [],
});

const plugins = ref([]);

const unlinkDriver = ref({
  accountName: '',
  routeId: null,
});

onMounted(async () => {
  initializeModals();
  initMap();

  await getMyServiceCenters();

  if (preferences.value.baseId) {
    filter.serviceCenter = preferences.value.baseId;
  } else {
    filter.serviceCenter = '';
  }

  await getRoutes();

  // tabMapRealTime.value.addEventListener('shown.bs.tab', async () => {
  //   console.log('shown.bs.tab');
  //   initMapRealTime();
  //   getLiveRoutes();
  //   updateRoutesWithTime();
  // });
  // tabMapRealTime.value.addEventListener('hidden.bs.tab', async () => {
  //   console.log('hidden.bs.tab');
  //   mapRealTime.remove();
  //   disconnectSocket();

  //   clearInterval(refreshMarkersTime.value);
  // });

  if (modalRouteRef.value) {
    modalRouteRef.value.addEventListener('hidden.bs.modal', () => {
      editRoute.polylineByImport = '';
    });
  }

  if (modalNewRouteRef.value) {
    modalNewRouteRef.value.addEventListener('hidden.bs.modal', () => {
      newRoute.polyline = '';
    });
  }

  await getPlugins();
});

onUnmounted(() => {
  endTimers();

  // tabMapRealTime.value.removeAllEventListeners('shown.bs.tab');
  // tabMapRealTime.value.removeAllEventListeners('hidden.bs.tab');

  // modalRouteRef.value.removeAllEventListeners('hidden.bs.modal');

  // modalNewRouteRef.value.removeAllEventListeners('hidden.bs.modal');
});

async function getPlugins() {
  loading.value = true;
  const response = await pluginService.indexPlugins({
    pluginInfo: false,
  });

  if (response && response.status === 200) {
    plugins.value = response.data;
  }
  loading.value = false;
}

async function onDrop(acceptFiles) {
  if (acceptFiles.length > 0) {
    newRoute.polyline = await readXlsxManualCoordinates(acceptFiles[0]);
    editRoute.polylineByImport = await readXlsxManualCoordinates(
      acceptFiles[0],
    );
  }
}

async function readXlsxManualCoordinates(file) {
  manualCoordinates.errors = [];
  manualCoordinates.rows = [];

  const xlsxRows = await readXlsxFile(file, {
    schema: manualCoordinates.schema,
    transformData(data) {
      return data.filter(
        (row) => row.filter((column) => column !== null).length > 0,
      );
    },
  });

  if (xlsxRows.errors.length) {
    manualCoordinates.errors = xlsxRows.errors;
    return;
  }

  manualCoordinates.rows = xlsxRows.rows;

  return encode(xlsxRows.rows);
}

function endTimers() {
  for (let i = 0; i < routes.value.length; i += 1) {
    if (routes.value[i].timer) {
      routes.value[i].timer.stop();
      routes.value[i].timer.removeAllEventListeners('secondsUpdated');
    }
    routes.value[i].timer = null;
  }
}

async function getRoutes() {
  modals.modalFilter.hide();
  loading.state = true;
  loading.text = 'Carregando rotas...';

  endTimers();

  const response = await routesService.indexRoutes({
    name: filter.name,
    getMeliData: process.env.NODE_ENV === 'production' ? true : false,
    routeId: filter.routeId,
    date: filter.date,
    serviceCenterId: filter.serviceCenter,
  });

  if (response && response.status === 200) {
    routes.value = response.data.routes;
    pendingRoutes.value = response.data.meliRoutes;

    filterRoute();
  }

  loading.state = false;
}

async function getMyServiceCenters() {
  loading.state = true;
  loading.text = 'Carregando base...';
  const response = await serviceCenterService.myServiceCenters();

  if (response && response.status === 200) {
    serviceCenters.value = response.data;
  }

  loading.state = false;
}

async function getDrivers({ driverStatus, serviceCenters, page }) {
  newRoute.driverAccountId = '';
  loading.value = true;
  const response = await driverService.getDrivers({
    driverStatus,
    page,
    serviceCenters,
  });

  if (response && response.status === 200) {
    drivers.value = response.data.sort((a, b) =>
      a.accountName.localeCompare(b.accountName),
    );
    store.commit('setDrivers', drivers.value);
    await getMeliRules(serviceCenters[0]);
  }

  loading.value = false;
}

async function getMeliRules(serviceCenterId) {
  loading.value = true;
  const response = await routesService.getMeliRules({
    serviceCenterId,
  });

  if (response && response.status === 200) {
    meliRules.value.enableKilometer = response.data.enableKilometer;
    meliRules.value.enableKilometerPhotos = response.data.enableKilometerPhotos;
    meliRules.value.kilometerValueRule = response.data.kilometerValueRule;
    meliRules.value.geolocationSourcePriority =
      response.data.geolocationSourcePriority;
    meliRules.value.flag = response.data.flag;
  }

  loading.value = false;
}

async function viewRoute({ routeId, index }) {
  loading.value = true;
  routeIndex.value = index;

  editRoute.imagesPreview = [];
  // gMap = new window.google.maps.Map(
  //   document.getElementById('mapDetails'),
  //   mapOptions,
  // );
  markers.value = [];
  const response = await routesService.getRouteById({
    id: routeId,
  });

  if (response && response.status === 200) {
    editRoute.id = response.data.id;
    editRoute.account = response.data.account;
    editRoute.kilometerValueRule = response.data.kilometerValueRule;
    editRoute.primaryDriver = response.data.primaryDriver;
    editRoute.meliId = response.data.meliId;
    editRoute.statusId = response.data.statusId;
    editRoute.initialKilometer = response.data.initialKilometer;
    editRoute.finalKilometer = response.data.finalKilometer;
    editRoute.initialKilometerPhoto = response.data.initialKilometerPhoto;
    editRoute.finalKilometerPhoto = response.data.finalKilometerPhoto;
    editRoute.meliPhoto = response.data.meliPhoto;
    editRoute.initialSelfiePhoto = response.data.initialSelfiePhoto;
    editRoute.geolocationSource = response.data.geolocationSource;
    editRoute.attachments = response.data.attachments;
    editRoute.tolls = Number(response.data.tolls);
    editRoute.wave = response.data.wave;
    editRoute.statusId = response.data.statusId;
    editRoute.finalDateTime = response.data.finalDateTime;
    editRoute.initialDateTime = response.data.initialDateTime;
    editRoute.vehicle = response.data.vehicle;
    editRoute.totalStops = response.data.totalStops;
    editRoute.numberPackages = response.data.numberPackages;
    editRoute.kmTraveled = response.data.kmTraveled;
    editRoute.geolocation = response.data.geolocation;
    response.data.attachments.map((attachment) =>
      editRoute.imagesPreview.push({
        id: attachment.id,
        routeId: attachment.routeId,
        photo: attachment.attachment,
      }),
    );

    await getGeolocationById({ routeId: response.data.id });

    if (editRoute.hasRoute) {
      drawMapDetails();

      const tabEl = document.querySelector(
        '#myTabRoute button[data-bs-target="#profile-tab-pane"]',
      );
      tabEl.addEventListener(
        'shown.bs.tab',
        () => {
          map.invalidateSize();
          map.fitBounds(detailsPolyline.getBounds());
        },
        { once: true },
      );
    }

    modals.tabRoute.show();
    modals.modalRoute.show();
  }

  loading.value = false;
}

async function createRoute() {
  const params = {
    driverAccountId: newRoute.driverAccountId,
    routeId: newRoute.routeId,
    numberPackages: newRoute.numberPackages,
    finalKilometer:
      newRoute.finalKilometer !== null && newRoute.finalKilometer !== ''
        ? parseInt(newRoute.finalKilometer)
        : null,
    tolls: parseFloat(newRoute.tolls).toFixed(2),
    wave: newRoute.wave,
    carPlate: newRoute.carPlate.toUpperCase(),
    initialKilometer: newRoute.initialKilometer
      ? Number(newRoute.initialKilometer)
      : null,
    initialDate: newRoute.initialDate,
    initialHour: newRoute.initialHour,
    finalDate: newRoute.finalDate,
    finalHour: newRoute.finalHour,
    statusId: newRoute.statusId,
    ended: newRoute.ended,
    kmTraveled: newRoute.kmTraveled ? Number(newRoute.kmTraveled) : null,
    totalStops: newRoute.totalStops ? Number(newRoute.totalStops) : null,
  };

  const form = new FormData();
  Object.keys(params).forEach(function (key) {
    if (params[key] !== null) {
      form.append(key, params[key]);
    }
  });

  if (newRoute.polyline.length) {
    form.append('polyline', newRoute.polyline);
  }

  if (newRoute.meliBlob === '') {
    return toast.error('Insira a foto do app do mercado livre');
  } else {
    form.append('photos', newRoute.meliBlob, 'meliPhoto.webp');
  }

  if (meliRules.value.enableKilometerPhotos) {
    if (newRoute.initialKilometerBlob === '') {
      return toast.error('Insira a foto do odômetro inicial');
    }
    form.append(
      'photos',
      newRoute.initialKilometerBlob,
      'initialKilometerPhoto.webp',
    );
  }

  if (newRoute.initialSelfieBlob === '') {
    return toast.error('Insira a selfie');
  } else {
    form.append(
      'photos',
      newRoute.initialSelfieBlob,
      'initialSelfiePhoto.webp',
    );
  }
  isLoading.value = true;

  // form.append('photos', newRoute.meliBlob, 'meliPhoto.webp');
  // form.append(
  //   'photos',
  //   newRoute.initialKilometerBlob,
  //   'initialKilometerPhoto.webp',
  // );
  // form.append('photos', newRoute.initialSelfieBlob, 'initialSelfiePhoto.webp');

  if (newRoute.ended === 1) {
    if (meliRules.value.enableKilometerPhotos) {
      if (newRoute.finalKilometerBlob === '') {
        return toast.error('Insira a foto do odômetro final');
      }
      form.append(
        'photos',
        newRoute.finalKilometerBlob,
        'finalKilometerBlob.webp',
      );
    }
    newRoute.attachments.forEach(function (attachment) {
      form.append('photos', attachment, 'attachment.webp');
    });
  }

  const response = await routesService.createRoute({ form });

  if (response && response.status === 201) {
    if (response.data.message.length > 0) {
      toast.success(response.data.message);
    } else {
      toast.success('Rota criada com sucesso.');
    }
    modals.modalNewRoute.hide();

    // TO-DO: Refatorar get
    filter.date = newRoute.initialDate;
    filter.serviceCenter = filterDriverServiceCenterId.value;
    filter.routeId = newRoute.routeId;
    await getRoutes();

    newRoute.initialDate = date.getToday();
    newRoute.initialHour = '12:00';
    newRoute.finalDate = '';
    newRoute.finalHour = '';
    newRoute.driverAccountId = null;
    newRoute.mainRouteId = '';
    newRoute.initialKilometer = '';
    newRoute.finalKilometer = '';
    newRoute.initialKilometerPhoto = '';
    newRoute.initialKilometerBlob = '';
    newRoute.finalKilometerPhoto = '';
    newRoute.finalKilometerBlob = '';
    newRoute.initialSelfiePhoto = '';
    newRoute.initialSelfieBlob = '';
    newRoute.meliPhoto = '';
    newRoute.meliBlob = '';
    newRoute.failuresCounter = '';
    newRoute.successCounter = '';
    newRoute.routeId = '';
    newRoute.numberPackages = '';
    newRoute.totalStops = '';
    newRoute.carPlate = '';
    newRoute.wave = 1;
    newRoute.ended = '0';
    newRoute.statusId = 6;
    newRoute.attachments = [];
    newRoute.imagesPreview = [];
    newRoute.tolls = '0.00';
    manualCoordinates.rows = [];
    manualCoordinates.errors = [];

    newRoute.polyline = '';
  }

  isLoading.value = false;
}

async function deleteRoute({ allRoutes }) {
  isLoading.value = true;

  const response = await routesService.deleteRoute({
    id: deleteRouteData.routeId,
    allRoutes,
  });

  if (response && response.status === 204) {
    const routeDeleteIndex = routes.value.findIndex(
      (route) => route.id === deleteRouteData.routeId,
    );

    routes.value.splice(routeDeleteIndex, 1);

    deleteRouteData.accountName = '';
    deleteRouteData.meliId = '';
    deleteRouteData.routeId = 0;

    filterRoute();

    toast.success('Rota excluída com sucesso.');
    modals.modalConfirmDeleteRoute.hide();
  }

  isLoading.value = false;
}

function openModalConfirmDeleteRoute({ route }) {
  deleteRouteData.accountName = route.accountName;
  deleteRouteData.meliId = route.routeId;
  deleteRouteData.routeId = route.id;
  modals.modalConfirmDeleteRoute.show();
}

function viewRouteStatus({ statusId, routeId }) {
  updateStatusRoute.routeId = routeId;
  updateStatusRoute.statusId = statusId;
  modals.modalRouteStatus.show();
}

async function updateRouteStatus() {
  isLoading.value = true;

  const response = await routesService.updateRouteStatus({
    id: updateStatusRoute.routeId,
    statusId: updateStatusRoute.statusId,
  });

  if (response && response.status === 204) {
    const findRouteIndex = routes.value.findIndex(
      (route) => route.id === updateStatusRoute.routeId,
    );

    routes.value[findRouteIndex].statusId = updateStatusRoute.statusId;

    if (routes.value[findRouteIndex].timer) {
      routes.value[findRouteIndex].timer.removeAllEventListeners(
        'secondsUpdated',
      );
    }

    if (isRouteEnded(routes.value[findRouteIndex].statusId)) {
      routes.value[findRouteIndex].ended = true;
      if (routes.value[findRouteIndex].timer) {
        routes.value[findRouteIndex].timer.pause();
      }

      if (
        // !routes.value[findRouteIndex].finalKilometer ||
        routes.value[findRouteIndex].finalKilometer === '' ||
        // routes.value[findRouteIndex].finalKilometer === 'null' ||
        // routes.value[findRouteIndex].finalKilometer === 'undefined' ||
        routes.value[findRouteIndex].initialKilometer === 0
      ) {
        routes.value[findRouteIndex].statusFinalKm = true;
      }

      const totalKm =
        parseInt(routes.value[findRouteIndex].finalKilometer) -
        parseInt(routes.value[findRouteIndex].initialKilometer);
      if (totalKm) {
        if (totalKm > 300 || totalKm <= 0) {
          routes.value[findRouteIndex].statusTotalKm = true;
        }
      }
    } else {
      routes.value[findRouteIndex].ended = false;
      if (!routes.value[findRouteIndex].timer) {
        routes.value[findRouteIndex].timer = initTimer(findRouteIndex);
      } else {
        routes.value[findRouteIndex].timer.start();
      }

      routes.value[findRouteIndex].statusFinalKm = false;
      routes.value[findRouteIndex].statusTotalKm = false;
    }

    // updateStatusRoute.id = null;
    // updateStatusRoute.statusId = null;

    toast.success('Atualizado com sucesso.');
    modals.modalRouteStatus.hide();
  }

  isLoading.value = false;
}

async function getGeolocationById({ routeId }) {
  const response = await routesService.getGeolocation({
    routeId,
  });

  if (response && response.status === 200) {
    editRoute.hasRoute = true;
    editRoute.stops = response.data.stops;
    editRoute.polyline = response.data.polyline;
    editRoute.distanceMeters = (response.data.distanceMeters / 1000).toFixed(0);
    editRoute.serviceCenterLocation = response.data.serviceCenterLocation;
    editRoute.initialLocation = response.data.initialLocation;
    editRoute.finalLocation = response.data.finalLocation;
    editRoute.flag = response.data.flag;
    clearMap();
  } else {
    editRoute.hasRoute = false;
  }
}

function clearMap() {
  // clear polyline and markers
  map.eachLayer(function (layer) {
    if (layer instanceof L.Polyline || layer instanceof L.Marker) {
      map.removeLayer(layer);
    }
    // map.removeLayer(layer);
    // console.log('layer', layer);
  });
  // clear markers
  // for (let i = 0; i < editRouteData.markerIfApp.length; i++) {
  //   map.removeLayer(editRouteData.markerIfApp[i]);
  // }
}

function drawMapDetails() {
  // const bounds = L.latLngBounds();
  const polylineDecoded = polyUtil.decode(editRoute.polyline);
  // polylineDecoded.map((item) => {
  //   bounds.extend(L.latLng(item[0], item[1]));
  // });
  if (
    editRoute.serviceCenterLocation?.lat &&
    editRoute.serviceCenterLocation?.lng
  ) {
    const baseMarker = L.marker(
      [
        editRoute.serviceCenterLocation.lat,
        editRoute.serviceCenterLocation.lng,
      ],
      {
        icon: L.divIcon({
          iconSize: null,
          html: `<div class="map-label">
            <div class="map-label-content base">BASE</div>
            <div class="map-label-arrow base "></div></div>`,
        }),
        draggable: false,
        title: 'BASE',
      },
    ).addTo(map);

    markers.value.push(baseMarker);
  }

  if (editRoute.stops.length) {
    editRoute.stops.map((stop, index) => {
      const stopMarker = L.marker([stop.lat, stop.lng], {
        icon: L.divIcon({
          iconSize: null,
          html: `<div class="map-label">
          <div class="map-label-content">${index + 1}</div>
          <div class="map-label-arrow"></div></div>`,
        }),
        draggable: false,
        title: 'MELISTOP',
      }).addTo(map);

      markers.value.push(stopMarker);
    });
  }

  if (editRoute.finalLocation) {
    if (editRoute.finalLocation.lat && editRoute.finalLocation.lng) {
      markers.value.push(
        L.marker([editRoute.finalLocation.lat, editRoute.finalLocation.lng], {
          draggable: false,
          icon: L.divIcon({
            iconSize: null,
            html: `<div class="map-label">
        <div class="map-label-content final">FIM</div>
          <div class="map-label-arrow final"></div></div>`,
          }),
          title: 'FIM',
        }).addTo(map),
      );
    }
  }

  if (editRoute.initialLocation) {
    if (editRoute.initialLocation.lat && editRoute.initialLocation.lng) {
      markers.value.push(
        L.marker(
          [editRoute.initialLocation.lat, editRoute.initialLocation.lng],
          {
            draggable: false,
            icon: L.divIcon({
              iconSize: null,
              html: `<div class="map-label">
        <div class="map-label-content initial">INÍCIO</div>
          <div class="map-label-arrow initial"></div></div>`,
            }),
            title: 'INÍCIO',
          },
        ).addTo(map),
      );
    }
  }
  detailsPolyline = L.polyline(polylineDecoded, {
    color: '#f35e1d',
    weight: 6,
    opacity: 0.6,
  });

  detailsPolyline.addTo(map);
}

function filterRoute() {
  routeTotals.totalPackages = 0;
  routeTotals.totalSuccess = 0;
  routeTotals.totalFailures = 0;
  routeTotals.totalRoutes = 0;
  routeTotals.totalRoutesEnded = 0;
  routeTotals.totalRoutesTravel = 0;
  routeTotals.totalReal = 0;
  routeTotals.totalDivided = 0;
  routeTotals.totalGeolocation = 0;
  routeTotals.totalNotNull = 0;

  for (let el in routes.value) {
    if (routes.value[el].routeId) {
      routeTotals.totalReal += 1;
    }
  }

  for (let i = 0; i < routes.value.length; i += 1) {
    if (routes.value[i].hasGeolocation !== null) {
      routeTotals.totalNotNull += 1;
      if (routes.value[i].hasGeolocation === true) {
        routeTotals.totalGeolocation += 1;
      } else {
        routeTotals.totalNoGeolocation += 1;
      }
    }

    if (routes.value[i].meliData) {
      hasMeli.value = true;
    }
    routeTotals.totalRoutes += 1;
    if (routes.value[i].numberPackages) {
      routeTotals.totalPackages += parseInt(routes.value[i].numberPackages);
      routes.value[i].statusTotalKm = false;
      routes.value[i].statusInitialKm = false;
      routes.value[i].statusFinalKm = false;
      routes.value[i].statusPackages = false;

      if (
        !routes.value[i].numberPackages ||
        routes.value[i].numberPackages >= 200 ||
        routes.value[i].numberPackages === null ||
        routes.value[i].numberPackages === undefined ||
        routes.value[i].numberPackages <= 0
      ) {
        routes.value[i].statusPackages = true;
      }

      if (
        !routes.value[i].initialKilometer ||
        routes.value[i].initialKilometer === '' ||
        routes.value[i].initialKilometer === null ||
        routes.value[i].initialKilometer === undefined ||
        routes.value[i].initialKilometer < 0
      ) {
        routes.value[i].statusInitialKm = true;
      }

      if (!routes.value[i].ended) {
        routeTotals.totalRoutesTravel += 1;
        routes.value[i].timer = initTimer(i);
        routeTotals.totalSuccess += routes.value[i].successCounter;
        routeTotals.totalFailures += routes.value[i].failuresCounter;
      } else {
        routeTotals.totalSuccess += routes.value[i].successCounter;
        routeTotals.totalFailures += routes.value[i].failuresCounter;

        if (
          !routes.value[i].finalKilometer ||
          routes.value[i].finalKilometer === '' ||
          routes.value[i].finalKilometer === null ||
          routes.value[i].finalKilometer === undefined ||
          routes.value[i].finalKilometer <= 0
        ) {
          routes.value[i].statusFinalKm = true;
        }

        const totalKm =
          parseInt(routes.value[i].finalKilometer) -
          parseInt(routes.value[i].initialKilometer);
        if (totalKm)
          if (totalKm > 300 || totalKm <= 0)
            routes.value[i].statusTotalKm = true;
        routeTotals.totalRoutesEnded += 1;
        routes.value[i].timer = null;
      }
    }
  }

  routeTotals.totalDivided = routeTotals.totalRoutes - routeTotals.totalReal;
}

async function exportToCsv() {
  isLoading.value = true;

  const response = await routesService.exportRoutes({
    initialDate: exportCsv.initialDate,
    finalDate: exportCsv.finalDate,
    serviceCenterId: exportCsv.serviceCenter,
  });

  if (response && response.status === 200) {
    jsonConvertor.JSONToCSVConvertor(response.data, 1, 'Rotas.csv');
    modals.modalExport.hide();
  }

  isLoading.value = false;
}

function initTimer(index) {
  let split = routes.value[index].time.split(':');
  const timer = new Timer();

  timer.start({
    precision: 'seconds',
    startValues: {
      hours: parseInt(split[0]),
      minutes: parseInt(split[1]),
      seconds: parseInt(split[2]),
    },
  });

  timer.addEventListener('secondsUpdated', function secondsUpdated() {
    const element = document.getElementById(`timer_${index}`);
    if (element) {
      element.innerHTML = timer
        .getTimeValues()
        .toString(['hours', 'minutes', 'seconds']);
    }
  });

  return timer;
}

function isRouteEnded(statusId) {
  if (statusId === 7 || statusId === 8 || statusId === 5) {
    return true;
  }

  return false;
}

function initializeModals() {
  modals.modalExport = new Modal(document.getElementById('modal-export'));
  modals.modalConfirmDeleteRoute = new Modal(
    document.getElementById('modal-confirm-delete-route'),
  );
  modals.modalRouteStatus = new Modal(
    document.getElementById('modal-route-status'),
  );
  modals.modalNewRoute = new Modal(document.getElementById('modal-new-route'));
  modals.modalRoute = new Modal(document.getElementById('modal-route'));
  modals.modalFilter = new Modal(document.getElementById('modal-filter'));
  modals.modalSelfiePhoto = new Modal(
    document.getElementById('modal-selfiePhoto'),
  );
  modals.modalInitialKilometerPhoto = new Modal(
    document.getElementById('modal-initialKilometerPhoto'),
  );
  modals.modalFinalKilometerPhoto = new Modal(
    document.getElementById('modal-finalKilometerPhoto'),
  );
  modals.modalMeliPhoto = new Modal(document.getElementById('modal-meliPhoto'));
  modals.modalUpdateSelfiePhoto = new Modal(
    document.getElementById('modal-update-selfiePhoto'),
  );
  modals.modalUpdateInitialKilometerPhoto = new Modal(
    document.getElementById('modal-update-initialKilometerPhoto'),
  );
  modals.modalUpdateMeliPhoto = new Modal(
    document.getElementById('modal-update-meliPhoto'),
  );
  modals.modalUpdateFinalKilometerPhoto = new Modal(
    document.getElementById('modal-update-finalKilometerPhoto'),
  );
  modals.modalAttachments = new Modal(
    document.getElementById('modal-attachments'),
  );
  // modals.modalUpdateAttachments = new Modal(
  //   document.getElementById('modal-update-attachments'),
  // );
  modals.modalUpdateRouteValues = new Modal(
    document.getElementById('modal-updateRouteValues'),
  );
  // modals.offcanvasRoutes = new Offcanvas(
  //   document.getElementById('offcanvas-modal-routes-map'),
  // );
  // modals.modalRoutesMap = new Modal(
  //   document.getElementById('modal-routes-map'),
  // );
  modals.modalRestoreRoute = new Modal(
    document.getElementById('modal-route-restored-confirmartion'),
  );

  modals.tabRoute = new Tab(
    document.querySelector(
      '#myTabRoute button[data-bs-target="#home-tab-pane"]',
    ),
  );

  modals.modalManualCoordinates = new Modal(
    document.getElementById('modalManualCoordinates'),
  );

  modals.modalCoordinatorsErros = new Modal(
    document.getElementById('modalCoordinatorsErros'),
  );

  modals.modalSchemaXlsxErrors = new Modal(
    document.getElementById('modalSchemaXlsxErrors'),
  );

  modals.modalUnlinkDriver = new Modal(
    document.getElementById('modal-unlink-driver'),
  );
}

function initMap() {
  map = L.map('map', {
    zoomControl: true,
    dragging: true,
    maxZoom: 18,
    minZoom: 5,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: 'topleft',
    },
  }).setView([-13.8277929, -46.4530738], 5);

  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(map);
}

function openLightboxImage(image) {
  lightboxImage.imgs = [];
  lightboxImage.imgs.push({
    title: '',
    src: image,
  });
  lightboxImage.visible = true;
}

function editMeliPhoto(event) {
  const input = event.target;
  loading.value = true;
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (e) => {
      cropperConfig.image = e.target.result;
      modals.modalMeliPhoto.show();
    };
    reader.readAsDataURL(input.files[0]);
  }
  $('#newRouteMeliPhoto').prop('value', '');
  loading.value = false;
}

function cropMeliPhoto() {
  if (cropperConfig.canvas === null) {
    toast.error('Não foi possível atualizar a foto.');
  } else {
    cropperConfig.canvas.toBlob(function (blob) {
      newRoute.meliPhoto = window.URL.createObjectURL(blob);
      newRoute.meliBlob = blob;
      cropperConfig.image = null;
      cropperConfig.canvas = null;
    }, 'image/webp');
    modals.modalMeliPhoto.hide();
  }
}

function changePhotoArea({ coordinates, canvas }) {
  if (coordinates.width !== 0 || firstTimeRun.value) {
    cropperConfig.canvas = canvas;
    firstTimeRun.value = false;
  }
}

function hideLoading() {
  loading.value = false;
}

function editInitialKilometerPhoto(event) {
  const input = event.target;
  loading.value = true;
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (e) => {
      cropperConfig.image = e.target.result;
      modals.modalInitialKilometerPhoto.show();
    };
    reader.readAsDataURL(input.files[0]);
  }
  $('#newRouteOdometerPhoto').prop('value', '');
  loading.value = false;
}

function cropInitialKilometerPhoto() {
  cropperConfig.canvas.toBlob(function (blob) {
    newRoute.initialKilometerPhoto = URL.createObjectURL(blob);
    newRoute.initialKilometerBlob = blob;
    cropperConfig.image = null;
    cropperConfig.canvas = null;
  }, 'image/webp');
  modals.modalInitialKilometerPhoto.hide();
}

function editSelfiePhoto(event) {
  const input = event.target;
  loading.value = true;
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (e) => {
      cropperConfig.image = e.target.result;
      modals.modalSelfiePhoto.show();
    };
    reader.readAsDataURL(input.files[0]);
  }
  $('#newRouteSelfiePhoto').prop('value', '');
  loading.value = false;
}

function cropPhotoSelfie() {
  cropperConfig.canvas.toBlob(function (blob) {
    newRoute.initialSelfiePhoto = URL.createObjectURL(blob);
    newRoute.initialSelfieBlob = blob;
    cropperConfig.image = null;
    cropperConfig.canvas = null;
  }, 'image/webp');
  modals.modalSelfiePhoto.hide();
}

function editFinalKilometerPhoto(event) {
  const input = event.target;
  loading.value = true;
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (e) => {
      cropperConfig.image = e.target.result;
      modals.modalFinalKilometerPhoto.show();
    };
    reader.readAsDataURL(input.files[0]);
  }
  $('#newRouteFinalKilometerPhoto').prop('value', '');
  loading.value = false;
}

function cropFinalKilometerPhoto() {
  cropperConfig.canvas.toBlob(function (blob) {
    newRoute.finalKilometerPhoto = URL.createObjectURL(blob);
    newRoute.finalKilometerBlob = blob;
    cropperConfig.image = null;
    cropperConfig.canvas = null;
  }, 'image/webp');
  modals.modalFinalKilometerPhoto.hide();
}

function editAttachments(event) {
  const target = event.target;
  loading.value = true;
  if (target.files && target.files[0]) {
    const reader = new FileReader();
    reader.onload = (e) => {
      cropperConfig.image = e.target.result;
      modals.modalAttachments.show();
    };
    reader.readAsDataURL(target.files[0]);
  }
  $('#newUpdateAttachment').prop('value', '');
  loading.value = false;
}

function cropAttachments() {
  cropperConfig.canvas.toBlob(function (blob) {
    newRoute.attachments.push({
      attachment: URL.createObjectURL(blob),
      attachmentBlob: blob,
    });
    cropperConfig.image = null;
    cropperConfig.canvas = null;
  }, 'image/webp');
  modals.modalAttachments.hide();
}

function editUpdateMeliPhoto(event) {
  const input = event.target;
  loading.value = true;
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (e) => {
      cropperConfig.image = e.target.result;
      modals.modalUpdateMeliPhoto.show();
    };
    reader.readAsDataURL(input.files[0]);
  }
  $('#UpdateIDMeli').prop('value', '');
  loading.value = false;
}

function cropUpdateMeliPhoto() {
  cropperConfig.canvas.toBlob(function (blob) {
    editRoute.meliPhoto = URL.createObjectURL(blob);

    editRoute.meliPhotoBlob = blob;
    updateMeliPhoto();
    cropperConfig.image = null;
    cropperConfig.canvas = null;
  }, 'image/webp');
}

async function updateMeliPhoto() {
  isLoading.value = true;

  const form = new FormData();
  form.append('photo', editRoute.meliPhotoBlob, 'photo.webp');

  const response = await routesService.updateMeliPhoto({
    id: editRoute.id,
    form,
  });

  if (response && response.status === 200) {
    modals.modalUpdateMeliPhoto.hide();
    toast.success('Foto atualizada com sucesso.');
  }

  isLoading.value = false;
}

function cropUpdateInitialKilometerPhoto() {
  cropperConfig.canvas.toBlob(function (blob) {
    editRoute.initialKilometerPhoto = URL.createObjectURL(blob);
    editRoute.initialKilometerBlob = blob;
    updateInitialKilometerPhoto();
    cropperConfig.image = null;
    cropperConfig.canvas = null;
  }, 'image/webp');
}

async function updateInitialKilometerPhoto() {
  isLoading.value = true;

  const form = new FormData();
  form.append('photo', editRoute.initialKilometerBlob, 'photo.webp');

  const response = await routesService.updateInitialKilometerPhoto({
    id: editRoute.id,
    form,
  });

  if (response && response.status === 200) {
    modals.modalUpdateInitialKilometerPhoto.hide();
    toast.success('Foto atualizada com sucesso.');
  }

  isLoading.value = false;
}

function editUpdateInitialKilometerPhoto(event) {
  const input = event.target;
  loading.value = true;
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (e) => {
      cropperConfig.image = e.target.result;
      modals.modalUpdateInitialKilometerPhoto.show();
    };
    reader.readAsDataURL(input.files[0]);
  }
  $('#updateInitialKilometer').prop('value', '');
  loading.value = false;
}

function editUpdateSelfiePhoto(event) {
  const input = event.target;
  loading.value = true;
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (e) => {
      cropperConfig.image = e.target.result;
      modals.modalUpdateSelfiePhoto.show();
    };
    reader.readAsDataURL(input.files[0]);
  }
  $('#updateSelfie').prop('value', '');
  loading.value = false;
}

function cropUpdateSelfiePhoto() {
  cropperConfig.canvas.toBlob(function (blob) {
    editRoute.initialSelfiePhoto = URL.createObjectURL(blob);
    editRoute.initialSelfiePhotoBlob = blob;
    updateSelfiePhoto();
    cropperConfig.image = null;
    cropperConfig.canvas = null;
  }, 'image/webp');
}

async function updateSelfiePhoto() {
  isLoading.value = true;

  const form = new FormData();
  form.append('photo', editRoute.initialSelfiePhotoBlob, 'photo.webp');

  const response = await routesService.updateSelfiePhoto({
    id: editRoute.id,
    form,
  });

  if (response && response.status === 200) {
    modals.modalUpdateSelfiePhoto.hide();
    toast.success('Foto atualizada com sucesso.');
  }

  isLoading.value = false;
}

function editUpdateFinalKilometerPhoto(event) {
  const input = event.target;
  loading.value = true;
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (e) => {
      cropperConfig.image = e.target.result;
      modals.modalUpdateFinalKilometerPhoto.show();
    };
    reader.readAsDataURL(input.files[0]);
  }
  $('#updateFinalKilometer').prop('value', '');
  loading.value = false;
}

function cropUpdateFinalKilometerPhoto() {
  cropperConfig.canvas.toBlob(function (blob) {
    editRoute.finalKilometerPhoto = URL.createObjectURL(blob);
    editRoute.finalKilometerBlob = blob;
    updateFinalKilometerPhoto();
    cropperConfig.image = null;
    cropperConfig.canvas = null;
  }, 'image/webp');
}

async function updateFinalKilometerPhoto() {
  isLoading.value = true;

  const form = new FormData();
  form.append('photo', editRoute.finalKilometerBlob, 'photo.webp');

  const response = await routesService.updateFinalKilometerPhoto({
    id: editRoute.id,
    form,
  });

  if (response && response.status === 200) {
    modals.modalUpdateFinalKilometerPhoto.hide();
    toast.success('Foto atualizada com sucesso.');
  }

  isLoading.value = false;
}

function handleSelectEditImages(event) {
  if (!event.target.files) {
    return;
  }

  const selectedImages = Array.from(event.target.files);

  selectedImages.map((selectedImg) => editRoute.attachments.push(selectedImg));

  const selectedImagesPreview = selectedImages.map((image) => {
    return URL.createObjectURL(image);
  });

  selectedImagesPreview.map(
    (imgPreview) =>
      imgPreview &&
      editRoute.imagesPreview.push({
        id: null,
        photo: imgPreview,
      }),
  );

  event.target.value = '';
}

function handleSelectNewRouteImages(event) {
  if (!event.target.files) {
    return;
  }

  const selectedImages = Array.from(event.target.files);

  selectedImages.map((selectedImg) => newRoute.attachments.push(selectedImg));

  const selectedImagesPreview = selectedImages.map((image) => {
    return URL.createObjectURL(image);
  });

  selectedImagesPreview.map(
    (imgPreview) =>
      imgPreview &&
      newRoute.imagesPreview.push({
        id: null,
        photo: imgPreview,
      }),
  );

  event.target.value = '';
}

async function deleteAttachment({ attachment, index }) {
  isDeletingPhoto.value = true;

  if (!attachment.id) {
    editRoute.attachments.splice(index, 1);
    editRoute.imagesPreview.splice(index, 1);
    isDeletingPhoto.value = false;
    return;
  }

  const response = await routesService.deleteAttachment({
    routeId: attachment.routeId,
    id: attachment.id,
  });

  if (response && response.status === 204) {
    toast.success('Foto excluída com sucesso!');
    editRoute.imagesPreview = editRoute.imagesPreview.filter(
      (photo) => photo.id !== attachment.id,
    );
  }

  isDeletingPhoto.value = false;
}

async function deletenewRouteAttachment({ index }) {
  newRoute.attachments.splice(index, 1);
  newRoute.imagesPreview.splice(index, 1);
}

function verifyUpdateRouteValues() {
  if (verifyIfEnded() == '1') {
    modals.modalUpdateRouteValues.show();
  } else {
    updateRoute(false, false);
  }
}

function verifyIfEnded() {
  if (
    editRoute.statusId == 4 ||
    editRoute.statusId == 5 ||
    editRoute.statusId == 7 ||
    editRoute.statusId == 8
  ) {
    return '1';
  } else {
    return '0';
  }
}

async function updateRoute(updateRouteValues, updateSameIdRouteValues) {
  isLoading.value = true;

  let ended = verifyIfEnded();

  const params = {
    meliRouteId: editRoute.meliId,
    numberPackages: editRoute.numberPackages,
    totalStops: editRoute.totalStops,
    finalKilometer: editRoute.finalKilometer,
    tolls: editRoute.tolls,

    wave: editRoute.wave,
    carPlate: editRoute.vehicle.plate,
    initialKilometer: editRoute.initialKilometer,

    initialDateTime: editRoute.initialDateTime,
    finalDateTime: editRoute.finalDateTime || '',
    statusId: editRoute.statusId,
    ended: ended,
    updateRouteValues: updateRouteValues,
    updateSameIdRouteValues: updateSameIdRouteValues,
    kmTraveled: editRoute.kmTraveled,
  };

  const form = new FormData();
  Object.keys(params).forEach(function (key) {
    if (params[key] !== null) {
      form.append(key, params[key]);
    }
  });

  if (editRoute.polylineByImport.length) {
    form.append('polyline', editRoute.polylineByImport);
  }

  editRoute.attachments.forEach(function (attachment) {
    if (!attachment.id) {
      form.append('photos', attachment, 'attachments.webp');
    }
  });

  const response = await routesService.updateRoute({
    id: editRoute.id,
    form,
  });

  if (response && response.status === 204) {
    // routesFiltered.value[routeIndex.value].statusTotalKm = false;
    // routesFiltered.value[routeIndex.value].statusInitialKm = false;
    // routesFiltered.value[routeIndex.value].statusFinalKm = false;
    // routesFiltered.value[routeIndex.value].statusPackages = false;

    // if (
    //   !routesFiltered.value[routeIndex.value].numberPackages ||
    //   routesFiltered.value[routeIndex.value].numberPackages >= 200 ||
    //   routesFiltered.value[routeIndex.value].numberPackages == 'null' ||
    //   routesFiltered.value[routeIndex.value].numberPackages == 'undefined' ||
    //   routesFiltered.value[routeIndex.value].numberPackages <= 0
    // ) {
    //   routesFiltered.value[routeIndex.value].statusPackages = true;
    // }

    // if (
    //   !routesFiltered.value[routeIndex.value].initialKilometer ||
    //   routesFiltered.value[routeIndex.value].initialKilometer == '' ||
    //   routesFiltered.value[routeIndex.value].initialKilometer == 'null' ||
    //   routesFiltered.value[routeIndex.value].initialKilometer == 'undefined' ||
    //   routesFiltered.value[routeIndex.value].initialKilometer < 0
    // ) {
    //   routesFiltered.value[routeIndex.value].statusInitialKm = true;
    // }
    // if (routesFiltered.value[routeIndex.value].ended) {
    //   if (
    //     !routesFiltered.value[routeIndex.value].finalKilometer ||
    //     routesFiltered.value[routeIndex.value].finalKilometer == '' ||
    //     routesFiltered.value[routeIndex.value].finalKilometer == 'null' ||
    //     routesFiltered.value[routeIndex.value].finalKilometer == 'undefined' ||
    //     routesFiltered.value[routeIndex.value].finalKilometer <= 0
    //   ) {
    //     routesFiltered.value[routeIndex.value].statusFinalKm = true;
    //   }
    //   const totalKm =
    //     parseInt(routesFiltered.value[routeIndex.value].finalKilometer) -
    //     parseInt(routesFiltered.value[routeIndex.value].initialKilometer);
    //   if (totalKm){
    //     if (totalKm > 300 || totalKm <= 0)
    //       routesFiltered.value[routeIndex.value].statusTotalKm = true;
    // }

    editRoute.polylineByImport = '';
    manualCoordinates.rows = [];
    toast.success('Atualizado com sucesso.');
    modals.modalRoute.hide();
    modals.modalUpdateRouteValues.hide();
  }

  isLoading.value = false;
}

async function copyApiText() {
  const kmDifference =
    editRoute.finalKilometer === ''
      ? '-'
      : editRoute.finalKilometer - editRoute.initialKilometer;

  navigator.clipboard.writeText(kmDifference);
}

async function showPendents() {
  loading.state = true;
  loading.text = 'Carregando...';
  isShowPendents.value = !isShowPendents.value;
  if (isShowPendents.value) {
    meliRoutes.value = pendingRoutes.value;
  }

  await nextTick(() => {
    loading.state = false;
  });
}

function openModalRestoreRoute() {
  modals.modalRestoreRoute.show();
}

async function restoreRouteById() {
  loading.value = true;
  const response = await routesService.restoreRouteById({
    routeId: editRoute.id,
  });
  if (response && response.status === 204) {
    toast.success('Rota restaurada com sucesso.');

    modals.modalRestoreRoute.hide();
    // await getRoutes();
  }
  modals.modalRoute.hide();
  loading.value = false;
}

watch(
  () => routes.value,
  () => {
    testKey.value += 1;
  },
);

function initMapRealTime() {
  mapRealTime = L.map('realTimeMap', {
    zoomControl: true,
    dragging: true,
    maxZoom: 18,
    minZoom: 5,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: 'topleft',
    },
  }).setView([-13.8277929, -46.4530738], 5);

  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(mapRealTime);
}

function disconnectSocket() {
  socket.state.disconnect();
  socket.state.off('geolocation', updateRoutes);
  clearInterval(refreshMarkersTime.value);
}

async function updateRoutesWithTime() {
  refreshMarkersTime.value = setInterval(async () => {
    markersRealTime.value.forEach((marker) => {
      const markerExists = markers.value.find(
        (markerMap) => markerMap.title === marker.accountName,
      );

      if (markerExists) {
        markerExists.setLatLng([
          markerExists.position.lat,
          markerExists.position.lng,
        ]);
      } else {
        const options = {
          draggable: false,
          icon: L.divIcon({
            iconSize: null,
            html: `<div class="map-label ${''}">
                  <div class="map-label-content">
                  <img src="${marker.avatar}"  class="img-fluid" alt="..." />
                  </div>
                  <div class="map-label-arrow"></div></div>`,
          }),
          title: marker.accountName,
        };

        const newMarker = L.marker(
          [marker.position.lat, marker.position.lng],
          options,
        ).bindPopup(`<div class="card" style="width: 18rem">
                    <img
                      src="${marker.customData.avatar}"
                      class="card-img-top img-fluid"
                      alt="..."
                    />

                    <div class="card-body">
                      <h5 class="card-title m-0">
                        ${marker.customData.accountName}
                      </h5>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <strong>ID rota:</strong>
                       ${marker.customData.routeId}
                      </li>
                      <li class="list-group-item">
                        <strong>ID conta:${marker.customData.accountId}</strong>
                      </li>
                    </ul>
                    <div
                      class="card-body"
                      v-if="${marker.customData.unstableConnection}"
                    >
                      <div class="alert alert-warning m-0 fw-bold" role="alert">
                        <font-awesome-icon
                          icon="fa-regular fa-triangle-exclamation"
                        />
                        Conexão com o motorista instável
                      </div>
                    </div>
                  </div>`);

        markers.value.push(newMarker);
      }
    });
  }, 2000);
}

function updateRoutes(data) {
  data.drivers.map((route) => {
    const driverExists = computed(() => {
      return markersRealTime.value.findIndex(
        (driverRoute) => driverRoute.accountId === route.accountId,
      );
    });

    if (route.geolocation) {
      if (driverExists.value > -1) {
        if (
          markersRealTime.value[driverExists.value].position.lat !==
            route.geolocation.lat ||
          markersRealTime.value[driverExists.value].position.lng !==
            route.geolocation.lng
        ) {
          markersRealTime.value[driverExists.value].position.lat =
            route.geolocation.lat;
          markersRealTime.value[driverExists.value].position.lng =
            route.geolocation.lng;
        }
      } else {
        markersRealTime.value.push({
          serviceCenterId: data.serviceCenterId,
          routeId: route.geolocation ? route.geolocation.routeId : 0,
          position: {
            lat: route.geolocation ? route.geolocation.lat : 0,
            lng: route.geolocation ? route.geolocation.lng : 0,
          },
          avatar: route.avatar,
          accountName: route.accountName,
          accountId: route.accountId,
          unstableConnection: route.unstableConnection,
        });
      }
    }
  });
}

async function getLiveRoutes() {
  socket.state.connect();

  socket.state.on('geolocation', updateRoutes);

  socket.state.on('error', (err) => {
    toast.error(err.message);
  });
}

function calculatePercentDeliveries(total, delivered) {
  if (total === 0) return 0;
  let percent = delivered / total;
  if (percent > 100) {
    return 100;
  }
  return Math.round(parseFloat(percent) * 100);
}

function calculateProductionPerHour(route) {
  if (route.meliData == null) return '-';
  if (
    route.meliData.ozh == '00:00' ||
    route.meliData.ozh == '0:00' ||
    route.meliData.ozh == '' ||
    route.meliData.ozh == null
  )
    return '-';

  let totalDelivered =
    route.meliData.residentialDelivered + route.meliData.commercialDelivered;
  let hours =
    parseFloat(route.meliData.ozh.split(':')[0]) +
    parseFloat(route.meliData.ozh.split(':')[1] / 60);
  return (totalDelivered / hours).toFixed(1).toString();
}

function calculatePrevisionToEnd(route) {
  if (route.meliData == null) return '-';

  let pending =
    route.meliData.totalCommercial -
    route.meliData.commercialDelivered -
    route.meliData.commercialFailures +
    (route.meliData.totalResidential -
      route.meliData.residentialDelivered -
      route.meliData.residentialFailures);
  if (pending == 0) return '-';

  if (
    route.meliData.commercialDelivered == 0 &&
    route.meliData.residentialDelivered == 0 &&
    route.meliData.commercialFailures == 0 &&
    route.meliData.residentialFailures == 0
  )
    return '-';

  let productionPerHour = calculateProductionPerHour(route);
  if (productionPerHour == '0.0') return '-';

  let produtionPending =
    (pending / parseFloat(productionPerHour)) * 60 * 60 * 1000;
  let final = '-';
  if (route.now) {
    let prevision = new Date(route.now).getTime();
    final = new Date(prevision + produtionPending);
  }
  return filters.dateTimeDisplay(final);
}

async function updateRouteImgsOrder() {
  isLoading.value = true;

  const response = await routesService.updateImgsRouteOrder({
    origin: dragNDropImgs.origin,
    destiny: dragNDropImgs.destiny,
    routeId: editRoute.id,
    originId: dragNDropImgs.originId,
    destinyId: dragNDropImgs.destinyId,
  });

  if (response && response.status === 204) {
    isLoading.value = false;
    dragNDropImgs.originId = null;
    dragNDropImgs.destinyId = null;
    toast.success('Ordem das imagens atualizada com sucesso!');
    return true;
  }
  isLoading.value = false;
  return null;
}

function dragStart(event, type, originId, index) {
  event.dataTransfer.dropEffect = 'move';
  event.dataTransfer.effectAllowed = 'move';
  event.dataTransfer.setData('originID', type);

  dragNDropImgs.origin = type;
  if (type === 'attachmentPhoto') {
    dragNDropImgs.originId = originId;
    event.dataTransfer.setData('originIndex', index);
  }
}

async function drop(event, type, index) {
  const originID = event.dataTransfer.getData('originID');
  const originIndex = event.dataTransfer.getData('originIndex');

  if (type === 'attachmentPhoto') {
    dragNDropImgs.destinyId = editRoute.imagesPreview[index].id;
    dragNDropImgs.originId = null;
  } else {
    dragNDropImgs.destinyId = null;
  }

  dragNDropImgs.destiny = event.target.id;

  if (originID === event.target.id) {
    return;
  }

  if (!event.target.id) {
    return toast.error(
      'Erro ao atualizar ordem das imagens. Arraste a imagem para uma posição válida.',
    );
  }

  const response = await updateRouteImgsOrder();

  if (!response) {
    return;
  }

  const origin = document.getElementById(originID);
  const destiny = document.getElementById(event.target.id);

  if (type === 'attachmentPhoto' || originID === 'attachmentPhoto') {
    if (type === 'attachmentPhoto') {
      const origin = document.getElementById(originID);
      let tempOrigin = origin.src;

      origin.src = editRoute.imagesPreview[index].photo;
      editRoute.imagesPreview[index].photo = tempOrigin;
    }
    if (originID === 'attachmentPhoto') {
      const destiny = document.getElementById(event.target.id);
      let tempDestiny = destiny.src;

      destiny.src = editRoute.imagesPreview[originIndex].photo;
      editRoute.imagesPreview[originIndex].photo = tempDestiny;
    }
  } else {
    let imgOriginTemp = origin.src;

    origin.src = destiny.src;
    destiny.src = imgOriginTemp;
  }
}

async function readFileRoutesByXlsx(event) {
  const target = event.target;

  if (!target.files.length) {
    return;
  }

  const file = target.files[0];

  // Validate file format
  const allowedFormats = ['xlsx'];
  const fileExtension = file.name.split('.').pop().toLowerCase();
  if (!allowedFormats.includes(fileExtension)) {
    toast.error('Formato inválido. Somente arquivo .XLSX é permitido.');
    return;
  }

  loading.state = true;
  loading.text = 'Aguarde...';

  const toastXlsx = toast('Lendo arquivo, o navegador pode ficar lento...', {
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    closeButton: false,
    progress: undefined,
  });

  const xlsxRows = await readXlsxFile(file, {
    schema: routesByXlsxSchema,
    transformData(data) {
      return data.filter(
        (row) => row.filter((column) => column !== null).length > 0,
      );
    },
  });

  if (xlsxRows.errors.length) {
    routesByXlsxErros.value = xlsxRows.errors;
    loading.state = false;
    toast.dismiss(toastXlsx);
    modals.modalSchemaXlsxErrors.show();
    return;
  }

  toast.update(toastXlsx, { content: 'Formatando dados...' });

  routesByXlsx.value = await formatRoutesByXlsxArray(xlsxRows.rows);

  toast.update(toastXlsx, { content: 'Enviando dados...' });

  await createRoutesByXlsx();

  toast.dismiss(toastXlsx);
  loading.state = false;
}

function groupBy(array, key) {
  return array.reduce((result, current) => {
    // Get the value of the key for the current object
    const keyValue = current[key];

    // If the key does not exist in the result object yet, create a new array for it
    if (!result[keyValue]) {
      result[keyValue] = [];
    }

    // Add the current object to the array for the key
    result[keyValue].push(current);

    return result;
  }, {});
}

async function formatRoutesByXlsxArray(data) {
  const groupData = groupBy(data, 'id');

  const promises = Object.entries(groupData).map(async ([meliId, items]) => {
    const polyline = encode(
      items.map(({ latitude, longitude }) => [latitude, longitude]),
    );

    return { meliId, polyline };
  });

  const result = await Promise.all(promises);

  return result;
}

async function createRoutesByXlsx() {
  const response = await routesService.createRouteByXlsx(routesByXlsx.value);
  const inpuFile = document.getElementById('createByXlsx');

  if (response && response.status === 201) {
    if (response.data.errors.length) {
      toast.error(
        `Existem ${response.data.errors.length} erros na importação.`,
      );
      modalCoordinatorsErros.value = response.data.errors;

      modals.modalCoordinatorsErros.show();
    }

    toast.success(
      `Atualizadas coordenadas de ${
        routesByXlsx.value.length - response.data.errors.length
      } rotas com sucesso. `,
    );

    if (inpuFile) {
      inpuFile.value = '';
    }
  }
  routesByXlsx.value = [];
  routesByXlsxErros.value = [];
}

function setUnlinkDriverFromRoute(route) {
  unlinkDriver.value.accountName = route.account.accountName;
  unlinkDriver.value.routeId = route.id;

  modals.modalRoute.hide();
  modals.modalUnlinkDriver.show();
}

async function handleUnlinkDriver() {
  isLoading.value = true;

  const response = await routesService.unlinkDriver({
    routeId: unlinkDriver.value.routeId,
  });

  if (response && response.status === 204) {
    toast.success('Motorista desvinculado com sucesso.');
    modals.modalUnlinkDriver.hide();

    routes.value.findIndex((route) => {
      if (route.id === unlinkDriver.value.routeId) {
        route.primaryDriverAccountId = null;
      }
    });

    await viewRoute({
      routeId: unlinkDriver.value.routeId,
      index: routeIndex.value,
    });

    modals.modalRoute.show();
    unlinkDriver.value = {
      accountName: '',
      routeId: null,
    };
  }

  isLoading.value = false;
}

const currentRule = computed(() => {
  if (editRoute.account.driver.serviceCenter.kilometerValueRule === 'APP') {
    return 'Rastreamento herdado do mapa.';
  }
  if (editRoute.account.driver.serviceCenter.kilometerValueRule === 'MELI') {
    return 'Crawler do Meli.';
  }

  return 'Odômetro inicial e final.';
});

const currentUsedRule = computed(() => {
  if (editRoute.kilometerValueRule === 'APP') {
    if (editRoute.geolocationSource === 'app') {
      return 'Rastreamento herdado do mapa - Atual: APP';
    }
    if (editRoute.geolocationSource === 'external') {
      return 'Rastreamento herdado do mapa - Atual: MELI';
    }

    if (editRoute.geolocationSource === 'manual') {
      return 'Rastreamento herdado do mapa - Atual: Manual';
    }

    return 'Rastreamento herdado do mapa.';
  }
  if (editRoute.kilometerValueRule === 'MELI') {
    return 'Crawler do Meli.';
  }

  return 'Odômetro inicial e final.';
});
</script>

<template>
  <section class="bg-white rounded p-2 mx-3">
    <nav>
      <div class="nav nav-fill nav-tabs" id="nav-tab" role="tablist">
        <button
          class="nav-link active border-0"
          id="nav-routes-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-routes"
          type="button"
          role="tab"
          aria-controls="nav-routes"
          aria-selected="true"
        >
          Rotas
        </button>
        <button
          class="nav-link border-0"
          id="nav-pending-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-pending"
          type="button"
          role="tab"
          aria-controls="nav-pending"
          aria-selected="false"
          @click="showPendents"
        >
          Pendentes
        </button>
        <!-- <button
          class="nav-link border-0"
          id="nav-real-time-map-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-real-time-map"
          type="button"
          role="tab"
          aria-controls="nav-real-time-map"
          aria-selected="false"
          ref="tabMapRealTime"
        >
          Mapa
        </button> -->
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane fade show active py-2"
        id="nav-routes"
        role="tabpanel"
        aria-labelledby="nav-routes-tab"
        tabindex="0"
      >
        <div class="row row-cols-1 row-cols-sm-4 g-1">
          <div class="col">
            <div class="card">
              <div class="card-body p-2">
                <div
                  class="d-flex flex-nowrap justify-content-between align-items-center gap-1"
                >
                  <div class="d-flex flex-nowrap flex-column gap-1">
                    <span class="text-nowrap" style="font-size: 0.825rem">
                      <span class="fw-semibold">
                        {{ routeTotals.totalRoutes }}
                      </span>
                      total
                    </span>
                    <span class="text-nowrap" style="font-size: 0.825rem">
                      <span class="fw-semibold">
                        {{ routeTotals.totalRoutesTravel }}
                      </span>
                      em andamento
                    </span>
                    <span class="text-nowrap" style="font-size: 0.825rem">
                      <span class="fw-semibold">
                        {{ routeTotals.totalRoutesEnded }}
                      </span>
                      finalizadas
                    </span>
                  </div>
                  <VeProgress
                    :progress="
                      calculatePercentDeliveries(
                        routeTotals.totalRoutes,
                        routeTotals.totalRoutesEnded,
                      )
                    "
                    color="#00a650"
                    :size="46"
                    :thickness="3"
                    empty-thickness="10%"
                    animation="default 0 0"
                    :legend-formatter="({ currentValue }) => `${currentValue}%`"
                    legendClass="legend"
                    font-color="black"
                    dot="10% black"
                    font-size=".825rem"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card">
              <div class="card-body p-2">
                <div
                  class="d-flex flex-nowrap justify-content-between align-items-center gap-1"
                >
                  <div class="d-flex flex-nowrap flex-column gap-1">
                    <span class="text-nowrap" style="font-size: 0.825rem">
                      -
                    </span>
                    <span class="text-nowrap" style="font-size: 0.825rem">
                      <span class="fw-semibold">
                        {{ routeTotals.totalPackages }}
                      </span>
                      pacotes
                    </span>
                    <span class="text-nowrap" style="font-size: 0.825rem">
                      -
                    </span>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-center icon-shape bg-gradient bg-success shadow-sm text-center rounded-circle"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-box-check"
                      class="text-white"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <div class="card-body p-2">
                <div
                  class="d-flex flex-nowrap justify-content-between align-items-center gap-1"
                >
                  <div class="d-flex flex-nowrap flex-column gap-1">
                    <span class="text-nowrap" style="font-size: 0.825rem">
                      <span class="fw-semibold">
                        {{ routeTotals.totalReal }}
                      </span>
                      reais
                    </span>
                    <span class="text-nowrap" style="font-size: 0.825rem">
                      <span class="fw-semibold">
                        {{ routeTotals.totalDivided }}
                      </span>
                      divididas
                    </span>
                    <span class="text-nowrap" style="font-size: 0.825rem">
                      -
                    </span>
                  </div>
                  <VeProgress
                    :progress="
                      calculatePercentDeliveries(
                        routeTotals.totalReal,
                        routeTotals.totalReal + routeTotals.totalDivided,
                      )
                    "
                    color="#00a650"
                    :size="46"
                    :thickness="3"
                    empty-thickness="10%"
                    animation="default 0 0"
                    :legend-formatter="({ currentValue }) => `${currentValue}%`"
                    legendClass="legend"
                    font-color="black"
                    dot="10% black"
                    font-size=".825rem"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <div class="card-body p-2">
                <div
                  class="d-flex flex-nowrap justify-content-between align-items-center gap-1"
                >
                  <div class="d-flex flex-nowrap flex-column gap-1">
                    <span class="text-nowrap" style="font-size: 0.825rem">
                      <span class="fw-semibold">
                        {{ routeTotals.totalGeolocation }}
                      </span>
                      geolocalização
                    </span>
                    <span class="text-nowrap" style="font-size: 0.825rem">
                      <span class="fw-semibold">
                        {{ routeTotals.totalNoGeolocation }}
                      </span>
                      sem geolocalização
                    </span>
                    <span class="text-nowrap" style="font-size: 0.825rem">
                      <span class="fw-semibold">
                        {{ routeTotals.totalNotNull }}
                      </span>
                      não se aplica
                    </span>
                  </div>
                  <VeProgress
                    :progress="
                      calculatePercentDeliveries(
                        routeTotals.totalGeolocation +
                          routeTotals.totalNoGeolocation,
                        routeTotals.totalNotNull,
                      )
                    "
                    color="#00a650"
                    :size="46"
                    :thickness="3"
                    empty-thickness="10%"
                    animation="default 0 0"
                    :legend-formatter="({ currentValue }) => `${currentValue}%`"
                    legendClass="legend"
                    font-color="black"
                    dot="10% black"
                    font-size=".825rem"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-grid d-md-flex gap-1 my-1">
          <button
            type="button"
            class="btn btn-sm btn-orange"
            data-bs-toggle="modal"
            data-bs-target="#modal-new-route"
          >
            <font-awesome-icon icon="fa-regular fa-plus" />
            Iniciar rota
          </button>
          <button
            type="button"
            class="btn btn-sm btn-orange"
            data-bs-toggle="modal"
            data-bs-target="#modal-export"
          >
            <font-awesome-icon icon="fa-regular fa-download" />
            Exportar
          </button>

          <label
            v-if="permissions.manageRoutes"
            as="label"
            for="createByXlsx"
            class="btn btn-sm btn-orange"
          >
            <font-awesome-icon icon="fa-regular fa-file-excel" />
            Importar coordenadas
          </label>

          <input
            id="createByXlsx"
            type="file"
            class="d-none"
            @change="readFileRoutesByXlsx"
          />

          <input
            v-model="inputSearch"
            type="text"
            class="form-control form-control-sm ms-md-auto input-plate-search"
            placeholder="Buscar por ID"
          />
          <button
            data-bs-toggle="modal"
            data-bs-target="#modal-filter"
            class="btn btn-sm btn-orange"
          >
            <font-awesome-icon icon="fa-regular fa-filter" />
            Filtrar
          </button>
        </div>

        <div class="contianer-fluid">
          <VueElementLoading
            :active="loading.state"
            spinner="spinner"
            color="#FF6700"
            size="60"
            :text="loading.text"
          />
          <div
            v-if="filteredItems.length"
            v-bind="containerProps"
            style="height: 65vh; overflow-x: auto; white-space: nowrap"
          >
            <div v-bind="wrapperProps">
              <table class="table w-100">
                <thead class="position-sticky top-0" style="z-index: 1000">
                  <tr>
                    <th>Status</th>
                    <th>ID</th>
                    <!-- HASMELI  -->
                    <template v-if="hasMeli">
                      <th>Comercial(MELI)</th>
                      <th>Residencial(MELI)</th>
                      <th>Paradas(MELI)</th>
                      <th>Local</th>
                      <th class="text-nowrap">Tempo p/ rota</th>
                      <th class="text-nowrap">Tempo entrega</th>
                      <th class="text-nowrap">Tempo rota</th>
                      <th class="text-nowrap">Produção p/ hora</th>
                      <th class="text-nowrap">Hora início</th>
                      <th class="text-nowrap">Previsão de término</th>
                    </template>
                    <th v-else class="w-75 mx-auto" colspan="10">MELI</th>
                    <!-- END HASMELI -->
                    <th>Motorista</th>
                    <th>Veículo</th>
                    <th class="text-nowrap">Placa</th>
                    <th>Base</th>
                    <th>Data</th>
                    <th>Onda</th>
                    <th class="fixed-width">Tempo</th>
                    <th>Pacotes</th>
                    <th class="text-nowrap">Km inicial</th>
                    <th class="text-nowrap">Km final</th>
                    <th>Pedágios</th>
                    <th>Ações</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    class="align-middle"
                    v-for="{ data, index } in list"
                    :key="data.id"
                    style="height: 78px !important"
                  >
                    <td class="text-wrap">
                      <span v-if="data.statusId == 1" class="badge bg-success">
                        Em andamento
                      </span>
                      <span
                        v-else-if="data.statusId == 2"
                        class="badge bg-danger"
                      >
                        Parada
                      </span>
                      <span
                        v-else-if="data.statusId === 4"
                        class="badge bg-secondary"
                      >
                        Finalizada
                      </span>
                      <span
                        v-else-if="data.statusId == 5"
                        class="badge bg-secondary"
                      >
                        Finalizada automaticamente
                      </span>
                      <span
                        v-else-if="data.statusId == 6"
                        class="badge bg-success"
                      >
                        Iniciada Manualmente
                      </span>
                      <span
                        v-else-if="data.statusId == 7"
                        class="badge bg-secondary"
                      >
                        Finalizada Manualmente
                      </span>
                      <span
                        v-else-if="data.statusId == 8"
                        class="badge bg-secondary"
                      >
                        Arquivada Manualmente
                      </span>
                      <span
                        v-show="data.statusPackages"
                        class="badge bg-warning"
                        title="Aviso rota com mais de 200 pacotes"
                      >
                        +200 pacotes
                      </span>
                      <span
                        v-show="data.statusInitialKm"
                        class="badge bg-warning"
                        title="Aviso rota sem Km inicial"
                      >
                        Km inicial
                      </span>
                      <span
                        v-show="data.statusFinalKm"
                        class="badge bg-warning"
                        title="Aviso rota sem Km final"
                      >
                        Km final
                      </span>
                      <span
                        v-show="data.statusTotalKm"
                        class="badge bg-warning"
                        title="Aviso rota com Km total maior de 300km"
                      >
                        Km total
                      </span>
                    </td>
                    <td>
                      <div class="d-flex align-items-center gap-1">
                        <a
                          :href="`https://envios.adminml.com/logistics/monitoring-distribution/detail/${data.routeId}`"
                          target="_blank"
                          class="text-black"
                        >
                          {{ data.routeId }}
                        </a>
                        <font-awesome-icon
                          icon="fa-regular fa-up-right-from-square"
                          size="sm"
                        />
                      </div>
                    </td>

                    <template v-if="hasMeli && data.meliData">
                      <td>
                        <div
                          class="d-flex flex-nowrap align-items-center gap-1"
                        >
                          <VeProgress
                            :progress="
                              calculatePercentDeliveries(
                                data.meliData.totalCommercial,
                                data.meliData.commercialDelivered +
                                  data.meliData.commercialFailures,
                              )
                            "
                            color="#00a650"
                            :size="46"
                            :thickness="3"
                            empty-thickness="10%"
                            animation="default 0 0"
                            :legend-formatter="
                              ({ currentValue }) => `${currentValue}%`
                            "
                            legendClass="legend"
                            font-color="black"
                            dot="10% black"
                            font-size=".825rem"
                          />
                          <div class="d-flex flex-nowrap flex-column gap-1">
                            <span
                              class="text-nowrap"
                              style="font-size: 0.825rem"
                            >
                              {{ data.meliData.commercialDelivered }} entregues
                            </span>
                            <span
                              class="text-nowrap"
                              style="font-size: 0.825rem"
                            >
                              {{ data.meliData.commercialFailures }} falhas
                            </span>
                            <span
                              class="text-nowrap"
                              style="font-size: 0.825rem"
                            >
                              Faltam
                              {{
                                data.meliData.totalCommercial -
                                data.meliData.commercialDelivered -
                                data.meliData.commercialFailures
                              }}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          class="d-flex flex-nowrap align-items-center gap-1"
                        >
                          <VeProgress
                            :progress="
                              calculatePercentDeliveries(
                                data.meliData.totalResidential,
                                data.meliData.residentialDelivered +
                                  data.meliData.residentialFailures,
                              )
                            "
                            color="#00a650"
                            :size="46"
                            :thickness="3"
                            empty-thickness="10%"
                            animation="default 0 0"
                            :legend-formatter="
                              ({ currentValue }) => `${currentValue}%`
                            "
                            legendClass="legend"
                            font-color="black"
                            dot="10% black"
                            font-size=".825rem"
                          />

                          <div class="d-flex flex-nowrap flex-column gap-1">
                            <span
                              class="text-nowrap"
                              style="font-size: 0.825rem"
                            >
                              {{ data.meliData.residentialDelivered }} entregues
                            </span>
                            <span
                              class="text-nowrap"
                              style="font-size: 0.825rem"
                            >
                              {{ data.meliData.residentialFailures }} falhas
                            </span>
                            <span
                              class="text-nowrap"
                              style="font-size: 0.825rem"
                            >
                              Faltam
                              {{
                                data.meliData.totalResidential -
                                data.meliData.residentialDelivered -
                                data.meliData.residentialFailures
                              }}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        {{ data.meliData.totalStops }}
                      </td>
                      <td class="fixed-width">
                        {{ data.meliData.local }}
                      </td>
                      <td>{{ data.meliData.stemOut }}</td>
                      <td>{{ data.meliData.ozh }}</td>
                      <td>{{ data.meliData.orh }}</td>
                      <td>{{ calculateProductionPerHour(data) }}</td>

                      <td>{{ data.initialHour }}</td>
                      <td>{{ calculatePrevisionToEnd(data) }}</td>
                    </template>
                    <td v-else class="w-75 mx-auto" colspan="10"><hr /></td>

                    <td>
                      <div class="d-flex flex-column">
                        {{ data.accountName }}
                        <span
                          v-if="data.primaryDriverAccountId !== null"
                          class="badge bg-secondary"
                        >
                          Secundário
                        </span>
                      </div>
                    </td>

                    <td>{{ data.type }}</td>
                    <td class="text-nowrap">{{ data.plate }}</td>
                    <td>
                      {{
                        data.serviceCenter
                          ? data.serviceCenter.split('-')[0]
                          : ''
                      }}
                    </td>
                    <td>
                      {{
                        data.initialDate
                          ? filters.dateDisplay(data.initialDate)
                          : '-'
                      }}
                    </td>
                    <td>{{ data.wave ? data.wave : '-' }}</td>
                    <td :id="`timer_${index}`">
                      {{ data.time ? data.time : '-' }}
                    </td>
                    <td>{{ data.numberPackages }}</td>
                    <td>
                      {{
                        data.initialKilometer
                          ? Number(data.initialKilometer).toLocaleString()
                          : '-'
                      }}
                    </td>
                    <td>
                      {{
                        data.finalKilometer
                          ? Number(data.finalKilometer).toLocaleString()
                          : '-'
                      }}
                    </td>
                    <td>
                      {{ data.tolls ? filters.floatToMoney(data.tolls) : '-' }}
                    </td>
                    <td class="text-nowrap">
                      <div class="btn-group btn-group-sm">
                        <button
                          class="btn btn-orange btn-sm"
                          type="button"
                          @click="() => viewRoute({ routeId: data.id, index })"
                        >
                          Ver detalhes
                        </button>
                        <button
                          type="button"
                          class="btn btn-sm btn-orange dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          data-bs-reference="toggle"
                          aria-expanded="false"
                        >
                          <span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-right">
                          <li>
                            <a
                              role="button"
                              @click="
                                () =>
                                  viewRouteStatus({
                                    routeId: data.id,
                                    statusId: data.statusId,
                                  })
                              "
                              class="dropdown-item"
                              href="#"
                            >
                              Alterar status
                            </a>
                          </li>
                          <li>
                            <a
                              role="button"
                              @click="
                                openModalConfirmDeleteRoute({ route: data })
                              "
                              class="dropdown-item"
                              href="#"
                            >
                              Excluir
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <NoDataComponent
            text="Sem rotas encontradas para o filtro atual."
            v-else
          />
        </div>
      </div>
      <div
        class="tab-pane fade py-2"
        id="nav-pending"
        role="tabpanel"
        aria-labelledby="nav-pending-tab"
        tabindex="0"
      >
        <PendentsTable :meli-routes="meliRoutes" />
      </div>
      <div
        class="tab-pane fade py-2"
        id="nav-real-time-map"
        role="tabpanel"
        aria-labelledby="nav-real-time-map-tab"
        tabindex="0"
      >
        <div id="realTimeMap" style="height: 600px" />
      </div>
    </div>
  </section>
  <div class="modals">
    <div
      class="modal fade"
      id="modal-unlink-driver"
      tabindex="-1"
      aria-labelledby="ModalUnlinkDriver"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-orange text-white">
            <h1 class="modal-title fs-5" id="ModalUnlinkDriver">
              <font-awesome-icon :icon="['far', 'link-slash']" />
              Desvincular motorista
            </h1>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-target="#modal-route"
              data-bs-toggle="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container">
              Atenção! Tem certeza que deseja desvincular a rota? Seus valores
              ficarão no nome de
              <b>{{ unlinkDriver.accountName }}</b>
              . Só é possível desvincular se a rota não está em um fechamento.
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-target="#modal-route"
              data-bs-toggle="modal"
            >
              Voltar
            </button>
            <button
              @click="handleUnlinkDriver"
              type="button"
              class="btn btn-sm btn-success"
              :disabled="isLoading"
            >
              <div
                v-if="isLoading"
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              {{ isLoading ? 'Desvinculando...' : 'Desvincular' }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modalCoordinatorsErros"
      tabindex="-1"
      aria-labelledby="modalCoordinatorsErrosLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header bg-danger text-white">
            <h1 class="modal-title fs-5" id="modalCoordinatorsErrosLabel">
              Detalhes dos erros
            </h1>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="d-flex my-1">
              <input
                type="text"
                v-model="modalCoordinatorsErrosValue"
                class="form-control form-control-sm"
                placeholder="Pesquisar por ID"
              />
            </div>
            <EasyDataTable
              :headers="coordenadasHeaders"
              :items="modalCoordinatorsErros"
              theme-color="#f35e1d"
              table-class-name="customize-table"
              empty-message="Sem data."
              search-field="meliId"
              :search-value="modalCoordinatorsErrosValue"
              :table-min-height="400"
              :rows-per-page="10"
              rows-of-page-separator-message="de"
              rows-per-page-message="Linhas por página"
            ></EasyDataTable>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modalSchemaXlsxErrors"
      tabindex="-1"
      aria-labelledby="ModalSchemaXlsxErrorsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-danger text-white">
            <h1 class="modal-title fs-5" id="ModalSchemaXlsxErrorsLabel">
              Error no arquivo xlsx
            </h1>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <EasyDataTable
              :headers="routesByXlsxErrosHeaders"
              :items="routesByXlsxErros"
              theme-color="#f35e1d"
              table-class-name="customize-table"
              empty-message="Sem data."
              :table-min-height="400"
              :rows-per-page="10"
              rows-of-page-separator-message="de"
              rows-per-page-message="Linhas por página"
            >
              <template #item-error="{ error }">
                {{ error === 'required' ? 'Campo obrigatório' : error }}
              </template>

              <template #item-value="{ value }">
                {{ value === null ? 'Campo vazio' : value }}
              </template>
            </EasyDataTable>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-filter"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title">
              <font-awesome-icon
                icon="fa-regular fa-magnifying-glass"
                class="me-2"
              />
              Filtrar
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <form @submit.prevent="getRoutes">
            <div class="modal-body">
              <div class="container">
                <div class="form-group">
                  <label for="filterName" class="form-label">
                    Informe o nome do motorista:
                  </label>
                  <input
                    v-model="filter.name"
                    type="text"
                    class="form-control form-control-sm"
                    id="filterName"
                  />
                </div>

                <div class="form-group">
                  <label for="filterId" class="form-label">
                    Informe o ID da rota:
                  </label>
                  <input
                    v-model="filter.routeId"
                    type="text"
                    class="form-control form-control-sm"
                    id="filterId"
                  />
                </div>

                <div class="form-group">
                  <label for="routeRouteDate" class="form-label">
                    Data da rota:
                  </label>
                  <VDatePicker
                    :masks="masks"
                    v-model.string="filter.date"
                    mode="date"
                  >
                    <template #default="{ inputValue, inputEvents }">
                      <input
                        id="routeRouteDate"
                        class="form-control form-control-sm"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </VDatePicker>
                </div>

                <div class="form-group">
                  <label for="filterServiceCenter" class="form-label">
                    Base:
                  </label>
                  <select
                    class="form-select form-select-sm"
                    id="filterServiceCenter"
                    v-model="filter.serviceCenter"
                  >
                    <option value="">Todos</option>
                    <option
                      v-for="serviceCenter in serviceCenters"
                      :value="serviceCenter.id"
                      :key="serviceCenter.id"
                    >
                      {{ serviceCenter.serviceCenter }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                Filtrar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-export"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title">
              <font-awesome-icon
                icon="fa-regular fa-magnifying-glass"
                class="me-2"
              />
              Exportar
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <form @submit.prevent="exportToCsv">
            <div class="modal-body">
              <div class="container">
                <div class="form-group">
                  <h4 class="text-orange fw-bold">Período entre as datas:</h4>
                </div>

                <div class="form-group">
                  <label for="exporCsvInitDate" class="form-label">
                    Data inicial:
                  </label>
                  <VDatePicker
                    :masks="masks"
                    v-model.string="exportCsv.initialDate"
                    mode="date"
                  >
                    <template #default="{ inputValue, inputEvents }">
                      <input
                        id="exporCsvInitDate"
                        class="form-control form-control-sm"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </VDatePicker>
                </div>

                <div class="form-group">
                  <label for="exporCsvEndDate" class="form-label">
                    Data final:
                  </label>

                  <VDatePicker
                    :masks="masks"
                    v-model.string="exportCsv.finalDate"
                    mode="date"
                  >
                    <template #default="{ inputValue, inputEvents }">
                      <input
                        id="exporCsvEndDate"
                        class="form-control form-control-sm"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </VDatePicker>
                </div>

                <div class="form-group">
                  <label for="exportServiceCenter" class="form-label">
                    Selecione o service center:
                  </label>
                  <select
                    class="form-select form-select-sm"
                    id="exportServiceCenter"
                    v-model="exportCsv.serviceCenter"
                  >
                    <option value="Todos">Todos</option>
                    <option
                      v-for="serviceCenter in serviceCenters"
                      :value="serviceCenter.id"
                      :key="serviceCenter.id"
                    >
                      {{ serviceCenter.serviceCenter }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                data-bs-dismiss="modal"
                v-if="!isLoading"
              >
                Cancelar
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                  v-if="isLoading"
                ></span>
                <span v-if="isLoading">Aguarde...</span>
                <span v-if="!isLoading">Exportar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      ref="modalNewRouteRef"
      id="modal-new-route"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-sm-down"
        role="document"
      >
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title">
              <font-awesome-icon icon="fa-regular fa-play" class="me-2" />
              Iniciar Rota
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <form @submit.prevent="createRoute">
            <div class="modal-body">
              <div class="form-group">
                <h4 class="text-orange fw-semibold">Dados iniciais:</h4>
              </div>
              <hr class="text-muted border border-1" />
              <div class="row row-cols-1 row-cols-sm-2">
                <div class="form-group">
                  <label for="newRouteServiceCenterId" class="form-label">
                    Base do motorista:
                  </label>
                  <Multiselect
                    id="newRouteServiceCenterId"
                    v-model="filterDriverServiceCenterId"
                    :options="serviceCenters"
                    :searchable="true"
                    obejct
                    valueProp="id"
                    trackBy="name"
                    label="serviceCenter"
                    noOptionsText="Nenhum service center encontrado"
                    noResultsText="Nenhum service center encontrado"
                    @select="
                      (option) =>
                        getDrivers({
                          statusDriver: [2],
                          serviceCenters: [option],
                        })
                    "
                    class="mb-2 multi-select-orange"
                  />
                </div>

                <div class="form-group">
                  <label for="newRouteDriverAccountId" class="form-label">
                    Motorista:
                  </label>
                  <Multiselect
                    id="newRouteDriverAccountId"
                    v-model="newRoute.driverAccountId"
                    :options="filterDrivers"
                    :searchable="true"
                    obejct
                    valueProp="accountId"
                    trackBy="accountName"
                    label="accountName"
                    noOptionsText="Nenhum motorista encontrado"
                    noResultsText="Nenhum motorista encontrado"
                    class="mb-2 multi-select-orange"
                    :disabled="!filterDrivers.length"
                  />
                </div>
              </div>
              <div class="row row-cols-1 row-cols-md-3">
                <div class="form-group">
                  <label for="newRouterouteId" class="form-label">
                    ID da Rota :
                  </label>
                  <input
                    v-if="meliRules.flag === 'Meli'"
                    v-model="newRoute.routeId"
                    v-mask="'##########'"
                    inputmode="numeric"
                    type="text"
                    class="form-control form-control-sm"
                    id="newRouterouteId"
                  />
                  <input
                    v-else
                    v-model="newRoute.routeId"
                    type="text"
                    class="form-control form-control-sm"
                    id="newRouterouteId"
                  />
                </div>

                <div class="form-group">
                  <label for="newRouteNumberBoxes" class="form-label">
                    Número de pacotes:
                  </label>
                  <input
                    v-model="newRoute.numberPackages"
                    type="number"
                    :max="999"
                    :min="1"
                    class="form-control form-control-sm"
                    id="newRouteNumberBoxes"
                  />
                </div>

                <div class="form-group">
                  <label for="newRouteStops" class="form-label">
                    Número de paradas:
                  </label>
                  <input
                    v-model="newRoute.totalStops"
                    type="number"
                    :max="999"
                    :min="1"
                    class="form-control form-control-sm"
                    id="newRouteStops"
                  />
                  <div class="form-text">
                    Deixar vazio para que o crawler calcule automaticamente.
                  </div>
                </div>
              </div>
              <div class="row row-cols-1 row-cols-sm-2">
                <div class="form-group">
                  <label for="newRouteInitialDate" class="form-label">
                    Qual é a data inicial da rota?
                  </label>
                  <VDatePicker
                    :masks="masks"
                    v-model.string="newRoute.initialDate"
                    mode="date"
                  >
                    <template #default="{ inputValue, inputEvents }">
                      <input
                        id="newRouteInitialDate"
                        class="form-control form-control-sm"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </VDatePicker>
                </div>
                <div class="form-group">
                  <label for="newRouteWave" class="form-label">
                    Qual é a onda?
                  </label>
                  <select
                    class="form-select form-select-sm"
                    id="newRouteWave"
                    v-model="newRoute.wave"
                  >
                    <option value="1">1º Onda</option>
                    <option value="2">2º Onda</option>
                    <option value="3">3ª Onda</option>
                  </select>
                </div>
              </div>
              <div
                class="row row-cols-1 row-cols-md-2"
                :class="{
                  'row-cols-md-3': meliRules.enableKilometer,
                }"
              >
                <div class="col form-group">
                  <label for="newRouteInitialHour" class="form-label">
                    Hora inicial da rota:
                  </label>
                  <input
                    id="newRouteInitialHour"
                    class="form-control form-control-sm"
                    type="time"
                    v-model="newRoute.initialHour"
                  />
                </div>
                <div class="col form-group">
                  <label for="newRouteCarPlate" class="form-label">
                    Placa:
                  </label>
                  <input
                    v-model="newRoute.carPlate"
                    type="text"
                    v-mask="['AAA#X##', 'AAA-####']"
                    class="form-control form-control-sm text-uppercase"
                    id="newRouteCarPlate"
                  />
                </div>
                <div class="col form-group" v-if="meliRules.enableKilometer">
                  <label for="newRouteMileage" class="form-label text-nowrap">
                    KM inicial do veículo:
                  </label>
                  <input
                    v-model="newRoute.initialKilometer"
                    v-mask="'###############'"
                    :min="1"
                    inputmode="numeric"
                    type="text"
                    class="form-control form-control-sm"
                    id="newRouteMileage"
                  />
                </div>
              </div>

              <div class="row my-2">
                <h5 class="my-3 text-orange fw-semibold">Imagens</h5>
              </div>
              <hr class="text-muted border border-1" />
              <div class="row row-cols-1">
                <div class="form-group">
                  <label for="newRouteMeliPhoto" class="form-label">
                    Insira uma foto do ID da rota no app do Mercado Livre:
                  </label>
                  <div
                    class="d-flex flex-column gap-1 align-items-center justify-content-center"
                  >
                    <input
                      type="file"
                      @change="editMeliPhoto($event)"
                      class="d-none"
                      id="newRouteMeliPhoto"
                    />
                    <label
                      v-if="newRoute.meliPhoto == ''"
                      htmlFor="newRouteMeliPhoto"
                      className="new-route-images"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                    </label>
                    <label v-else for="newRouteMeliPhoto" class="p-0 mt-2">
                      <img
                        :src="newRoute.meliPhoto"
                        class="img-thumbnail"
                        style="height: 150px; width: 150px"
                      />
                    </label>
                    <button
                      type="button"
                      class="btn btn-sm btn-orange"
                      v-if="newRoute.meliPhoto"
                      @click="openLightboxImage(newRoute.meliPhoto)"
                    >
                      <font-awesome-icon icon="fa-regular fa-eye" size="sm" />
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="row row-cols-1"
                v-if="meliRules.enableKilometerPhotos"
              >
                <div class="form-group">
                  <label class="form-label">
                    Tire uma foto do odômetro do veículo:
                  </label>
                  <div
                    class="d-flex flex-column gap-1 align-items-center justify-content-center"
                  >
                    <input
                      type="file"
                      @change="editInitialKilometerPhoto($event)"
                      class="d-none"
                      id="newRouteOdometerPhoto"
                    />
                    <label
                      v-if="newRoute.initialKilometerPhoto == ''"
                      class="new-route-images"
                      for="newRouteOdometerPhoto"
                    >
                      <font-awesome-icon icon="fa-regular fa-gauge-high" />
                    </label>

                    <label v-else for="newRouteOdometerPhoto" class="p-0 mt-2">
                      <img
                        :src="newRoute.initialKilometerPhoto"
                        class="img-thumbnail"
                        style="height: 150px; width: 150px"
                      />
                    </label>
                    <button
                      type="button"
                      class="btn btn-sm btn-orange"
                      v-if="newRoute.initialKilometerPhoto"
                      @click="openLightboxImage(newRoute.initialKilometerPhoto)"
                    >
                      <font-awesome-icon icon="fa-regular fa-eye" size="sm" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="row row-cols-1">
                <div class="form-group">
                  <label class="form-label">Tire uma selfie agora:</label>
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                  >
                    <input
                      type="file"
                      @change="editSelfiePhoto($event)"
                      class="d-none"
                      id="newRouteSelfiePhoto"
                    />
                    <label
                      v-if="newRoute.initialSelfiePhoto == ''"
                      for="newRouteSelfiePhoto"
                      class="new-route-images"
                    >
                      <font-awesome-icon icon="fa-regular fa-user" />
                    </label>
                    <label v-else for="newRouteSelfiePhoto" class="p-0 mt-2">
                      <figure class="m-0">
                        <img
                          :src="newRoute.initialSelfiePhoto"
                          class="img-thumbnail"
                          style="height: 150px; width: 150px"
                        />
                      </figure>
                    </label>
                    <small class="text-muted">
                      Lembre-se de retirar a máscara para a foto.
                    </small>
                    <button
                      type="button"
                      class="btn btn-sm btn-orange"
                      v-if="newRoute.initialSelfiePhoto"
                      @click="openLightboxImage(newRoute.initialSelfiePhoto)"
                    >
                      <font-awesome-icon icon="fa-regular fa-eye" size="sm" />
                    </button>
                  </div>
                </div>
              </div>

              <div class="row row-cols-1">
                <div class="form-group">
                  <label class="form-label">
                    A rota já finalizou?
                    <Popper
                      content="Selecione arquivada caso a data da rota seja anterior ao último fechamento realizado"
                      arrow
                      placement="top"
                      hover
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-circle-info"
                        class="text-muted align-middle"
                      />
                    </Popper>
                  </label>
                  <div class="d-block">
                    <div
                      class="btn-group btn-group-sm"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        class="btn-check"
                        name="routesEndedYes"
                        id="routesEndedYes"
                        autocomplete="off"
                        v-model="newRoute.ended"
                        :value="1"
                        @change="
                          () => {
                            newRoute.statusId = 7;
                          }
                        "
                      />
                      <label
                        class="btn btn-outline-orange"
                        for="routesEndedYes"
                      >
                        Sim
                      </label>
                      <input
                        type="radio"
                        class="btn-check"
                        name="routesEndedNo"
                        id="routesEndedNo"
                        autocomplete="off"
                        v-model="newRoute.ended"
                        :value="0"
                        @change="
                          () => {
                            newRoute.statusId = 6;
                          }
                        "
                      />
                      <label class="btn btn-outline-orange" for="routesEndedNo">
                        Não
                      </label>
                      <input
                        type="radio"
                        class="btn-check"
                        name="routesEndedArchived"
                        id="routesEndedArchived"
                        autocomplete="off"
                        v-model="newRoute.ended"
                        :value="2"
                        @change="
                          () => {
                            newRoute.statusId = 8;
                          }
                        "
                      />
                      <label
                        class="btn btn-outline-orange"
                        for="routesEndedArchived"
                      >
                        Arquivada
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="newRoute.ended !== 0">
                <div class="form-group">
                  <h5 class="text-orange fw-semibold">Dados finais:</h5>
                </div>

                <hr class="text-muted border border-1" />
                <div
                  class="row row-cols-1 row-cols-sm-2"
                  :class="{
                    'row-cols-md-3': meliRules.enableKilometer,
                  }"
                >
                  <div class="form-group">
                    <label for="newRouteFinalDate" class="form-label">
                      Data de finalização:
                    </label>
                    <VDatePicker
                      :masks="masks"
                      v-model.string="newRoute.finalDate"
                      mode="date"
                    >
                      <template #default="{ inputValue, inputEvents }">
                        <input
                          id="newRouteFinalDate"
                          class="form-control form-control-sm"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </VDatePicker>
                  </div>

                  <div class="form-group">
                    <label for="newRouteInitialTime" class="form-label">
                      Horário de finalização:
                    </label>
                    <input
                      id="newRouteInitialTime"
                      class="form-control form-control-sm"
                      type="time"
                      v-model="newRoute.finalHour"
                    />
                  </div>

                  <div class="form-group" v-if="meliRules.enableKilometer">
                    <label for="newRouteFinalMileage" class="form-label">
                      KM final do veículo:
                    </label>

                    <input
                      v-model="newRoute.finalKilometer"
                      v-mask="'###############'"
                      inputmode="numeric"
                      type="text"
                      :min="1"
                      class="form-control form-control-sm"
                      id="newRouteFinalMileage"
                    />
                  </div>
                </div>

                <div class="row row-cols-1">
                  <div class="form-group">
                    <label for="" class="form-label">KM percorrido:</label>
                    <input
                      class="form-control form-control-sm"
                      :disabled="meliRules.kilometerValueRule !== 'APP'"
                      v-model="newRoute.kmTraveled"
                    />
                    <div class="form-text">
                      <span v-if="meliRules.kilometerValueRule === 'USER'">
                        Total percorrido
                        {{
                          newRoute.finalKilometer - newRoute.initialKilometer
                        }}
                        KM
                      </span>
                      <span v-else-if="meliRules.kilometerValueRule === 'MELI'">
                        O km percorrido será buscado do crawler do MELI pelo ID
                        da rota, confira os valores após iniciar a rota.
                      </span>
                      <span v-else>
                        <b>Regra:</b>
                        Rastreamento herdado do mapa.
                        <span v-if="meliRules.geolocationSource === 'external'">
                          Deixar vazio para que o crawler calcule
                          automaticamente.
                        </span>
                        <br />
                        Você também pode preencher manualmente ou importar os
                        dados de geolocalização abaixo.
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="row row-cols-1"
                  v-if="meliRules.enableKilometerPhotos"
                >
                  <div class="form-group">
                    <label class="form-label">
                      Tire uma foto do odômetro do veículo:
                    </label>
                    <div
                      class="d-flex flex-column align-items-center gap-1 justify-content-center"
                    >
                      <input
                        type="file"
                        @change="editFinalKilometerPhoto($event)"
                        class="d-none"
                        id="newRouteFinalKilometerPhoto"
                      />
                      <label
                        v-if="newRoute.finalKilometerPhoto == ''"
                        for="newRouteFinalKilometerPhoto"
                        class="new-route-images"
                      >
                        <font-awesome-icon icon="fa-regular fa-gauge-high" />
                      </label>
                      <label
                        v-else
                        for="newRouteFinalKilometerPhoto"
                        class="p-0 mt-2"
                      >
                        <img
                          :src="newRoute.finalKilometerPhoto"
                          class="img-thumbnail"
                          style="height: 150px; width: 150px"
                        />
                      </label>
                      <button
                        type="button"
                        class="btn btn-sm btn-orange"
                        v-if="newRoute.finalKilometerPhoto"
                        @click="openLightboxImage(newRoute.finalKilometerPhoto)"
                      >
                        <font-awesome-icon icon="fa-regular fa-eye" size="sm" />
                      </button>
                    </div>
                  </div>
                </div>

                <div v-if="permissions.manageRoutes" class="row row-cols-12">
                  <div class="col">
                    <div class="form-group">
                      <h5 class="my-3 text-orange fw-semibold">
                        Importar dados de geolocalização (opcional):
                      </h5>
                    </div>

                    <div class="row row-cols-12">
                      <div class="col">
                        <div
                          v-if="manualCoordinates.errors.length"
                          class="alert alert-warning alert-dismissible fade show"
                          role="alert"
                        >
                          Atenção! Existem
                          <strong>
                            {{
                              manualCoordinates.errors.length > 1
                                ? `${manualCoordinates.errors.length} erros`
                                : `${manualCoordinates.errors.length} erro`
                            }}
                          </strong>
                          no arquivo de geolocalização (XLSX). Click
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#modalManualCoordinates"
                            class="alert-link"
                          >
                            aqui
                          </a>
                          para ver os detalhes.
                          <button
                            type="button"
                            class="btn-close btn-close-white"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>

                        <div class="drop-zone">
                          <div
                            v-bind="getRootProps()"
                            class="d-flex align-items-center justify-content-center"
                          >
                            <input v-bind="getInputProps()" />
                            <p class="m-0" v-if="isDragActive">
                              Solte os arquivos ...
                            </p>
                            <p class="m-0" v-else>
                              Arraste e solte o arquivo aqui, ou clique e
                              selecione o arquivo.
                            </p>
                          </div>
                          <button
                            type="button"
                            class="btn btn-sm btn-orange place"
                            @click="open"
                          >
                            Abrir
                          </button>
                        </div>
                        <div
                          class="d-grid d-sm-flex align-items-center justify-content-between"
                        >
                          <div class="form-text fw-semibold">
                            <font-awesome-icon
                              icon="fa-regular fa-circle-check"
                              :class="{
                                'text-success': manualCoordinates.rows.length,
                                'text-secondary':
                                  manualCoordinates.rows.length === 0,
                              }"
                            />
                            {{ manualCoordinates.rows.length }} coordenadas
                            importadas.
                          </div>
                          <div class="form-text ms-auto">
                            Click
                            <a
                              href="/template_manual_coordenadas.xlsx"
                              target="_blank"
                              download
                            >
                              aqui
                            </a>
                            para baixar o modelo de arquivo de geolocalização.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <h5 class="my-3 text-orange fw-semibold">Pedágios:</h5>
                </div>
                <hr class="text-muted border border-1" />
                <div class="row row-cols-1">
                  <div class="form-group">
                    <label for="newRouteTolls" class="form-label">
                      Valor total em pedágios:
                    </label>
                    <CurrencyInput
                      id="newRouteTolls"
                      v-model="newRoute.tolls"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div class="row row-cols-1">
                  <div class="mb-2">
                    <label class="form-label">Comprovantes:</label>
                    <div class="images-container">
                      <div
                        v-for="(img, index) in newRoute.imagesPreview"
                        :key="img"
                      >
                        <img
                          role="button"
                          type="button"
                          :src="img.photo"
                          alt=""
                          @click="openLightboxImage(img.photo)"
                        />
                        <button
                          @click="
                            () =>
                              deletenewRouteAttachment({
                                index,
                              })
                          "
                          type="button"
                          class="remove-button"
                        >
                          <font-awesome-icon icon="fa-regular fa-xmark" />
                        </button>
                      </div>

                      <label htmlFor="newRouteImages[]" class="new-image">
                        <font-awesome-icon icon="fa-regular fa-plus" />
                      </label>
                    </div>
                    <input
                      class="d-none"
                      multiple
                      @change="handleSelectNewRouteImages"
                      type="file"
                      id="newRouteImages[]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                v-if="!isLoading"
                class="btn btn-sm btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                  v-if="isLoading"
                ></span>
                <span v-if="isLoading">Aguarde...</span>
                <span v-if="!isLoading">Iniciar rota</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modalManualCoordinates"
      tabindex="-1"
      aria-labelledby="modalManualCoordinates"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-orange">
            <h1 class="modal-title fs-5 text-white" id="modalManualCoordinates">
              <font-awesome-icon icon="fa-regular fa-circle-exclamation" />
              Erros no arquivo de geolocalização.
            </h1>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-toggle="modal"
              data-bs-target="#modal-new-route"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <table class="table">
              <thead class="table">
                <tr>
                  <th>Coluna</th>
                  <th>Linha</th>
                  <th>Erro</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(error, index) in manualCoordinates.errors"
                  :key="`${error.column}-${index}`"
                >
                  <td>{{ error.column }}</td>
                  <td>{{ error.row }}</td>
                  <td v-if="error.error === 'required'">Campo obrigatório</td>
                  <td v-else>
                    {{ error.error }}
                  </td>
                  <td v-if="error.value === null">Campo vazio</td>
                  <td v-else>{{ error.value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-toggle="modal"
              data-bs-target="#modal-new-route"
            >
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-route"
      tabindex="-1"
      ref="modalRouteRef"
      aria-labelledby="ModalRouteLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header bg-orange text-white">
            <h5 class="modal-title" id="ModalRouteLabel">
              <font-awesome-icon icon="fa-regular fa-route" />
              Detalhes da rota
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid h-100">
              <ul class="nav nav-fill nav-tabs" id="myTabRoute" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link text-black border-0 rounded-0 active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected="true"
                  >
                    Rota
                  </button>
                </li>
                <li ref="modalRouteTabRef" class="nav-item" role="presentation">
                  <button
                    class="nav-link border-0 rounded-0 text-black"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane"
                    aria-selected="false"
                  >
                    Mapa
                  </button>
                </li>
              </ul>
              <div class="tab-content h-100" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabindex="0"
                >
                  <div class="container p-2">
                    <div class="row my-2">
                      <h5 class="my-3 text-orange fw-semibold">
                        Dados iniciais da rota
                      </h5>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <div class="mb-2">
                          <label for="editRouteAccountName" class="form-label">
                            Motorista:
                          </label>
                          <div class="input-group input-group-sm">
                            <input
                              id="editRouteAccountName"
                              type="text"
                              class="form-control"
                              aria-label="Driver's name"
                              aria-describedby="input-driver-group"
                              disabled
                              v-model="editRoute.account.accountName"
                            />
                            <span class="input-group-text" id="basic-addon2">
                              {{
                                editRoute.primaryDriver
                                  ? 'Secundário'
                                  : 'Primário'
                              }}
                            </span>
                            <button
                              type="button"
                              class="btn btn-outline-danger"
                              @click="setUnlinkDriverFromRoute(editRoute)"
                              v-if="editRoute.primaryDriver"
                            >
                              <Popper
                                arrow
                                hover
                                content="Desvincular motorista"
                              >
                                <font-awesome-icon
                                  icon="fa-regular fa-unlink"
                                  size="sm"
                                />
                              </Popper>
                            </button>
                          </div>
                          <div v-if="editRoute.primaryDriver" class="form-text">
                            {{ editRoute.primaryDriver.accountName }}
                            (Motorista principal)
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="mb-2">
                          <label for="editRouteVehicle" class="form-label">
                            Veículo:
                          </label>
                          <input
                            id="editRouteVehicle"
                            v-mask="['AAA#X##', 'AAA-####']"
                            type="text"
                            class="form-control form-control-sm text-uppercase"
                            v-model="editRoute.vehicle.plate"
                          />
                          <div class="form-text">
                            {{ editRoute.vehicle.type.name }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-4">
                        <div class="mb-2">
                          <label for="editRouteWave" class="form-label">
                            Onda:
                          </label>
                          <select
                            class="form-select form-select-sm"
                            id="editRouteWave"
                            v-model="editRoute.wave"
                          >
                            <option value="1">1º Onda</option>
                            <option value="2">2º Onda</option>
                            <option value="3">3ª Onda</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row row-cols-1 row-cols-3">
                      <div class="col">
                        <div class="mb-2">
                          <label for="editRouteMeliId" class="form-label">
                            ID da rota
                          </label>
                          <input
                            v-if="meliRules.flag === 'Meli'"
                            id="editRouteMeliId"
                            type="text"
                            v-mask="'##########'"
                            class="form-control form-control-sm"
                            v-model="editRoute.meliId"
                          />
                          <input
                            v-else
                            id="editRouteMeliId"
                            type="text"
                            class="form-control form-control-sm"
                            v-model="editRoute.meliId"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-2">
                          <label
                            for="editRouteTotalPackages"
                            class="form-label"
                          >
                            Pacotes:
                          </label>
                          <input
                            id="editRouteTotalPackages"
                            type="number"
                            class="form-control form-control-sm"
                            v-model="editRoute.numberPackages"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-2">
                          <label for="editRouteTotalStops" class="form-label">
                            Total paradas:
                          </label>
                          <input
                            id="editRouteTotalStops"
                            type="text"
                            class="form-control form-control-sm"
                            v-model="editRoute.totalStops"
                            :disabled="!permissions.manageRoutes"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="row row-cols-1"
                      :class="{
                        'row-cols-2':
                          editRoute.account.driver.serviceCenter
                            .enableKilometer,
                      }"
                    >
                      <div class="col">
                        <div class="mb-2">
                          <label
                            for="editRouteInitialDateTime"
                            class="form-label"
                          >
                            Data e hora inicial:
                          </label>

                          <VDatePicker
                            v-model="editRoute.initialDateTime"
                            mode="dateTime"
                            is24hr
                          >
                            <template #default="{ inputValue, inputEvents }">
                              <input
                                id="editRouteInitialDateTime"
                                class="form-control form-control-sm"
                                :value="inputValue"
                                v-on="inputEvents"
                              />
                            </template>
                          </VDatePicker>
                        </div>
                      </div>
                      <div
                        class="col"
                        v-if="
                          editRoute.account.driver.serviceCenter.enableKilometer
                        "
                      >
                        <div class="mb-2">
                          <label for="editRouteInitialKm" class="form-label">
                            Quilometragem do veículo:
                          </label>

                          <input
                            id="editRouteInitialKm"
                            type="number"
                            class="form-control form-control-sm"
                            v-model="editRoute.initialKilometer"
                          />
                        </div>
                      </div>
                    </div>
                    <hr
                      class="border border-1 border-top border-secondary border-1"
                    />
                    <div class="row my-2">
                      <h5 class="my-3 text-orange fw-semibold">Imagens</h5>
                    </div>

                    <div class="row align-items-center">
                      <div class="col">
                        <div class="mb-2 d-flex flex-column align-items-center">
                          <label class="form-label">
                            ID da rota do Mercado Livre:
                          </label>
                          <div class="editRouteImgs">
                            <div
                              role="button"
                              @click="openLightboxImage(editRoute.meliPhoto)"
                            >
                              <img
                                :src="editRoute.meliPhoto"
                                class="img-thumbnail"
                                @dragstart="dragStart($event, 'meliPhoto')"
                                id="meliPhoto"
                                draggable
                                @drop="drop"
                                @dragenter.prevent
                                @dragover.prevent
                              />
                            </div>
                          </div>

                          <input
                            type="file"
                            id="UpdateIDMeli"
                            style="display: none"
                            @change="editUpdateMeliPhoto($event)"
                          />
                          <label
                            for="UpdateIDMeli"
                            class="btn btn-sm btn-primary text-white mt-2"
                          >
                            <font-awesome-icon icon="fa-regular fa-camera" />
                            Alterar
                          </label>
                        </div>
                      </div>
                      <div
                        v-if="
                          editRoute.account.driver.serviceCenter
                            .enableKilometerPhotos
                        "
                        class="col"
                      >
                        <div class="mb-2 d-flex flex-column align-items-center">
                          <label class="form-label">Odômetro do veículo:</label>
                          <div class="editRouteImgs">
                            <div
                              @click="
                                openLightboxImage(
                                  editRoute.initialKilometerPhoto,
                                )
                              "
                              role="button"
                            >
                              <img
                                :src="
                                  editRoute.initialKilometerPhoto ||
                                  '/assets/img/unknow.png'
                                "
                                class="img-thumbnail"
                                id="initialKilometerPhoto"
                                draggable
                                @dragstart="
                                  dragStart($event, 'initialKilometerPhoto')
                                "
                                @drop="drop"
                                @dragenter.prevent
                                @dragover.prevent
                              />
                            </div>
                          </div>

                          <input
                            type="file"
                            id="updateInitialKilometer"
                            style="display: none"
                            @change="editUpdateInitialKilometerPhoto($event)"
                          />
                          <label
                            for="updateInitialKilometer"
                            class="btn btn-sm btn-primary text-white mt-2"
                          >
                            <font-awesome-icon icon="fa-regular fa-camera" />
                            Alterar
                          </label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-2">
                          <div
                            class="mb-2 d-flex flex-column align-items-center"
                          >
                            <label class="form-label">
                              Tire uma selfie agora:
                            </label>
                            <div class="editRouteImgs">
                              <div
                                @click="
                                  openLightboxImage(
                                    editRoute.initialSelfiePhoto,
                                  )
                                "
                                role="button"
                              >
                                <img
                                  :src="editRoute.initialSelfiePhoto"
                                  class="img-thumbnail"
                                  draggable
                                  @dragstart="dragStart($event, 'selfiePhoto')"
                                  id="selfiePhoto"
                                  @drop="drop"
                                  @dragenter.prevent
                                  @dragover.prevent
                                />
                              </div>
                            </div>

                            <input
                              type="file"
                              id="updateSelfie"
                              style="display: none"
                              @change="editUpdateSelfiePhoto($event)"
                            />
                            <label
                              for="updateSelfie"
                              class="btn btn-sm btn-primary text-white mt-2"
                            >
                              <font-awesome-icon icon="fa-regular fa-camera" />
                              Alterar
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="![1, 2, 6].includes(editRoute.statusId)">
                      <hr class="border border-1 border-top border-secondary" />

                      <div class="row my-2">
                        <h5 class="my-3 text-orange fw-semibold">
                          Dados finais
                        </h5>
                      </div>

                      <div
                        class="row row-cols-1 row-cols-2"
                        :class="{
                          'row-cols-3':
                            editRoute.account.driver.serviceCenter
                              .enableKilometer,
                        }"
                      >
                        <div class="col">
                          <div class="mb-2">
                            <label
                              for="editRouteFinalDateTime"
                              class="form-label"
                            >
                              Data e hora final:
                            </label>
                            <VDatePicker
                              v-model="editRoute.finalDateTime"
                              mode="dateTime"
                              is24hr
                            >
                              <template #default="{ inputValue, inputEvents }">
                                <input
                                  id="editRouteFinalDateTime"
                                  class="form-control form-control-sm"
                                  :value="inputValue"
                                  v-on="inputEvents"
                                />
                              </template>
                            </VDatePicker>
                          </div>
                        </div>
                        <div
                          class="col"
                          v-if="
                            editRoute.account.driver.serviceCenter
                              .enableKilometer
                          "
                        >
                          <div class="mb-2">
                            <label for="editRouteFinalKm" class="form-label">
                              Quilometragem final do veículo:
                            </label>

                            <input
                              id="editRouteFinalKm"
                              type="number"
                              class="form-control form-control-sm"
                              v-model="editRoute.finalKilometer"
                            />
                            <div class="form-text">
                              <button class="btn btn-link" @click="copyApiText">
                                <font-awesome-icon
                                  icon="fa-regular fa-copy"
                                  size="sm"
                                  class="text-orange"
                                />
                              </button>
                              Diferença Quilometragem:
                              <strong>
                                {{
                                  editRoute.finalKilometer === ''
                                    ? '-'
                                    : editRoute.finalKilometer -
                                      editRoute.initialKilometer
                                }}
                              </strong>
                              km
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <label for="kmTravled" class="form-label">
                            Quilometragem percorrida:
                          </label>
                          <input
                            id="kmTravled"
                            type="text"
                            v-model="editRoute.kmTraveled"
                            class="form-control form-control-sm"
                          />
                          <div class="form-text">
                            Este é o valor usado no cálculo de valores.
                            <span class="d-block">
                              Regra usada:
                              <b>{{ currentUsedRule }}</b>
                            </span>
                            <span
                              v-if="
                                editRoute.account.driver.serviceCenter
                                  .kilometerValueRule !==
                                editRoute.kilometerValueRule
                              "
                              class="d-block"
                            >
                              Regra atual:
                              <b>{{ currentRule }}</b>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="
                          editRoute.account.driver.serviceCenter
                            .enableKilometerPhotos
                        "
                        class="row justify-content-center my-2"
                      >
                        <div class="col-12">
                          <div
                            class="mb-2 d-flex flex-column align-items-center"
                          >
                            <label class="form-label">
                              Foto final do odômetro do veículo:
                            </label>
                            <div class="editRouteImgs">
                              <div
                                @click="
                                  openLightboxImage(
                                    editRoute.finalKilometerPhoto,
                                  )
                                "
                                role="button"
                              >
                                <img
                                  :src="
                                    editRoute.finalKilometerPhoto ||
                                    '/assets/img/unknow.png'
                                  "
                                  class="img-thumbnail"
                                  id="finalKilometerPhoto"
                                  draggable
                                  @dragstart="
                                    dragStart($event, 'finalKilometerPhoto')
                                  "
                                  @drop="drop"
                                  @dragenter.prevent
                                  @dragover.prevent
                                />
                              </div>
                            </div>

                            <input
                              type="file"
                              id="updatFinalKilometerPhoto"
                              style="display: none"
                              @change="editUpdateFinalKilometerPhoto"
                            />
                            <label
                              for="updatFinalKilometerPhoto"
                              class="btn btn-sm btn-primary text-white mt-2"
                            >
                              <font-awesome-icon icon="fa-regular fa-camera" />
                              Alterar
                            </label>
                          </div>
                        </div>
                      </div>

                      <div v-if="permissions.manageRoutes" class="form-group">
                        <h5 class="my-3 text-orange fw-semibold">
                          Importar dados de geolocalização (opcional):
                        </h5>
                        <div
                          v-if="
                            editRoute.geolocation.app ||
                            editRoute.geolocation.manual ||
                            editRoute.geolocation.meli
                          "
                          class="form-text"
                        >
                          Essa rota possui geolocalização de:
                          <span
                            v-if="editRoute.geolocation.app"
                            class="badge bg-primary"
                          >
                            app
                          </span>
                          <span
                            class="badge bg-primary"
                            v-if="editRoute.geolocation.manual"
                          >
                            manual
                          </span>
                          <span
                            class="badge bg-primary"
                            v-if="editRoute.geolocation.meli"
                          >
                            meli
                          </span>
                        </div>
                        <div v-else class="form-text">
                          Essa rota não possui dados de geolocalização.
                        </div>
                      </div>

                      <div
                        v-if="permissions.manageRoutes"
                        class="row row-cols-12"
                      >
                        <div class="col">
                          <div
                            v-if="manualCoordinates.errors.length"
                            class="alert alert-warning alert-dismissible fade show"
                            role="alert"
                          >
                            Atenção! Existem
                            <strong>
                              {{
                                manualCoordinates.errors.length > 1
                                  ? `${manualCoordinates.errors.length} erros`
                                  : `${manualCoordinates.errors.length} erro`
                              }}
                            </strong>
                            no arquivo de geolocalização (XLSX). Click
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#modalManualCoordinates"
                              class="alert-link"
                            >
                              aqui
                            </a>
                            para ver os detalhes.
                            <button
                              type="button"
                              class="btn-close btn-close-white"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            ></button>
                          </div>

                          <div class="drop-zone">
                            <div
                              v-bind="getRootProps()"
                              class="d-flex align-items-center justify-content-center"
                            >
                              <input v-bind="getInputProps()" />
                              <p class="m-0" v-if="isDragActive">
                                Solte os arquivos ...
                              </p>
                              <p class="m-0" v-else>
                                Arraste e solte o arquivo aqui, ou clique e
                                selecione o arquivo.
                              </p>
                            </div>
                            <button
                              type="button"
                              class="btn btn-sm btn-orange place"
                              @click="open"
                            >
                              Abrir
                            </button>
                          </div>
                          <div
                            class="d-grid d-sm-flex align-items-center justify-content-between"
                          >
                            <div class="form-text fw-semibold">
                              <font-awesome-icon
                                icon="fa-regular fa-circle-check"
                                :class="{
                                  'text-success': manualCoordinates.rows.length,
                                  'text-secondary':
                                    manualCoordinates.rows.length === 0,
                                }"
                              />
                              {{ manualCoordinates.rows.length }} coordenadas
                              importadas.
                            </div>
                            <div class="form-text ms-auto">
                              Click
                              <a
                                href="/template_manual_coordenadas.xlsx"
                                target="_blank"
                                download
                              >
                                aqui
                              </a>
                              para baixar o modelo de arquivo de geolocalização.
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr class="border border-1 border-top border-secondary" />
                      <div class="row my-2">
                        <h5 class="my-3 text-orange fw-semibold">Pedágios</h5>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="mb-2">
                            <label for="editRouteTollValue" class="form-label">
                              Valor total em pedágios:
                            </label>
                            <CurrencyInput
                              id="editRouteTollValue"
                              v-model="editRoute.tolls"
                              class="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <VueElementLoading
                            :active="isDeletingPhoto"
                            spinner="ring"
                            color="#FF6700"
                            size="60"
                            text="Excluindo..."
                          />
                          <div class="mb-2">
                            <label class="form-label">
                              Insira alguns anexos, se desejar:
                            </label>
                            <div class="images-container">
                              <div
                                v-for="(img, index) in editRoute.imagesPreview"
                                :key="img.id"
                              >
                                <img
                                  :src="img.photo"
                                  role="button"
                                  @click="() => openLightboxImage(img.photo)"
                                  draggable
                                  @dragstart="
                                    dragStart(
                                      $event,
                                      'attachmentPhoto',
                                      img.id,
                                      index,
                                    )
                                  "
                                  id="attachmentPhoto"
                                  @drop="drop($event, 'attachmentPhoto', index)"
                                  @dragenter.prevent
                                  @dragover.prevent
                                />
                                <button
                                  @click="
                                    () =>
                                      deleteAttachment({
                                        attachment: img,
                                        index,
                                      })
                                  "
                                  type="button"
                                  class="remove-button"
                                >
                                  <font-awesome-icon
                                    icon="fa-regular fa-xmark"
                                  />
                                </button>
                              </div>

                              <label
                                htmlFor="editImages[]"
                                className="new-image"
                              >
                                <font-awesome-icon icon="fa-regular fa-plus" />
                              </label>
                            </div>
                            <input
                              class="d-none"
                              multiple
                              @change="handleSelectEditImages"
                              type="file"
                              id="editImages[]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade p-3 p-sm-0 h-100"
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  tabindex="0"
                >
                  <div
                    class="d-grid d-sm-flex justify-content-between align-items-center my-2"
                  >
                    <div class="mx-auto mx-sm-0">
                      <RouterLink
                        :to="{
                          name: 'Editar Rota',
                          params: { id: String(editRoute.id) },
                        }"
                      >
                        <button
                          class="btn btn-sm btn-orange me-2"
                          data-bs-dismiss="modal"
                          data-bs-backdrop="false"
                        >
                          <font-awesome-icon icon="fa-regular fa-edit" />
                          Editar rota
                        </button>
                      </RouterLink>

                      <button
                        v-if="
                          plugins.some(
                            (plugin) =>
                              plugin.connected &&
                              plugin.pluginId === globals.PLUGIN_MELI,
                          )
                        "
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="openModalRestoreRoute"
                      >
                        <font-awesome-icon icon="fa-regular fa-rotate-right" />
                        {{
                          editRoute.flag === 'app' ||
                          editRoute.flag === 'appUpdated'
                            ? 'Substituir por trajeto do MELI?'
                            : 'Restaurar trajeto do MELI?'
                        }}
                      </button>
                    </div>
                    <div
                      class="d-flex gap-1 align-items-center justify-content-md-start"
                    >
                      <span class="text-muted fw-semibold">
                        KM Total: {{ editRoute.distanceMeters }}
                      </span>
                      <div class="border" v-if="editRoute.hasRoute" />
                      <span
                        v-show="editRoute.flag === 'app'"
                        :class="`badge  bg-primary `"
                      >
                        gerada pelo app
                      </span>
                      <span
                        v-show="editRoute.flag === 'appUpdated'"
                        :class="`badge  bg-primary `"
                      >
                        gerada pelo app e alterada
                      </span>
                      <span
                        v-show="editRoute.flag === 'meli'"
                        :class="`badge bg-primary `"
                      >
                        herdada do meli
                      </span>
                      <span
                        v-show="editRoute.flag === 'meliUpdated'"
                        :class="`badge  bg-primary `"
                      >
                        herdada pelo meli e alterada
                      </span>
                      <span
                        v-show="editRoute.flag === 'manual'"
                        :class="`badge  bg-primary `"
                      >
                        Manual
                      </span>
                      <span
                        v-show="editRoute.flag === 'manualUpdated'"
                        :class="`badge  bg-primary `"
                      >
                        Manual e alterada
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="!editRoute.hasRoute"
                    class="d-flex w-100 h-100 align-items-center justify-content-center bg-white bg-opacity-25"
                  >
                    <h5 class="fw-semibold text-muted">
                      Sem dados de geolocalização.
                    </h5>
                  </div>
                  <div v-show="editRoute.hasRoute" id="map"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
              v-if="!isLoading"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-sm btn-success"
              @click="verifyUpdateRouteValues"
            >
              <span
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
                v-if="isLoading"
              ></span>
              <span v-if="isLoading">Aguarde...</span>
              <span v-if="!isLoading">Salvar</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-confirm-delete-route"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header bg-orange text-white">
            <h5 class="modal-title">
              <font-awesome-icon icon="fa-regular fa-trash" class="mem-2" />
              Exluir rota
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row text-center">
              <p class="text-dark fw-bold">
                Tem certeza que deseja excluir a rota com ID
                <span class="fw-bold fs-5 text-orange">
                  {{ deleteRouteData.meliId }}
                </span>
                do motorista
                <span class="fw-bold fs-5 text-orange">
                  {{ deleteRouteData.accountName }}
                </span>
                ?
              </p>
            </div>

            <div class="row text-center my-3">
              <b class="d-block text-uppercase">Esta ação é irreversível!</b>
            </div>

            <div class="d-flex flex-column mx-5 text-center">
              <button
                v-if="!isLoading"
                @click="() => deleteRoute({ allRoutes: 1 })"
                type="button"
                class="btn btn-danger"
              >
                Excluir, e também recalular as rotas com o mesmo ID de rota
              </button>
              <small>
                Apenas as rotas que não estão em um fechamento serão recaluladas
              </small>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
              v-if="!isLoading"
            >
              Não
            </button>
            <button
              @click="() => deleteRoute({ allRoutes: 0 })"
              type="button"
              class="btn btn-sm btn-danger"
            >
              <span
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
                v-if="isLoading"
              ></span>
              <span v-if="isLoading">Aguarde...</span>
              <span v-if="!isLoading">Excluir</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-route-status"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-blue">
            <h5 class="modal-title">
              <font-awesome-icon icon="fa-regular fa-bullhorn" />
              Editar status
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <form @submit.prevent="updateRouteStatus">
            <div class="modal-body">
              <div class="form-group">
                <label for="routeTime" class="form-label">
                  Status da rota:
                </label>
                <select
                  id="routeTime"
                  v-model="updateStatusRoute.statusId"
                  class="form-select form-select-sm"
                >
                  <option value="1">Em andamento</option>
                  <option value="2">Parada</option>
                  <option value="7">Finalizada manualmente</option>
                  <option value="8">Arquivada manualmente</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                data-bs-dismiss="modal"
                v-if="!isLoading"
              >
                Cancelar
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                  v-if="isLoading"
                ></span>
                <span v-if="isLoading">Aguarde...</span>
                <span v-if="!isLoading">Salvar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-meliPhoto"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title text-white">
              <font-awesome-icon
                icon="fa-regular fa-crop-simple"
                class="me-2"
              />
              Ajuste a imagem do ID da Rota
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body ps-5 pe-5 pt-4 pb-3">
            <Cropper
              class="cropper"
              :src="cropperConfig.image"
              :stencil-props="{
                aspectRatio: '',
              }"
              @change="changePhotoArea"
              @ready="hideLoading"
            />

            <div class="d-block text-end mt-3">
              <button
                type="button"
                class="btn btn-sm btn-secondary text-white me-2"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success"
                @click="cropMeliPhoto"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-initialKilometerPhoto"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title text-white">
              <font-awesome-icon
                icon="fa-regular fa-crop-simple"
                class="me-2"
              />
              Ajuste a imagem do odômetro
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body ps-5 pe-5 pt-4 pb-3">
            <Cropper
              class="cropper"
              :src="cropperConfig.image"
              :stencil-props="{
                aspectRatio: 10 / 10,
              }"
              @change="changePhotoArea"
              @ready="hideLoading"
            />

            <div class="d-block text-end mt-3">
              <a class="btn btn-sm btn-secondary me-2" data-bs-dismiss="modal">
                Cancelar
              </a>
              <a
                class="btn btn-sm btn-success"
                @click="cropInitialKilometerPhoto"
              >
                Pronto!
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-selfiePhoto"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title text-white">
              <font-awesome-icon
                icon="fa-regular fa-crop-simple"
                class="me-2"
              />
              Ajuste a imagem da selfie
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body">
            <Cropper
              class="cropper"
              :src="cropperConfig.image"
              :stencil-props="{
                aspectRatio: 10 / 10,
              }"
              @change="changePhotoArea"
              @ready="hideLoading"
            />

            <div class="d-block text-end mt-3">
              <button
                type="button"
                class="btn btn-sm btn-secondary me-2"
                data-bs-dismiss="modal"
                v-if="!isLoading"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success"
                @click="cropPhotoSelfie"
              >
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                  v-if="isLoading"
                ></span>
                <span v-if="isLoading">Aguarde...</span>
                <span v-if="!isLoading">Salvar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal-md fade"
      id="modal-finalKilometerPhoto"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title text-white">
              <font-awesome-icon
                icon="fa-regular fa-crop-simple"
                class="me-2"
              />
              Ajuste a imagem do odômetro
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body ps-5 pe-5 pt-4 pb-3">
            <Cropper
              class="cropper"
              :src="cropperConfig.image"
              :stencil-props="{
                aspectRatio: 10 / 10,
              }"
              @change="changePhotoArea"
              @ready="hideLoading"
            />

            <div class="d-block text-end mt-3">
              <a class="btn btn-sm btn-secondary me-2" data-bs-dismiss="modal">
                Cancelar
              </a>
              <a
                class="btn btn-success btn-sm"
                @click="cropFinalKilometerPhoto"
              >
                Pronto!
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal-md fade"
      id="modal-attachments"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title text-white">
              <font-awesome-icon
                icon="fa-regular fa-crop-simple"
                class="me-2"
              />
              Ajuste a imagem do comprovante
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body">
            <Cropper
              class="cropper"
              :src="cropperConfig.image"
              :stencil-props="{
                aspectRatio: 10 / 10,
              }"
              @change="changePhotoArea"
              @ready="hideLoading"
            />

            <div class="d-block text-end mt-3">
              <button
                type="button"
                class="btn btn-sm btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success"
                @click="cropAttachments"
              >
                Pronto!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-update-meliPhoto"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title text-white">
              <font-awesome-icon
                icon="fa-regular fa-crop-simple"
                class="me-2"
              />
              Ajuste a imagem do ID da Rota
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body ps-5 pe-5 pt-4 pb-3">
            <Cropper
              class="cropper"
              :src="cropperConfig.image"
              :stencil-props="{
                aspectRatio: '',
              }"
              @change="changePhotoArea"
              @ready="hideLoading"
            />

            <div class="d-block text-end mt-3">
              <button
                type="button"
                class="btn btn-sm btn-secondary me-2"
                data-bs-dismiss="modal"
                v-if="!isLoading"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success"
                @click="cropUpdateMeliPhoto"
              >
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                  v-if="isLoading"
                ></span>
                <span v-if="isLoading">Aguarde...</span>
                <span v-if="!isLoading">Salvar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-update-initialKilometerPhoto"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title text-white">
              <font-awesome-icon
                icon="fa-regular fa-crop-simple"
                class="me-2"
              />
              Ajuste a imagem do odômetro
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body ps-5 pe-5 pt-4 pb-3">
            <Cropper
              class="cropper"
              :src="cropperConfig.image"
              :stencil-props="{
                aspectRatio: 10 / 10,
              }"
              @change="changePhotoArea"
              @ready="hideLoading"
            />

            <div class="d-block text-end mt-3">
              <button
                type="button"
                class="btn btn-sm btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                class="btn btn-sm btn-success"
                @click="cropUpdateInitialKilometerPhoto()"
              >
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                  v-if="isLoading"
                ></span>
                <span v-if="isLoading">Aguarde...</span>
                <span v-if="!isLoading">Salvar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-update-selfiePhoto"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title text-white">
              <font-awesome-icon
                icon="fa-regular fa-crop-simple"
                class="me-2"
              />
              Ajuste a imagem da selfie
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body">
            <Cropper
              class="cropper"
              :src="cropperConfig.image"
              :stencil-props="{
                aspectRatio: 10 / 10,
              }"
              @change="changePhotoArea"
              @ready="hideLoading"
            />

            <div class="d-block text-end mt-3">
              <button
                type="button"
                class="btn btn-sm btn-secondary me-2"
                data-bs-dismiss="modal"
                v-if="!isLoading"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success"
                @click="cropUpdateSelfiePhoto"
              >
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                  v-if="isLoading"
                ></span>
                <span v-if="isLoading">Aguarde...</span>
                <span v-if="!isLoading">Salvar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-update-finalKilometerPhoto"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title text-white">
              <font-awesome-icon
                icon="fa-regular fa-crop-simple"
                class="me-2"
              />
              Ajuste a imagem do odômetro
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body">
            <Cropper
              class="cropper"
              :src="cropperConfig.image"
              :stencil-props="{
                aspectRatio: 10 / 10,
              }"
              @change="changePhotoArea"
              @ready="hideLoading"
            />

            <div class="d-block text-end mt-3">
              <button
                :disabled="isLoading"
                type="button"
                class="btn btn-sm btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success"
                @click="cropUpdateFinalKilometerPhoto"
                :disabled="isLoading"
              >
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                  v-if="isLoading"
                ></span>
                <span v-if="isLoading">Aguarde...</span>
                <span v-if="!isLoading">Salvar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-updateRouteValues"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="background-color: rgb(33, 37, 41, 0.15) !important"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content border-0">
          <div class="modal-header text-white bg-orange">
            <h5 class="modal-title">
              <font-awesome-icon icon="fa-regular fa-coins" class="me-2" />
              Atualizar valores
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            ></button>
          </div>
          <div class="modal-body">
            <VueElementLoading
              :active="isLoading"
              spinner="spinner"
              color="#FF6700"
              size="60"
              text="Atualizando rota..."
            />
            <div
              class="d-grid align-items-center justify-content-center text-center"
            >
              <p class="text-dark">
                Deseja também atualizar os valores da rota com ID
                <span class="badge bg-primary text-white">
                  {{ editRoute.meliId }}
                </span>
                do motorista
                <strong>{{ editRoute.account.accountName }}</strong>
                ?
              </p>
              <div class="d-flex jutify-conetent-center mx-auto gap-2">
                <button
                  type="button"
                  class="btn btn-sm btn-secondary"
                  @click="updateRoute(false, false)"
                >
                  Não
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-success"
                  @click="updateRoute(true, false)"
                >
                  Sim
                </button>
              </div>
              <button
                type="button"
                class="btn btn-sm btn-success mt-4"
                @click="updateRoute(true, true)"
              >
                Sim, e também as rotas com o mesmo ID de rota
              </button>
              <small>
                Apenas as rotas que não estão em um fechamento serão recaluladas
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-route-restored-confirmartion"
      tabindex="-1"
      aria-labelledby="ModalRouteRestoredConfirmartionLabel"
      aria-hidden="true"
      style="background-color: rgb(33, 37, 41, 0.15) !important"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-orange text-white">
            <h1
              class="modal-title fs-5"
              id="ModalRouteRestoredConfirmartionLabel"
            >
              Substituir por trajeto do MELI
            </h1>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              v-if="
                editRoute.geolocationSourcePriority === 'external' &&
                editRoute.kilometerValueRule === 'APP'
              "
              class="container"
            >
              Isso vai destruir o trajeto gerado pelo app e vai substituir pelo
              trajeto do MELI passando por todos os pontos da rota
              <b>e seus valores serão recalculados.</b>
              Esta ação é
              <b>irreversível</b>
              . Tem certeza que deseja fazer isso?
            </div>
            <div v-else class="container">
              Isso vai destruir o trajeto gerado pelo app e vai substituir pelo
              trajeto do MELI passando por todos os pontos da rota. Esta ação é
              <b>irreversível</b>
              . Tem certeza que deseja fazer isso?
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              data-bs-dismiss="modal"
            >
              Não
            </button>
            <button
              @click="restoreRouteById"
              type="button"
              class="btn btn-sm btn-success"
            >
              Sim
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <VueEasyLightbox
    :escDisabled="false"
    moveDisabled
    :visible="lightboxImage.visible"
    :imgs="lightboxImage.imgs"
    :index="0"
    @hide="() => (lightboxImage.visible = false)"
  />
</template>

<style scoped>
section {
  height: calc(100vh - 100px);
}

.nav-link.active {
  color: var(--orange-450) !important;
  border-bottom: 2px solid var(--orange-450) !important;
  font-weight: bold !important;
}

.nav-link:not(.active) {
  border-bottom: 2px solid gray !important;
  font-weight: normal !important;
  color: #000 !important;
}
.nav-link:not(.active):hover {
  border: 0;
}

.list {
  padding-left: 0;
  padding-right: 0;
}

.list-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.map-label-content {
  order: 1;
  position: relative;
  left: -50%;
  background-color: #fff;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: #f35e1d;
  padding: 0px 8px;
  white-space: nowrap;
  font-weight: 600;
}

.map-label-arrow {
  order: 2;
  width: 0px;
  height: 0px;
  left: 50%;
  border-style: solid;
  border-color: #f35e1d transparent transparent transparent;
  border-width: 10px 6px 0 6px;
  margin-left: -6px;
}

.map-label-arrow.base {
  border-color: var(--orange-450) transparent transparent transparent;
  border-width: 50px 6px 0 6px; /*[first number is height, second/fourth are rigth/left width]*/
}

.map-label-content.base {
  border-color: var(--orange-450);
}

.map-label-arrow.initial {
  border-color: #1e3895 transparent transparent transparent;
  border-width: 25px 6px 0 6px; /*[first number is height, second/fourth are rigth/left width]*/
}

.map-label-content.initial {
  border-color: #1e3895;
}

.map-label-arrow.final {
  border-color: #1e3895 transparent transparent transparent;
  border-width: 5px 6px 0 6px; /*[first number is height, second/fourth are rigth/left width]*/
}

.map-label-content.final {
  border-color: #1e3895;
}

.img-thumbnail {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.editRouteImgs img {
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 20px;
}

.images-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}

.images-container div {
  position: relative;
}

.images-container img {
  width: 100%;
  height: 96px;
  object-fit: cover;
  border-radius: 20px;
}

.images-container .new-image {
  height: 96px;
  width: 96px;
  background: var(--white-400);
  border: 1px dashed var(--orange-450);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-route-images {
  height: 120px;
  width: 120px;
  background: var(--white-400);
  border: 1px dashed var(--orange-450);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-button {
  position: absolute;
  width: 40px;
  height: 40px;
  background: '#FFF';
  right: 0;
  top: 0;
  cursor: pointer;
  outline: none;
  border: 1px solid var(--orange-300);
  box-sizing: border-box;
  border-radius: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-button:hover {
  box-shadow: 0px 0px 18px -2px rgb(235, 19, 19);
}

@media (max-width: 760px) {
  .images-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 760px) and (max-width: 1024px) {
  .images-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.fixed-width {
  min-width: 100px !important;
}

.customize-table {
  --easy-table-header-font-color: white;
  --easy-table-header-background-color: var(--blue-650);
}

.multi-select-orange {
  box-shadow: none !important;
}

.input-plate-search {
  width: 200px;
}

@media (max-width: 768px) {
  .input-plate-search {
    width: 100%;
  }
}

.icon-shape {
  width: 45px;
  height: 45px;
  background-position: 50%;
}

.drop-zone {
  border-radius: 8px;
  border: 1px dashed var(--orange-450);
  text-align: center;
  padding: 1rem;
}

.drop-zone > div {
  height: 100px;
}
</style>
