import { api } from '../api';
import errors from '@/utils/errors';

export const fectherRoutes = async ({
  name,
  date,
  serviceCenterId,
  routeId,
  getMeliData,
}) =>
  await api
    .get(`meli/searchRoutes`, {
      params: { name, date, serviceCenterId, routeId, getMeliData },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
    .then((response) => {
      for (let i = 0; i < response.data.routes.length; i += 1) {
        if (response.data.routes[i].timer) {
          response.data.routes[i].timer.stop();
        }
      }

      return response.data;
    });

class RoutesService {
  async indexRoutes({ name, date, serviceCenterId, routeId, getMeliData }) {
    try {
      const { data, status } = await api.get(`meli/searchRoutes`, {
        params: { name, date, serviceCenterId, routeId, getMeliData },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar as rotas.');
    }
  }

  async createRoute({ form }) {
    try {
      const { data, status } = await api.post('meli/routeManual/', form, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível criar a rota.');
    }
  }

  async updateRoute({ id, form }) {
    try {
      const { data, status } = await api.put(`/meli/route/${id}`, form, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível atualizar a rota.');
    }
  }

  async deleteRoute({ id, allRoutes }) {
    try {
      const { data, status } = await api.delete(
        `meli/route/${id}/${allRoutes}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível deletar a rota.');
    }
  }

  async deleteAttachment({ routeId, id }) {
    try {
      const { status } = await api.delete(
        `/meli/route/${routeId}/attachment/${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { status };
    } catch (error) {
      errors.handler(error, 'Não foi possível deletar o anexo.');
    }
  }

  async getRouteById({ id }) {
    try {
      const { data, status } = await api.get(`meli/route/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível obter a rota.');
    }
  }

  async updateInitialKilometerPhoto({ id, form }) {
    try {
      const { data, status } = await api.put(
        `/meli/initialKilometerPhoto/${id}`,
        form,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível atualizar a foto.');
    }
  }

  async updateFinalKilometerPhoto({ id, form }) {
    try {
      const { data, status } = await api.put(
        `/meli/finalKilometerPhoto/${id}`,
        form,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível atualizar a foto.');
    }
  }

  async updateSelfiePhoto({ id, form }) {
    try {
      const { data, status } = await api.put(
        `meli/initialSelfiePhoto/${id}`,
        form,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível atualizar a foto.');
    }
  }

  async updateMeliPhoto({ id, form }) {
    try {
      const { data, status } = await api.put(`meli/meliPhoto/${id}`, form, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível atualizar a foto.');
    }
  }

  async exportRoutes({ initialDate, finalDate, serviceCenterId }) {
    try {
      const { data, status } = await api.get(`meli/exportRoutes/`, {
        params: {
          initialDate,
          finalDate,
          serviceCenterId,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar as rotas.');
    }
  }

  async updateRouteStatus({ id, statusId }) {
    try {
      const { data, status } = await api.put(
        `meli/routeStatus/${id}`,
        { statusId },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível atualizar status da rota.');
    }
  }

  async updateGeolocation({
    polyline,
    distanceMeters,
    waypoints,
    initialLocation,
    finalLocation,
    selectedPolyline,
    routeId,
    stops,
  }) {
    try {
      const { status } = await api.put(
        `/meli/routes/${routeId}/geolocation`,
        {
          polyline,
          distanceMeters,
          waypoints,
          initialLocation,
          finalLocation,
          selectedPolyline,
          stops,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { status };
    } catch (error) {
      errors.handler(error, 'Não foi possível atualizar as coordenadas.');
    }
  }

  async getGeolocation({ routeId }) {
    try {
      const { data, status } = await api.get(`/meli/routes/geolocation`, {
        params: {
          id: routeId,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      if (error.response && error.response.status === 409) {
        return;
      }
      errors.handler(error, 'Não foi possível obter a geolocalização.');
    }
  }

  async restoreRouteById({ routeId }) {
    try {
      const { status } = await api.put(
        `/meli/routes/${routeId}/geolocation/restore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { status };
    } catch (error) {
      errors.handler(error, 'Não foi possível restaurar rota.');
    }
  }

  async updateRouteValues({ routeId }) {
    try {
      const { status } = await api.put(
        `/meli/routes/${routeId}/recalculateValues`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { status };
    } catch (error) {
      errors.handler(error, 'Não foi possível atualizar valores da rota.');
    }
  }

  async updateImgsRouteOrder({
    routeId,
    origin,
    destiny,
    originId,
    destinyId,
  }) {
    try {
      const { status } = await api.put(
        `/meli/route/changePhotos/${routeId}`,
        {
          origin,
          destiny,
          originId: originId || undefined,
          destinyId: destinyId || undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { status };
    } catch (error) {
      errors.handler(error, 'Não foi possível atualizar a ordem das imagens.');
    }
  }

  async createRouteByXlsx(routes) {
    try {
      const { status, data } = await api.put(
        'meli/routes/manualGeolocation',
        routes,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { status, data };
    } catch (error) {
      errors.handler(error, 'Não foi possível criar as rotas.');
    }
  }

  async unlinkDriver({ routeId }) {
    try {
      const { status } = await api.put(
        `meli/routes/${routeId}/unlinkPrimaryDriver`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      return { status };
    } catch (error) {
      errors.handler(error, 'Não foi possível desvincular o motorista.');
    }
  }

  async getMeliRules({ serviceCenterId }) {
    try {
      const { data, status } = await api.get(`/meli/rules`, {
        params: {
          serviceCenterId,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível obter as regras do meli.');
    }
  }
}

export { RoutesService };
