<template>
  <div class="flex-container rounded bg-white p-2 mx-3">
    <div class="d-grid d-sm-flex gap-1 align-items-center">
      <button
        type="button"
        class="btn btn-sm btn-orange"
        @click="
          serviceCenterFilterText != 'Todos' ? exportCsv() : exportAllCsv()
        "
      >
        <font-awesome-icon icon="fa-regular fa-download" />
        {{
          serviceCenterFilterText != 'Todos'
            ? 'Exportar confronto'
            : 'Exportar todos os confrontos'
        }}
      </button>

      <div class="ms-auto py-2" v-if="lastUpdatedAt">
        <span v-if="loading" class="ms-auto">Carregando...</span>
        <span v-else class="ms-auto">
          <font-awesome-icon icon="fa-regular fa-info" />
          Dados atualizados em
          {{ filters.dateTimeDisplay(lastUpdatedAt) }}
        </span>
      </div>
      <button
        type="button"
        class="btn btn-sm btn-orange"
        data-bs-target="#modal-filter"
        data-bs-toggle="modal"
      >
        <font-awesome-icon icon="fa-regular fa-filter" />
        Filtrar
      </button>
    </div>
  </div>

  <div :class="`flex-container rounded bg-white p-2 my-2 mx-3 text-center`">
    <h5 class="m-0 text-orange">
      {{ filterRoutes.serviceCenter.name }}
    </h5>
  </div>

  <div :class="`flex-container rounded bg-white p-2 mt-2  mx-3`">
    <div
      :key="keyChange.refreshSelected"
      v-if="filterRoutes.serviceCenter.name !== 'Todos'"
    >
      <div class="row g-1">
        <div class="col-xl-3 col-sm-6 col-12">
          <CardOverview
            title="Total pacotes"
            :value="{
              total:
                selectedServiceCenter.totalResidential +
                selectedServiceCenter.totalCommercial +
                selectedServiceCenter.totalNotCategorized,
              delivered:
                selectedServiceCenter.residentialDelivered +
                selectedServiceCenter.residentialFailures +
                selectedServiceCenter.commercialDelivered +
                selectedServiceCenter.commercialFailures +
                selectedServiceCenter.notCategorizedDelivered +
                selectedServiceCenter.notCategorizedFailures,
            }"
            :delivered="
              selectedServiceCenter.residentialDelivered +
              selectedServiceCenter.commercialDelivered +
              selectedServiceCenter.totalNotCategorized
            "
            :fails="
              selectedServiceCenter.residentialFailures +
              selectedServiceCenter.commercialFailures +
              selectedServiceCenter.notCategorizedFailures
            "
            :toBeDelivered="
              selectedServiceCenter.totalResidential +
              selectedServiceCenter.totalCommercial +
              selectedServiceCenter.totalNotCategorized -
              (selectedServiceCenter.residentialDelivered +
                selectedServiceCenter.commercialDelivered +
                selectedServiceCenter.residentialFailures +
                selectedServiceCenter.commercialFailures +
                selectedServiceCenter.notCategorizedDelivered +
                selectedServiceCenter.notCategorizedFailures)
            "
            :loading="loading"
          />
        </div>
        <div class="col-xl-3 col-sm-6 col-12">
          <CardOverview
            title="Pacotes comerciais"
            :value="{
              total: selectedServiceCenter.totalCommercial,
              delivered:
                selectedServiceCenter.commercialDelivered +
                selectedServiceCenter.commercialFailures,
            }"
            :delivered="selectedServiceCenter.commercialDelivered"
            :fails="selectedServiceCenter.commercialFailures"
            :toBeDelivered="
              selectedServiceCenter.totalCommercial -
              (selectedServiceCenter.commercialDelivered +
                selectedServiceCenter.commercialFailures)
            "
            :loading="loading"
          />
        </div>
        <div class="col-xl-3 col-sm-6 col-12">
          <CardOverview
            title="Pacotes residenciais"
            :value="{
              total: selectedServiceCenter.totalResidential,
              delivered:
                selectedServiceCenter.residentialDelivered +
                selectedServiceCenter.residentialFailures,
            }"
            :delivered="selectedServiceCenter.residentialDelivered"
            :fails="selectedServiceCenter.residentialFailures"
            :toBeDelivered="
              selectedServiceCenter.totalResidential -
              (selectedServiceCenter.residentialDelivered +
                selectedServiceCenter.residentialFailures)
            "
            :loading="loading"
          />
        </div>
        <div class="col-xl-3 col-sm-6 col-12">
          <div class="border rounded d-block p-3">
            <VueElementLoading
              :active="loading"
              spinner="spinner"
              color="#FF6700"
              size="60"
            />
            <p class="fw-bold">Total verificados</p>
            <div class="d-inline-block align-top col-3">
              <CircularProgress
                :options="{
                  color: 'success',
                  value: calculatePercentDeliveries(
                    routesFiltered.length,
                    infoTotal.totalVerified,
                  ),
                }"
              />
            </div>
            <div class="d-inline-block align-top col-9">
              <small class="d-block">
                {{ infoTotal.totalRoutesMeli }} no MELI |
                <span class="fw-bold">Não batem</span>
                {{ infoTotal.totalRouteBldOnly }}
              </small>
              <small class="d-block">
                {{ infoTotal.totalVerified }} Subiram |
                {{ infoTotal.totalNotVerified }} Não subiram
              </small>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 col-12">
          <CardOverview
            title="Pacotes não categorizados"
            :value="{
              total: selectedServiceCenter.totalNotCategorized,
              delivered:
                selectedServiceCenter.notCategorizedDelivered +
                selectedServiceCenter.notCategorizedFailures,
            }"
            :delivered="selectedServiceCenter.notCategorizedDelivered"
            :fails="selectedServiceCenter.notCategorizedFailures"
            :toBeDelivered="
              selectedServiceCenter.totalNotCategorized -
              (selectedServiceCenter.notCategorizedDelivered +
                selectedServiceCenter.notCategorizedFailures)
            "
            :loading="loading"
          />
        </div>
        <div class="col-xl-3 col-sm-6 col-12">
          <div class="border rounded d-block p-3">
            <VueElementLoading
              :active="loading"
              spinner="spinner"
              color="#FF6700"
              size="60"
            />
            <p v-if="filterRoutes.date == date.getToday()" class="fw-bold">
              Veículos obrigatórios
            </p>
            <p v-else>
              <span class="fw-bold">Veículos obrigatórios</span>
              (
              <font-awesome-icon icon="fa-regular fa-triangle-exclamation" />
              Referente ao dia de hoje)
            </p>
            <div class="d-inline-block align-top col-3">
              <CircularProgress
                :options="{
                  color: 'success',
                  value: calculatePercentDeliveries(
                    selectedServiceCenter.vehiclesTotal,
                    selectedServiceCenter.vehiclesTravelling,
                  ),
                }"
              />
            </div>
            <div class="d-inline-block align-top col-9">
              <small class="d-block">
                {{ selectedServiceCenter.vehiclesTravelling }} Rodando
              </small>
              <small class="d-block">
                {{
                  selectedServiceCenter.vehiclesTotal -
                  selectedServiceCenter.vehiclesTravelling
                }}
                Não estão rodando
              </small>
            </div>
          </div>
        </div>
        <div
          v-if="weatherFiltered[0] !== undefined"
          class="col-xl-3 col-sm-6 col-12"
        >
          <div class="border rounded d-block p-3">
            <VueElementLoading
              :active="loading"
              spinner="spinner"
              color="#FF6700"
              size="60"
            />
            <p class="fw-bold">Tempo agora</p>
            <div class="d-inline-block align-top col-3">
              <img
                :src="
                  '/assets/icons/weather/' +
                  weatherFiltered[0].weather.condition_slug +
                  '.png'
                "
                style="height: 40px"
              />
            </div>
            <div class="d-inline-block align-top col-9">
              <h6>
                {{
                  globals.WEATHERS_CODES[
                    weatherFiltered[0].weather.condition_code
                  ]
                }}
              </h6>
              <span>{{ weatherFiltered[0].weather.temp }}ºC</span>
            </div>
          </div>
        </div>
        <div
          v-if="weatherFiltered[0] !== undefined"
          class="col-xl-3 col-sm-6 col-12"
        >
          <div class="border rounded d-block p-3">
            <VueElementLoading
              :active="loading"
              spinner="spinner"
              color="#FF6700"
              size="60"
            />
            <p class="fw-bold">Tempo amanhã</p>
            <div class="d-inline-block align-top col-3">
              <img
                :src="
                  '/assets/icons/weather/' +
                  weatherFiltered[0].weather.forecast[1].condition +
                  '.png'
                "
                style="height: 40px"
              />
            </div>
            <div class="d-inline-block align-top col-9">
              <h6>
                {{ weatherFiltered[0].weather.forecast[1].description }}
              </h6>
              <span>
                {{ weatherFiltered[0].weather.forecast[1].min }}ºC |
                {{ weatherFiltered[0].weather.forecast[1].max }}ºC
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="filterRoutes.serviceCenter.name === 'Todos'"
      :key="keyChange.refreshSelected"
      class="row g-1"
    >
      <div class="col-xl-3 col-sm-6 col-12">
        <div class="border rounded d-block p-3">
          <VueElementLoading
            :active="loading"
            spinner="spinner"
            color="#FF6700"
            size="60"
          />
          <p class="fw-bold">Total pacotes</p>
          <div class="d-inline-block align-top col-3">
            <CircularProgress
              :options="{
                color: 'success',
                value: calculatePercentDeliveries(
                  totalAll.totalResidential +
                    totalAll.totalCommercial +
                    totalAll.totalNotCategorized,
                  totalAll.residentialDelivered +
                    totalAll.residentialFailures +
                    totalAll.commercialDelivered +
                    totalAll.commercialFailures +
                    totalAll.notCategorizedDelivered +
                    totalAll.notCategorizedFailures,
                ),
              }"
            />
          </div>
          <div class="d-inline-block align-top col-9">
            <small class="d-block">
              {{
                totalAll.residentialDelivered +
                totalAll.commercialDelivered +
                totalAll.notCategorizedDelivered
              }}
              entregues |
              {{
                totalAll.residentialFailures +
                totalAll.commercialFailures +
                totalAll.notCategorizedFailures
              }}
              falhas
            </small>
            <small class="d-block text-secondary">
              Faltam
              {{
                totalAll.totalResidential +
                totalAll.totalCommercial +
                totalAll.totalNotCategorized -
                (totalAll.residentialDelivered +
                  totalAll.commercialDelivered +
                  totalAll.residentialFailures +
                  totalAll.commercialFailures +
                  totalAll.notCategorizedDelivered +
                  totalAll.notCategorizedFailures)
              }}
            </small>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 col-12">
        <div class="border rounded d-block p-3">
          <VueElementLoading
            :active="loading"
            spinner="spinner"
            color="#FF6700"
            size="60"
          />

          <p class="fw-bold">Pacotes comerciais</p>
          <div class="d-inline-block align-top col-3">
            <CircularProgress
              :options="{
                color: 'success',
                value: calculatePercentDeliveries(
                  totalAll.totalCommercial,
                  totalAll.commercialDelivered + totalAll.commercialFailures,
                ),
              }"
            />
          </div>
          <div class="d-inline-block align-top col-9">
            <small class="d-block">
              {{ totalAll.commercialDelivered }} entregues |
              {{ totalAll.commercialFailures }} falhas
            </small>
            <small class="d-block text-secondary">
              Faltam
              {{
                totalAll.totalCommercial -
                (totalAll.commercialDelivered + totalAll.commercialFailures)
              }}
            </small>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 col-12">
        <div class="border rounded d-block p-3">
          <VueElementLoading
            :active="loading"
            spinner="spinner"
            color="#FF6700"
            size="60"
          />

          <p class="fw-bold">Pacotes residenciais</p>
          <div class="d-inline-block align-top col-3">
            <CircularProgress
              :options="{
                color: 'success',
                value: calculatePercentDeliveries(
                  totalAll.totalResidential,
                  totalAll.residentialDelivered +
                    selectedServiceCenter.residentialFailures,
                ),
              }"
            />
          </div>
          <div class="d-inline-block align-top col-9">
            <small class="d-block">
              {{ totalAll.residentialDelivered }} entregues |
              {{ totalAll.residentialFailures }} falhas
            </small>
            <small class="d-block text-secondary">
              Faltam
              {{
                totalAll.totalResidential -
                (totalAll.residentialDelivered + totalAll.residentialFailures)
              }}
            </small>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 col-12">
        <div class="border rounded d-block p-3">
          <VueElementLoading
            :active="loading"
            spinner="spinner"
            color="#FF6700"
            size="60"
          />

          <p class="fw-bold">Total verificados</p>
          <div class="d-inline-block align-top col-3">
            <CircularProgress
              :options="{
                color: 'success',
                value: calculatePercentDeliveries(
                  totalAll.verified + totalAll.notVerified,
                  totalAll.verified,
                ),
              }"
            />
          </div>
          <div class="d-inline-block align-top col-9">
            <small class="d-block">{{ totalAll.verified }} Subiram</small>
            <small class="d-block">
              {{ totalAll.notVerified }} Não subiram
            </small>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 col-12">
        <div class="border rounded d-block p-3">
          <VueElementLoading
            :active="loading"
            spinner="spinner"
            color="#FF6700"
            size="60"
          />
          <p v-if="filterRoutes.date == date.getToday()" class="fw-bold">
            Veículos obrigatórios
          </p>
          <p v-else>
            <span class="fw-bold">Veículos obrigatórios</span>
            (
            <font-awesome-icon icon="fa-regular fa-triangle-exclamation" />
            Referente ao dia de hoje)
          </p>
          <div class="d-inline-block align-top col-3">
            <CircularProgress
              :options="{
                color: 'success',
                value: calculatePercentDeliveries(
                  selectedServiceCenter.vehiclesTotal,
                  selectedServiceCenter.vehiclesTravelling,
                ),
              }"
            />
          </div>
          <div class="d-inline-block align-top col-9">
            <small class="d-block">
              {{ selectedServiceCenter.vehiclesTravelling }} Rodando
            </small>
            <small class="d-block">
              {{
                selectedServiceCenter.vehiclesTotal -
                selectedServiceCenter.vehiclesTravelling
              }}
              Não estão rodando
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    :class="`flex-container rounded bg-${
      showWeather ? 'white' : 'transparent'
    } p-2  mt-${showWeather ? '2' : '0'} mb-${showWeather ? '2' : '0'}  mx-3`"
  >
    <div
      v-if="showWeather && filterRoutes.serviceCenter.name === 'Todos'"
      class="row"
    >
      <h2 class="text-center mt-3">Previsão do tempo</h2>
      <ul class="list-unstyled" :key="keyChange.refreshWeather">
        <li class="bg-white shadow-sm p-3 mb-2">
          <div class="d-inline-block col-3 fw-bold">Base</div>
          <div class="d-inline-block col-3 fw-bold"></div>
          <div class="d-inline-block col-3 fw-bold">Tempo agora</div>
          <div class="d-inline-block col-3 fw-bold">Tempo amanhã</div>
        </li>
        <li
          class="bg-white shadow-sm p-3 mb-1"
          v-for="serviceCenter in weatherFiltered"
          :key="serviceCenter.id"
        >
          <div class="d-inline-block col-3 align-middle">
            {{ serviceCenter.serviceCenter }}
          </div>
          <div class="d-inline-block col-3 align-middle"></div>
          <div
            class="d-inline-block col-3 align-middle"
            v-if="serviceCenter.weather.forecast"
          >
            <div class="d-block">
              <div class="d-inline-block align-top col-2">
                <img
                  :src="
                    '/assets/icons/weather/' +
                    serviceCenter.weather.condition_slug +
                    '.png'
                  "
                  style="height: 40px"
                />
              </div>
              <div class="d-inline-block align-top col-10">
                <h6 class="m-0">
                  {{
                    globals.WEATHERS_CODES[serviceCenter.weather.condition_code]
                  }}
                </h6>
                <span>{{ serviceCenter.weather.temp }}ºC</span>
              </div>
            </div>
          </div>
          <div
            class="d-inline-block col-3 align-middle"
            v-if="serviceCenter.weather.forecast"
          >
            <div class="d-block">
              <div class="d-inline-block align-top col-2">
                <img
                  :src="
                    '/assets/icons/weather/' +
                    serviceCenter.weather.forecast[0].condition +
                    '.png'
                  "
                  style="height: 40px"
                />
              </div>
              <div class="d-inline-block align-top col-10">
                <h6 class="m-0">
                  {{
                    globals.WEATHERS_CODES[
                      serviceCenter.weather.forecast[0].description
                    ]
                  }}
                </h6>
                <span>
                  {{ serviceCenter.weather.forecast[0].min }}ºC |
                  {{ serviceCenter.weather.forecast[0].max }}ºC
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div
    class="flex-container rounded bg-white p-2 mb-2 mx-3"
    style="min-height: 400px"
  >
    <VueElementLoading
      :active="loading"
      spinner="spinner"
      color="#FF6700"
      size="60"
    />

    <canvas id="routesHistory"></canvas>
    <div
      v-if="routesHistoryChart === null"
      class="d-flex text-black justify-content-center align-items-center"
    >
      <h6>Aguarde gerando gráfico...</h6>
    </div>
  </div>

  <div class="flex-container rounded bg-white p-2 mx-3">
    <VueElementLoading
      :active="loading"
      spinner="spinner"
      color="#FF6700"
      size="60"
    />
    <div v-if="routesFiltered.length > 0" class="table-responsive">
      <table id="table-routes" class="table" style="min-width: 1200px">
        <thead class="bg-white shadow-lg border-bottom text-black">
          <tr>
            <th scope="col">ID da Rota</th>
            <th scope="col">Verificado</th>
            <th scope="col">Veículo</th>
            <th scope="col">Pacotes comerciais</th>
            <th scope="col">Pacotes residenciais</th>
            <th scope="col">Total pacotes</th>
            <th scope="col">Paradas</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="bg-white shadow-sm border-bottom align-middle"
            v-for="(route, index) in routesFiltered"
            :key="`${route.routeId}-${index}`"
          >
            <td>
              <a
                style="color: black"
                :href="
                  'https://envios.mercadolivre.com.br/logistics/monitoring-distribution/detail/' +
                  route.routeId +
                  ''
                "
                target="_blank"
              >
                {{ route.routeId }}
                <font-awesome-icon icon="fa-regular fa-up-right-from-square" />
              </a>
            </td>

            <td>
              <span
                class="badge bg-success"
                v-if="route.statusMelixBld"
                title="OK"
              >
                OK
              </span>
              <span
                v-else
                class="badge bg-danger"
                title="Consta no MELI, mas não subiu na BLD"
              >
                NÃO SUBIU
              </span>
            </td>

            <td>
              <span
                class="badge bg-success"
                v-if="route.vehicleStatus == 1"
                title="Placas iguais"
              >
                OK
              </span>
              <span
                class="badge bg-danger"
                v-else-if="route.vehicleStatus == 2"
                title="Placas diferentes"
              >
                Placas diferentes
              </span>
              <span
                class="badge bg-secondary"
                v-else-if="route.vehicleStatus == 3"
                title="Não subiu"
              >
                NÃO SUBIU
              </span>
            </td>

            <td>
              <div class="d-block">
                <div class="d-inline-block align-top col-3">
                  <CircularProgress
                    :options="{
                      color: 'success',
                      value: calculatePercentDeliveries(
                        route.totalCommercial,
                        route.commercialDelivered + route.commercialFailures,
                      ),
                    }"
                  />
                </div>
                <div class="d-inline-block align-top col-9">
                  <small class="d-block">
                    {{ route.commercialDelivered }} entregues |
                    {{ route.commercialFailures }} falhas
                  </small>
                  <small class="d-block text-secondary">
                    Faltam
                    {{
                      route.totalCommercial -
                      (route.commercialDelivered + route.commercialFailures)
                    }}
                  </small>
                </div>
              </div>
            </td>

            <td>
              <div class="d-block">
                <div class="d-inline-block align-top col-3">
                  <CircularProgress
                    :options="{
                      color: 'success',
                      value: calculatePercentDeliveries(
                        route.totalResidential,
                        route.residentialDelivered + route.residentialFailures,
                      ),
                    }"
                  />
                </div>
                <div class="d-inline-block align-top col-9">
                  <small class="d-block">
                    {{ route.residentialDelivered }} entregues |
                    {{ route.residentialFailures }} falhas
                  </small>
                  <small class="d-block text-secondary">
                    Faltam
                    {{
                      route.totalResidential -
                      (route.residentialDelivered + route.residentialFailures)
                    }}
                  </small>
                </div>
              </div>
            </td>

            <td>
              <div class="d-block">
                <div class="d-inline-block mt-3 col-4">
                  <CircularProgress
                    :options="{
                      color: 'success',
                      value: calculatePercentDeliveries(
                        route.totalResidential +
                          route.totalCommercial +
                          route.totalNotCategorized,
                        route.residentialDelivered +
                          route.residentialFailures +
                          route.commercialDelivered +
                          route.commercialFailures +
                          route.notCategorizedDelivered +
                          route.notCategorizedFailures,
                      ),
                    }"
                  />
                </div>
                <div class="d-inline-block align-top col-8">
                  <small class="d-block">
                    <span class="fw-bold">
                      {{ route.totalPackagesMeli }} MELI
                    </span>
                  </small>
                  <small class="d-block">
                    {{
                      route.residentialDelivered +
                      route.commercialDelivered +
                      route.notCategorizedDelivered
                    }}
                    entregues
                  </small>
                  <small class="d-block">
                    {{
                      route.residentialFailures +
                      route.commercialFailures +
                      route.notCategorizedFailures
                    }}
                    falhas
                  </small>
                  <small class="d-block text-secondary">
                    Faltam
                    {{
                      route.totalResidential +
                      route.totalCommercial +
                      route.totalNotCategorized -
                      (route.residentialDelivered +
                        route.residentialFailures +
                        route.commercialDelivered +
                        route.commercialFailures +
                        route.notCategorizedFailures +
                        route.notCategorizedDelivered)
                    }}
                  </small>
                </div>
              </div>
            </td>

            <td>
              {{ route.totalStops ? route.totalStops : '' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-else
      class="d-flex align-items-center justify-content-center"
      style="height: 200px"
    >
      <h6 class="text-bold text-black">Aguarde...</h6>
    </div>
  </div>

  <div class="modals">
    <div
      class="modal fade"
      id="modal-filter"
      tabindex="-1"
      aria-labelledby="OverviewModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-orange text-white">
            <h5 class="modal-title" id="OverviewModalLabel">
              <font-awesome-icon
                icon="fa-regular fa-magnifying-glass"
                class="me-2"
              />
              Filtrar
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="filterDate" class="form-label">Data</label>

                    <VDatePicker
                      :masks="masks"
                      v-model.string="filterRoutes.date"
                      mode="date"
                    >
                      <template #default="{ inputValue, inputEvents }">
                        <input
                          id="updateInvitationRouteDate"
                          class="form-control form-control-sm"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </VDatePicker>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="serviceCenterId">Base</label>
                    <select
                      class="form-control form-control-sm"
                      id="serviceCenterId"
                      v-model="filterRoutes.serviceCenter"
                    >
                      <option
                        :value="{
                          id: '',
                          name: 'Todos',
                        }"
                      >
                        Todos
                      </option>
                      <option
                        v-for="serviceCenter in serviceCenters"
                        :key="serviceCenter.id"
                        :value="{
                          id: serviceCenter.id,
                          name: serviceCenter.name,
                        }"
                      >
                        {{ serviceCenter.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Fechar
            </button>
            <button @click="filter()" type="button" class="btn btn-success">
              Filtrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CircularProgress from '@/components/CircularProgress';
import date from '@/utils/date';
import globals from '@/utils/globals';
import jsonConvertor from '@/utils/jsonConvertor';
import { useStorage } from '@vueuse/core';
import { Modal } from 'bootstrap';
import { Chart, registerables } from 'chart.js';
import NProgress from 'nprogress';
import { onMounted, reactive, ref } from 'vue';
import VueElementLoading from 'vue-element-loading';
import CardOverview from '../components/CardOverview.vue';
import { OverviewService } from '../services/OverviewService';
import { ServiceCenterService } from '../services/ServiceCenterService';
import filters from '../utils/vueFilters';

const serviceCenterService = new ServiceCenterService();
const overviewService = new OverviewService();
Chart.register(...registerables);

const serviceCentersWeather = ref([]);
const weatherFiltered = ref([]);
const serviceCenters = ref([]);
const routesFiltered = ref([]);
const routes = ref([]);
const vehicles = ref([]);
const totals = ref([]);
const showWeather = ref(false);
const loading = ref(false);

const masks = ref({
  modelValue: 'YYYY-MM-DD',
});

const routesHistoryChart = ref(null);

const lastUpdatedAt = ref('');
const serviceCenterFilterText = ref('');

const selectedServiceCenter = reactive({
  totalCommercial: 0,
  commercialDelivered: 0,
  commercialFailures: 0,
  totalResidential: 0,
  residentialDelivered: 0,
  residentialFailures: 0,
  notCategorizedDelivered: 0,
  notCategorizedFailures: 0,
  totalNotCategorized: 0,
  vehiclesTotal: 0,
  vehiclesTravelling: 0,
});

const infoTotal = reactive({
  totalNotVerified: 0,
  totalVerified: 0,
  totalRoutesMeli: 0,
  totalRoutesBld: 0,
  totalHelperOk: 0,
  totalHelperNotOk: 0,
  totalHasHelper: 0,
  totalMoreOrEqual80: 0,
  totalLessThan80: 0,
  totalPackagesOk: 0,
  totalPackagesNotOk: 0,
  totalRouteBldOnly: 0,
});

const totalAll = ref({
  delivered: 0,
  commercialDelivered: 0,
  residentialDelivered: 0,
  failures: 0,
  commercialFailures: 0,
  residentialFailures: 0,
  left: 0,
  total: 0,
  totalCommercial: 0,
  totalResidential: 0,
  verified: 0,
  notVerified: 0,
  totalNotCategorized: 0,
  notCategorizedDelivered: 0,
  notCategorizedFailures: 0,
});

const filterRoutes = reactive({
  date: date.getToday(),
  name: '',
  serviceCenter: {
    id: null,
    name: '',
  },
});

const keyChange = reactive({
  refreshCrawler: 0,
  refreshSelected: 0,
  refreshWeather: 0,
  refreshChart: 0,
});

const modals = reactive({
  modalFilter: null,
});

const preferences = useStorage(
  `@${sessionStorage.getItem('accountName')}-preferences`,
  {
    baseId: 0,
    name: '',
  },
);

onMounted(async () => {
  loading.value = true;
  setModals();
  await getServiceCenterStatus();
  await getServiceCenters();

  if (preferences.value.baseId) {
    filterRoutes.serviceCenter.id = preferences.value.baseId;
    filterRoutes.serviceCenter.name = preferences.value.name;

    filter(filterRoutes.serviceCenter.id, filterRoutes.serviceCenter.name);
  } else {
    filter(filterRoutes.serviceCenter.id, filterRoutes.serviceCenter.name);
  }

  loading.value = false;
});

function setModals() {
  modals.modalFilter = new Modal(document.getElementById('modal-filter'));
}

async function getRoutes({ date, serviceCenterId }) {
  if (process.env.NODE_ENV !== 'production') {
    routes.value.meliRoutes = [];
    routes.value.routes = [];
    return;
  }
  const response = await overviewService.searchRoutes({
    date,
    getMeliData: true,
    serviceCenterId: serviceCenterId,
    getMeliDataHistory: false,
  });

  if (response.status === 200) {
    routes.value = response.data;
  }
}

async function getVehicles({ serviceCenterId }) {
  if (!serviceCenterId && !serviceCenters.value.length) return;
  const response = await overviewService.searchVehicles({
    // Apenas aprovados
    statusIds: [2],
    checklist: true,
    serviceCenterIds: serviceCenterId
      ? [serviceCenterId]
      : serviceCenters.value.map((sc) => sc.id),
  });

  if (response.status === 200) {
    vehicles.value = response.data;
  }
}

async function getServiceCenters() {
  NProgress.start();
  const response = await serviceCenterService.myServiceCenters();

  if (response && response.status === 200) {
    response.data.map((service) => {
      if (service.abbreviation) {
        serviceCenters.value.push(service);
      }
    });

    filterRoutes.serviceCenter = {
      id: response.data[0].id,
      name: response.data[0].name,
    };
  }

  NProgress.done();
}

async function getServiceCenterStatus() {
  const response = await overviewService.getServiceCentersStatus();

  if (response.status === 200) {
    response.data.map((serviceCenter) => {
      if (
        serviceCenter.id !== 20 &&
        serviceCenter.id !== 21 &&
        serviceCenter.id !== 46
      ) {
        serviceCentersWeather.value.push(serviceCenter);
      }
    });
  }
}

async function filter() {
  modals.modalFilter.hide();
  loading.value = true;

  await Promise.all([
    await getRoutes({
      date: filterRoutes.date,
      serviceCenterId: filterRoutes.serviceCenter.id,
      getMeliData: true,
    }),
    await getVehicles({
      serviceCenterId: filterRoutes.serviceCenter.id,
    }),
  ]);

  const vehiclesNotTravelling = vehicles.value.filter((vehicle) => {
    return !routes.value.routes.some(
      (routeVehicle) => vehicle.plate === routeVehicle.plate,
    );
  });
  selectedServiceCenter.totalCommercial = 0;
  selectedServiceCenter.commercialDelivered = 0;
  selectedServiceCenter.commercialFailures = 0;
  selectedServiceCenter.totalResidential = 0;
  selectedServiceCenter.residentialDelivered = 0;
  selectedServiceCenter.residentialFailures = 0;
  selectedServiceCenter.notCategorizedDelivered = 0;
  selectedServiceCenter.notCategorizedFailures = 0;
  selectedServiceCenter.totalNotCategorized = 0;

  infoTotal.totalNotVerified = 0;
  infoTotal.totalVerified = 0;
  infoTotal.totalRoutesMeli = 0;
  infoTotal.totalRoutesBld = 0;
  infoTotal.totalMoreOrEqual80 = 0;
  infoTotal.totalLessThan80 = 0;
  infoTotal.totalRouteBldOnly = 0;
  infoTotal.notCategorizedDelivered = 0;
  infoTotal.notCategorizedFailures = 0;
  infoTotal.totalNotCategorized = 0;
  selectedServiceCenter.vehiclesTotal = vehicles.value.length;
  selectedServiceCenter.vehiclesTravelling =
    selectedServiceCenter.vehiclesTotal - vehiclesNotTravelling.length;

  routes.value.meliRoutes.map((meliRoute) => {
    infoTotal.totalRoutesMeli += 1;
    if (meliRoute.serviceCenterId === filterRoutes.serviceCenter.id) {
      routesFiltered.value.push({
        ...meliRoute,
        vehicleStatus: 3,
        statusMelixBld: false,
      });
      meliRoute.totalPackagesMeli =
        meliRoute.totalCommercial + meliRoute.totalResidential;
      if (meliRoute.totalCommercial + meliRoute.totalResidential >= 80) {
        infoTotal.totalMoreOrEqual80 += 1;
      } else {
        infoTotal.totalLessThan80 += 1;
      }
      if (meliRoute.totalNotCategorized !== undefined) {
        selectedServiceCenter.notCategorizedDelivered +=
          meliRoute.notCategorizedDelivered;
        selectedServiceCenter.notCategorizedFailures +=
          meliRoute.notCategorizedFailures;
        selectedServiceCenter.totalNotCategorized +=
          meliRoute.totalNotCategorized;
      }
      selectedServiceCenter.totalCommercial += meliRoute.totalCommercial;
      selectedServiceCenter.commercialDelivered +=
        meliRoute.commercialDelivered;
      selectedServiceCenter.commercialFailures += meliRoute.commercialFailures;
      selectedServiceCenter.totalResidential += meliRoute.totalResidential;
      selectedServiceCenter.residentialDelivered +=
        meliRoute.residentialDelivered;
      selectedServiceCenter.residentialFailures +=
        meliRoute.residentialFailures;
    }
  });

  routes.value.routes.map((route) => {
    infoTotal.totalRoutesBld += 1;
    if (route.meliData) {
      routesFiltered.value.push({
        ...route.meliData,
        vehicleStatus:
          route.plate.replaceAll('-', '') === route.meliData.plate ? '1' : '2',
        statusMelixBld: true,
      });
      infoTotal.totalRoutesMeli += 1;
      infoTotal.totalVerified += 1;
      route.meliData.totalPackagesMeli =
        route.meliData.totalCommercial + route.meliData.totalResidential;

      if (
        route.meliData.totalCommercial + route.meliData.totalResidential >=
        80
      ) {
        infoTotal.totalMoreOrEqual80 += 1;
      } else {
        infoTotal.totalLessThan80 += 1;
      }

      if (route.meliData.totalNotCategorized !== undefined) {
        selectedServiceCenter.notCategorizedDelivered +=
          route.meliData.notCategorizedDelivered;
        selectedServiceCenter.notCategorizedFailures +=
          route.meliData.notCategorizedFailures;
        selectedServiceCenter.totalNotCategorized +=
          route.meliData.totalNotCategorized;
      }

      selectedServiceCenter.totalCommercial += route.meliData.totalCommercial;
      selectedServiceCenter.commercialDelivered +=
        route.meliData.commercialDelivered;

      selectedServiceCenter.commercialFailures +=
        route.meliData.commercialFailures;
      selectedServiceCenter.totalResidential += route.meliData.totalResidential;

      selectedServiceCenter.residentialDelivered +=
        route.meliData.residentialDelivered;

      selectedServiceCenter.residentialFailures +=
        route.meliData.residentialFailures;
    } else {
      infoTotal.totalRouteBldOnly += 1;
    }
  });

  infoTotal.totalNotVerified =
    infoTotal.totalRoutesMeli - infoTotal.totalVerified;

  weatherFiltered.value = [];

  if (!filterRoutes.serviceCenter.id) {
    weatherFiltered.value = serviceCentersWeather.value;
  } else {
    for (let i = 0; i < serviceCentersWeather.value.length; i += 1) {
      if (serviceCentersWeather.value[i].id === filterRoutes.serviceCenter.id) {
        weatherFiltered.value.push(serviceCentersWeather.value[i]);
      }
    }
  }

  keyChange.refreshCrawler += 1;
  keyChange.refreshSelected += 1;
  keyChange.refreshWeather += 1;

  lastUpdatedAt.value =
    routesFiltered.value.length > 0
      ? new Date(routesFiltered.value[0].lastUpdatedAt)
      : new Date();

  await drawRoutesHistory(routesFiltered.value);
  if (filterRoutes.serviceCenter.id === '') {
    calculateAll();
  }

  loading.value = false;
}

function calculateAll() {
  totals.value = [];

  totalAll.value.delivered = 0;
  totalAll.value.commercialDelivered = 0;
  totalAll.value.residentialDelivered = 0;
  totalAll.value.failures = 0;
  totalAll.value.commercialFailures = 0;
  totalAll.value.residentialFailures = 0;
  totalAll.value.left = 0;
  totalAll.value.total = 0;
  totalAll.value.totalCommercial = 0;
  totalAll.value.totalResidential = 0;
  totalAll.value.verified = 0;
  totalAll.value.notVerified = 0;
  totalAll.value.totalNotCategorized = 0;
  totalAll.value.notCategorizedDelivered = 0;
  totalAll.value.notCategorizedFailures = 0;

  routes.value.routes.map((route) => {
    let temp = {
      name: route.serviceCenter,
      totalCommercial: 0,
      commercialDelivered: 0,
      commercialFailures: 0,
      totalResidential: 0,
      residentialDelivered: 0,
      residentialFailures: 0,
      totalNotVerified: 0,
      totalVerified: 0,
      totalNotCategorized: 0,
      notCategorizedDelivered: 0,
      notCategorizedFailures: 0,
    };

    if (route.abbreviation !== '') {
      if (route.meliData) {
        temp.totalCommercial += route.meliData.totalCommercial;
        temp.commercialDelivered += route.meliData.commercialDelivered;
        temp.commercialFailures += route.meliData.commercialFailures;
        temp.totalResidential += route.meliData.totalResidential;
        temp.residentialDelivered += route.meliData.residentialDelivered;
        temp.residentialFailures += route.meliData.residentialFailures;
        if (route.meliData.totalNotCategorized !== undefined) {
          temp.totalNotCategorized += route.meliData.totalNotCategorized;
          temp.notCategorizedDelivered +=
            route.meliData.notCategorizedDelivered;
          temp.notCategorizedFailures += route.meliData.notCategorizedFailures;
        }
        temp.totalVerified += 1;
        totals.value.push(temp);
      } else {
        let temp = {
          name: route.serviceCenter,
          totalCommercial: 0,
          commercialDelivered: 0,
          commercialFailures: 0,
          totalResidential: 0,
          residentialDelivered: 0,
          residentialFailures: 0,
          totalNotVerified: 0,
          totalVerified: 0,
          totalNotCategorized: 0,
          notCategorizedDelivered: 0,
          notCategorizedFailures: 0,
        };
        temp.totalNotVerified += 1;
        totals.value.push(temp);
      }
    }
  });

  routes.value.meliRoutes.forEach((meliRoute) => {
    let temp = {
      name: meliRoute.serviceCenter,
      totalCommercial: 0,
      commercialDelivered: 0,
      commercialFailures: 0,
      totalResidential: 0,
      residentialDelivered: 0,
      residentialFailures: 0,
      totalNotVerified: 0,
      totalVerified: 0,
      totalNotCategorized: 0,
      notCategorizedDelivered: 0,
      notCategorizedFailures: 0,
    };

    temp.totalCommercial += meliRoute.totalCommercial;
    temp.commercialDelivered += meliRoute.commercialDelivered;
    temp.commercialFailures += meliRoute.commercialFailures;
    temp.totalResidential += meliRoute.totalResidential;
    temp.residentialDelivered += meliRoute.residentialDelivered;
    temp.residentialFailures += meliRoute.residentialFailures;
    if (meliRoute.totalNotCategorized !== undefined) {
      temp.totalNotCategorized += meliRoute.totalNotCategorized;
      temp.notCategorizedDelivered += meliRoute.notCategorizedDelivered;
      temp.notCategorizedFailures += meliRoute.notCategorizedFailures;
    }
    temp.totalNotVerified += 1;
    totals.value.push(temp);
  });

  totals.value.forEach((total) => {
    totalAll.value.total += total.totalCommercial + total.totalResidential;
    totalAll.value.delivered +=
      total.commercialDelivered + total.residentialDelivered;

    totalAll.value.failures +=
      total.commercialFailures + total.residentialFailures;

    totalAll.value.failures +=
      total.commercialFailures + total.residentialFailures;

    totalAll.value.commercialDelivered += total.commercialDelivered;
    totalAll.value.residentialDelivered += total.residentialDelivered;
    totalAll.value.commercialFailures += total.commercialFailures;
    totalAll.value.residentialFailures += total.residentialFailures;
    totalAll.value.totalCommercial += total.totalCommercial;
    totalAll.value.totalResidential += total.totalResidential;
    totalAll.value.totalNotCategorized += total.totalNotCategorized;

    totalAll.value.verified += total.totalVerified;
    totalAll.value.notVerified += total.totalNotVerified;
  });

  totalAll.value.left =
    totalAll.value.total - (totalAll.value.delivered + totalAll.value.failures);
  keyChange.refreshSelected += 1;
}

async function drawRoutesHistory(history) {
  loading.value = true;

  let meliRoutes = history;
  let bldRoutesTemp = routes.value.routes;
  const MINUTES = 60;

  meliRoutes = groupByMinute(meliRoutes, MINUTES);
  bldRoutesTemp = groupByMinute(bldRoutesTemp, MINUTES);

  let sum = 0;
  for (let item of bldRoutesTemp) {
    sum += item.routes.length;
    item.totalRoutes = sum;
  }

  let sumMeli = 0;
  for (let item of meliRoutes) {
    sumMeli += item.routes.length;
    item.totalRoutes = sumMeli;
  }

  let ctx = document.getElementById('routesHistory').getContext('2d');
  let data = [];
  let options = {};

  if (routesHistoryChart.value) {
    routesHistoryChart.value.destroy();
    routesHistoryChart.value = null;
  }

  let labels = [];
  for (let item of meliRoutes) {
    labels.push(getHoursAndMinutesFromTimestamp(item.initialTimestamp));
  }

  ctx.height = 400;

  data = {
    datasets: [
      {
        label: 'MELI',
        data: getChartDataFromRoutes(meliRoutes),
        backgroundColor: 'rgba(255, 193, 7, 1)',
        borderColor: 'rgba(255, 193, 7, 1)',
      },
      {
        label: 'BLD',
        data: getChartDataFromRoutes(bldRoutesTemp),
        backgroundColor: 'rgba(242, 99, 33, 1)',
        borderColor: 'rgba(242, 99, 33, 1)',
      },
    ],
    labels: labels,
  };

  options = {
    title: {
      display: true,
      fontSize: 16,
      text: 'Evolução das rotas do MELI e BLD',
    },
    scales: {},
    responsive: true,
    maintainAspectRatio: false,
  };

  routesHistoryChart.value = new Chart(ctx, {
    type: 'line',
    data: data,
    options: options,
  });

  loading.value = false;
}

function getChartDataFromRoutes(routes) {
  let data = [];
  for (let i = 0; i < routes.length; i += 1) {
    if (routes[i].totalRoutes) {
      data.push(routes[i].totalRoutes);
    } else {
      if (routes[i].routes.length > 0) {
        data.push(routes[i].routes.length);
      } else {
        data.push(0);
      }
    }
  }

  return data;
}

function getHoursAndMinutesFromTimestamp(timestamp) {
  timestamp = new Date(timestamp);
  let hours = timestamp.getHours();
  let minutes = timestamp.getMinutes();

  if (hours < 10) {
    hours = `0${hours}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${hours}:${minutes}`;
}

function groupByMinute(routes, minute) {
  const today = date.getToday();
  // eslint-disable-next-line prefer-template
  const globalInitialTimestamp = new Date(today + ' 00:00').getTime();
  let timestampList = [];

  for (let i = 0; i < (24 * 60) / minute; i += 1) {
    let initialTimestamp = globalInitialTimestamp + 1000 * 60 * minute * i;
    timestampList.push({
      initialTimestamp: initialTimestamp,
      finalTimestamp: initialTimestamp + 1000 * 60 * minute,
      routes: [],
    });
  }

  for (let i = 0; i < routes.length; i += 1) {
    for (let j = 0; j < timestampList.length; j += 1) {
      let timestamp;
      if (routes[i].initialHour) {
        timestamp = new Date(
          `${routes[i].initialDate} ${routes[i].initialHour}`,
        ).getTime();
      } else {
        timestamp = new Date(routes[i].createdAt).getTime();
      }

      if (
        timestamp >= timestampList[j].initialTimestamp &&
        timestamp < timestampList[j].finalTimestamp
      ) {
        timestampList[j].routes.push(routes[i]);
      }
    }
  }

  for (let i = 0; i < timestampList.length; i += 1) {
    timestampList[i].routes = deleteRepeatedRoutes(timestampList[i].routes);
  }

  return timestampList;
}

function deleteRepeatedRoutes(routes) {
  let result = [];

  for (let i = 0; i < routes.length; i += 1) {
    if (!routes[i].ignore) {
      result.push(routes[i]);
    }

    for (let j = i + 1; j < routes.length; j += 1) {
      if (routes[i].routeId == routes[j].routeId) {
        routes[j].ignore = true;
      }
    }
  }

  return result;
}

function calculatePercentDeliveries(total, delivered) {
  if (total == 0) return 0;
  let percent = delivered / total;
  if (percent > 100) {
    return 100;
  }
  return parseFloat(percent);
}

function exportCsv() {
  let verifiedData = [];
  for (let i = 0; i < routesFiltered.value.length; i += 1) {
    let tempVerified = {};

    tempVerified['ID da Rota'] = routesFiltered.value[i].routeId;

    let verifiedTotal = `Subiu (${infoTotal.totalVerified}/${routesFiltered.value.length})`;
    tempVerified[verifiedTotal] = routesFiltered.value[i].verified
      ? 'Sim'
      : 'Nao';

    tempVerified['% Todos Pacotes'] = (
      calculatePercentDeliveries(
        routesFiltered.value[i].totalResidential +
          routesFiltered.value[i].totalCommercial,
        routesFiltered.value[i].residentialDelivered +
          routesFiltered.value[i].residentialFailures +
          routesFiltered.value[i].commercialDelivered +
          routesFiltered.value[i].commercialFailures,
      ) * 100
    ).toFixed(1);

    tempVerified['Comercial Entregues'] =
      routesFiltered.value[i].commercialDelivered;
    tempVerified['Comercial Falhas'] =
      routesFiltered.value[i].commercialFailures;
    tempVerified['Residencial Entregues'] =
      routesFiltered.value[i].residentialDelivered;
    tempVerified['Residencial Falhas'] =
      routesFiltered.value[i].residentialFailures;

    tempVerified['Faltam'] =
      routesFiltered.value[i].totalResidential +
      routesFiltered.value[i].totalCommercial -
      routesFiltered.value[i].residentialDelivered -
      routesFiltered.value[i].residentialFailures -
      routesFiltered.value[i].commercialDelivered -
      routesFiltered.value[i].commercialFailures;
    tempVerified['Total'] =
      routesFiltered.value[i].totalResidential +
      routesFiltered.value[i].totalCommercial;
    verifiedData.push(tempVerified);
  }
  if (routesFiltered.value.length > 0) {
    let filename = `confrontos (${routesFiltered.value[0].serviceCenter}).csv`;
    jsonConvertor.JSONToCSVConvertor(verifiedData, 1, filename);
  }
}

function exportAllCsv() {
  let allData = [];

  calculateAll();

  for (let i = 0; i < totals.value.length; i += 1) {
    let tempData = {};
    tempData['Base'] = routes.value.serviceCenters[i].name;

    let verifiedTitle = `Subiu (${totalAll.value.verified}/${
      totalAll.value.verified + totalAll.value.notVerified
    })`;
    tempData[verifiedTitle] = `${totals.value[i].totalVerified}/${
      totals.value[i].totalNotVerified + totals.value[i].totalVerified
    }`;

    tempData['% Todos Pacotes'] = (
      calculatePercentDeliveries(
        totals.value[i].totalResidential + totals.value[i].totalCommercial,
        totals.value[i].residentialDelivered +
          totals.value[i].residentialFailures +
          totals.value[i].commercialDelivered +
          totals.value[i].commercialFailures,
      ) * 100
    ).toFixed(1);

    let commercialDeliveredTitle = `Comercial Entregues (${totalAll.value.commercialDelivered})`;
    tempData[commercialDeliveredTitle] = totals.value[i].commercialDelivered;

    let commercialFailuresTitle = `Comercial Falhas (${totalAll.value.commercialFailures})`;
    tempData[commercialFailuresTitle] = totals.value[i].commercialFailures;

    let residentialDeliveredTitle = `Residencial Entregues (${totalAll.value.residentialDelivered})`;
    tempData[residentialDeliveredTitle] = totals.value[i].residentialDelivered;

    let residentialFailuresTitle = `Residencial Falhas (${totalAll.value.residentialFailures})`;
    tempData[residentialFailuresTitle] = totals.value[i].residentialFailures;

    let leftTitle = `Faltam (${totalAll.value.left})`;
    tempData[leftTitle] =
      totals.value[i].totalResidential +
      totals.value[i].totalCommercial -
      (totals.value[i].residentialDelivered +
        totals.value[i].residentialFailures +
        totals.value[i].commercialDelivered +
        totals.value[i].commercialFailures);

    let totalPackagesTitle = `Total Pacotes (${totalAll.value.total})`;
    tempData[totalPackagesTitle] =
      totals.value[i].totalCommercial + totals.value[i].totalResidential;

    allData.push(tempData);
  }

  jsonConvertor.JSONToCSVConvertor(allData, 1, 'todos_confrontos.csv');
}
</script>

<style scoped>
.tabcontent {
  float: right !important;
  padding: 0px 12px;
  border-left: none;
  width: 100%;
  transition: width 0.5s;
  /* min-height: 100vh; */
}

.total-verified {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: white;
}

.total-card,
.weather-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: white;
}
.total-card {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.weather-card {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media (max-width: 500px) {
  .routeList li {
    flex-direction: column;
  } /* Show 2 logos per row on medium devices (tablets, phones in landscape) */
}

@media only screen and (max-device-width: 768px) {
  .tabcontent {
    width: 100%;
  }
}
.nav-pills {
  border-bottom: 2px solid #bbb;
}
.nav-pills .nav-item {
  margin-bottom: -2px;
}
.nav-pills .nav-link {
  color: #bbb;
  font-weight: bold;
  border-radius: 0px;
}
.nav-pills .nav-link.active {
  background-color: transparent;
  color: #f26321;
  border-bottom: 2px solid #f26321;
}
.modal {
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
}

#table-routes {
  overflow-x: auto;
}

#table-routes table {
  min-width: 1200px;
  overflow-x: auto;
}
</style>
