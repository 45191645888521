export default {
  PLUGIN_PRO_FROTAS: 1,
  PLUGIN_MELI: 2,

  DRIVER_ACCOUNT_TYPE_PRIMARY: 1,
  DRIVER_ACCOUNT_TYPE_SECONDARY: 2,

  ACCOUNT_TYPE_MANAGER: 1,
  ACCOUNT_TYPE_DRIVER: 3,
  ACCOUNT_TYPE_CUSTOMER: 4,
  ACCOUNT_TYPE_OWNER: 5,
  ACCOUNT_TYPE_PARTNER: 6,
  ACCOUNT_TYPE_SECONDARY_DRIVER: 7,

  ACCOUNT_STATUS_PENDING: 1,
  ACCOUNT_STATUS_APPROVED: 2,
  ACCOUNT_STATUS_DISAPPROVED: 3,
  ACCOUNT_STATUS_BLOCKED: 4,
  ACCOUNT_STATUS_INACTIVE: 5,

  ROUTE_TYPE_ROUTE: '1',
  ROUTE_TYPE_BACKUP: '2',

  VEHICLE_PENDING: 1,
  VEHICLE_APPROVED: 2,
  VEHICLE_DISAPPROVED: 3,

  DELIVERY_STATUS_PROGRESS: 1,
  DELIVERY_STATUS_FINISHED: 2,
  DELIVERY_STATUS_PENDING: 3,

  TYPE_IMAGE: 'image',
  TYPE_VIDEO: 'video',
  TYPE_DOCUMENT: 'document',

  ANTICIPATION_MIN_AMOUNT: 100,

  COST_CENTER_DAILY_MAX: 300,
  COST_CENTER_PER_KM_MAX: 1,
  COST_CENTER_BONUS_MAX: 100,
  COST_CENTER_BONUS_PER_PACKAGE_MAX: 1,

  LINK_ANDROID_APP:
    'https://play.google.com/store/apps/details?id=br.com.bldlogistica.mobile',
  LINK_IOS_APP:
    'https://play.google.com/store/apps/details?id=br.com.bldlogistica.mobile',

  WEATHERS_CODES: [
    'Tempestade forte',
    'Tempestade tropical',
    'Furacão',
    'Tempestades severas',
    'Tempestades',
    'Misto de neve e chuva',
    'Misto chuva e gelo',
    'Misto neve e gelo',
    'Geada fina',
    'Chuviscos',
    'Congelamento chuva',
    'Alguns chuviscos',
    'Alguns chuviscos',
    'Neve baixa',
    'Tempestade com neve',
    'Ventania com neve',
    'Neve',
    'Granizo',
    'Gelo',
    'Poeira',
    'Neblina',
    'Tempestade de areia',
    'Fumacento',
    'Vento acentuado',
    'Ventania',
    'Tempo frio',
    'Tempo nublado',
    'Tempo limpo',
    'Tempo nublado',
    'Parcialmente nublado',
    'Parcialmente nublado',
    'Tempo limpo',
    'Ensolarado',
    'Estrelado',
    'Ensolarado com muitas nuvens',
    'Misto chuva e granizo',
    'Ar quente',
    'Tempestades isoladas',
    'Trovoadas dispersas',
    'Trovoadas dispersas',
    'Chuvas esparsas',
    'Pesados neve',
    'Chuviscos com neve',
    'Neve pesada',
    'Sol com poucas nuvens',
    'Chuva',
    'Queda de neve',
    'Tempestades isoladas',
    'Serviço não disponível',
  ],
};
