<script setup>
import CircularProgress from './CircularProgress';
import VueElementLoading from 'vue-element-loading';
import { defineProps } from 'vue';

const props = defineProps({
  title: { type: String, required: true },
  value: { type: Object, required: true },
  delivered: { type: Number, required: true },
  fails: { type: Number, required: true },
  toBeDelivered: { type: Number, required: true },
  loading: { type: Boolean, required: true },
});

function calculatePercentDeliveries(total, delivered) {
  if (total == 0) return 0;
  let percent = delivered / total;
  if (percent > 100) {
    return 100;
  }
  return parseFloat(percent);
}
</script>

<template>
  <div class="border rounded d-block p-3">
    <VueElementLoading
      :active="loading"
      spinner="spinner"
      color="#FF6700"
      size="60"
    />
    <p class="fw-bold">{{ props.title }}</p>
    <div class="d-inline-block align-top col-3">
      <CircularProgress
        :options="{
          color: 'success',
          value: calculatePercentDeliveries(
            props.value.total,
            props.value.delivered,
          ),
        }"
      />
    </div>
    <div class="d-inline-block align-top col-9">
      <small class="d-block">
        {{ props.delivered }} entregues | {{ props.fails }} falhas
      </small>
      <small class="d-block text-secondary">
        Faltam {{ props.toBeDelivered }}
      </small>
    </div>
  </div>
</template>
