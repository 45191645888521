<template>
  <main>
    <div class="container" style="margin-top: 100px">
      <div class="row">
        <div class="col-sm-12 col-md-6 align-self-center">
          <img
            id="notfoundgif"
            src="/assets/img/notfound3.gif"
            alt="not-found"
          />
        </div>
        <div id="main-text" class="col-sm-12 col-md-6 align-self-center">
          <h1 style="font-size: 7.5em; margin: 15px 0px; font-weight: bold">
            4
            <span class="text-orange">0</span>
            4
          </h1>
          <h2 class="font-bold">UH OH! Você está perdido.</h2>
          <p>
            A página que você procura não existe. Como você chegou aqui é um
            mistério. Mas você pode clicar no botâo abaixo e tentar realizar
            login novamente.
          </p>
          <div class="d-flex flex-row align-items-center justify-content-start">
            <button class="btn btn-sm btn-orange">
              <a
                :href="
                  location.includes('staging')
                    ? 'https://stagingloggers.com.br/login'
                    : 'https://loggers.com.br/login'
                "
                class="text-decoration-none text-white"
              >
                Login
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref } from 'vue';

const location = ref(window.location.href);
</script>

<style scoped>
.svg-astroner {
  width: 100%;
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  #notfoundgif {
    width: 100%;
  }
}
</style>
