import { api } from '@/api';
import errors from '@/utils/errors';

class PluginService {
  async indexPlugins({ pluginInfo }) {
    try {
      const { data, status } = await api.get(
        `/partners/${sessionStorage.getItem('partnerId')}/plugins`,
        {
          params: {
            pluginInfo,
          },

          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar os plugins.');
    }
  }
}

export { PluginService };
