const Upload = {
  upload(event) {
    let input = event.target;
    if (input.files && input.files[0]) {
      let reader = new FileReader();

      reader.readAsDataURL(input.files[0]);
    }
  },

  getIconFromFile(filename) {
    let extension = filename.substring(filename.lastIndexOf('.') + 1);

    if (extension == 'pdf') {
      return '/assets/icons/files/pdf.svg';
    } else if (extension == 'xlsx' || extension == 'xls') {
      return '/assets/icons/files/xls.svg';
    } else if (extension == 'png') {
      return '/assets/icons/files/png.svg';
    } else if (extension == 'mp3') {
      return '/assets/icons/files/mp3.svg';
    } else if (extension == 'mov') {
      return '/assets/icons/files/mov.svg';
    } else if (extension == 'jpg' || extension == 'jpeg') {
      return '/assets/icons/files/jpg.svg';
    } else {
      return '/assets/icons/files/doc.svg';
    }
  },
};

export default Upload;
