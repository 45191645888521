<template>
  <section class="flex-container bg-white rounded p-3 mb-3 mx-3">
    <VueElementLoading
      :active="loading"
      spinner="spinner"
      color="#FF6700"
      size="60"
    />

    <div class="d-block">
      <div class="d-inline-block form-inline pb-3">
        <a
          href="#"
          class="btn btn-sm btn-orange me-2"
          data-bs-toggle="modal"
          data-bs-target="#modal-new-incident"
        >
          <font-awesome-icon icon="fa-regular fa-plus" />
          Registrar ocorrência
        </a>
      </div>

      <div v-if="state.isManager" class="d-inline-block float-end">
        <div class="dropdown">
          <a
            class="btn btn-sm btn-orange dropdown-toggle me-auto ms-2"
            data-bs-boundary="viewport"
            id="dropdownActions"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <font-awesome-icon icon="fa-regular fa-filter" />
            {{ state.serviceCenterFilterText }}
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            style="max-height: 300px; overflow-y: auto"
            aria-labelledby="dropdownActions"
          >
            <a class="dropdown-item text-dark p-3" @click="filterIncidents(0)">
              Todos
            </a>
            <a
              class="dropdown-item text-dark p-3"
              v-for="serviceCenter in serviceCenters"
              :key="'serviceCenterFilterList-' + serviceCenter.id"
              @click="filterIncidents(serviceCenter.id)"
              role="button"
            >
              {{ serviceCenter.serviceCenter }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <p v-if="incidents.length == 0" class="text-center p-5 mb-4">
        Nenhuma ocorrência.
      </p>

      <div v-else class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th
                v-if="state.isManager"
                scope="col border-bottom-0"
                style="min-width: 160px"
              >
                Envolvidos
              </th>
              <th v-if="!state.isManager" scope="col border-bottom-0"></th>
              <th v-if="!state.isManager" scope="col border-bottom-0">
                Responsável
              </th>
              <th scope="col border-bottom-0">Status</th>
              <th scope="col border-bottom-0">ID da Rota</th>
              <th scope="col border-bottom-0">Criada em</th>
              <th v-if="state.isManager" scope="col border-bottom-0">Base</th>
              <th scope="col border-bottom-0">Título</th>
              <th scoppe="col border-bottom-0">Data última mensagem</th>
              <th scope="col border-bottom-0">Ações</th>
            </tr>
          </thead>
          <tbody class="border-top-0" :key="keyChange.refreshIncidents">
            <tr
              class="align-middle"
              v-for="(incident, index) in incidents"
              :key="incident.id"
            >
              <td v-if="state.isManager">
                <span>
                  <div class="row text-center">
                    <div
                      class="col-md-5"
                      style="margin: 0 !important; padding: 0 !important"
                    >
                      <span v-if="incident.responsibleAccountName">
                        <figure
                          v-if="incident.responsibleAccountName"
                          class="m-0"
                        >
                          <img
                            v-if="incident.responsibleAccountAvatar != ''"
                            class="thumbnail-logo rounded shadow m-auto"
                            style="width: 24px; height: 24px"
                            :src="incident.responsibleAccountAvatar"
                          />
                          <img
                            v-else
                            id="img-logo"
                            class="thumbnail-logo roundedshadow m-auto"
                            style="width: 24px; height: 24px"
                            src="/assets/img/unknow.png"
                          />
                        </figure>
                        <div
                          class="mt-1"
                          :title="incident.responsibleAccountName"
                        >
                          {{
                            filters.firstNameAndFirstSurname(
                              incident.responsibleAccountName,
                            )
                          }}
                        </div>
                      </span>
                      <span v-else>
                        <span
                          class="badge bg-orange bg-action rounded-circle"
                          title="Adicionar responsável"
                          @click="viewAddResponsible(index)"
                        >
                          <font-awesome-icon icon="fa-regular fa-plus" />
                        </span>
                        <br />
                        <span
                          class="badge bg-orange mt-1"
                          @click="viewAddResponsible(index)"
                          style="cursor: pointer"
                        >
                          Adicionar
                        </span>
                      </span>
                    </div>
                    <div
                      class="col-md-1 arrowMiddle"
                      style="margin: 0 !important; padding: 0 !important"
                    >
                      <font-awesome-icon icon="fa-regular fa-arrow-right" />
                    </div>
                    <div
                      class="col-md-6"
                      style="margin: 0 !important; padding: 0 !important"
                    >
                      <figure class="m-0">
                        <img
                          v-if="
                            incident.avatar != '' && incident.avatar != null
                          "
                          class="thumbnail-logo rounded shadow m-auto"
                          style="width: 24px; height: 24px"
                          :src="incident.avatar"
                        />
                        <img
                          v-else
                          id="img-logo"
                          class="thumbnail-logo rounded shadow m-auto"
                          style="width: 24px; height: 24px"
                          src="/assets/img/unknow.png"
                        />
                      </figure>
                      <div class="mt-1" :title="incident.accountName">
                        {{
                          filters.firstNameAndFirstSurname(incident.accountName)
                        }}
                      </div>
                    </div>
                  </div>
                </span>
              </td>
              <td v-if="!state.isManager">
                <figure v-if="incident.responsibleAccountName" class="m-0">
                  <img
                    v-if="incident.responsibleAccountAvatar != ''"
                    class="thumbnail-logo rounded shadow m-auto"
                    style="width: 24px; height: 24px"
                    :src="incident.responsibleAccountAvatar"
                  />
                  <img
                    v-else
                    id="img-logo"
                    class="thumbnail-logo rounded shadow m-auto"
                    style="width: 24px; height: 24px"
                    src="/assets/img/unknow.png"
                  />
                </figure>
              </td>
              <td v-if="!state.isManager">
                <span v-if="incident.responsibleAccountName">
                  {{ incident.responsibleAccountName }}
                </span>
                <span v-else>
                  <span class="badge bg-danger">Sem responsável</span>
                  <br />
                </span>
              </td>
              <td>
                <span v-if="incident.statusId == 3" class="badge bg-success">
                  Resolvido
                </span>
                <span v-if="incident.statusId == 4" class="badge bg-danger">
                  Arquivado
                </span>
                <span v-if="incident.statusId == 1" class="badge bg-secondary">
                  Em aberto
                </span>
                <span v-if="incident.statusId == 2" class="badge bg-secondary">
                  Aguardando
                </span>
              </td>
              <td>{{ incident.meliRouteId }}</td>
              <td>
                {{
                  filters.displayRelativeTimestamp(
                    formatIncidentDateAndTime(incident),
                  )
                }}
              </td>
              <td v-if="state.isManager">
                {{ filters.serviceCenterName(incident.serviceCenter) }}
              </td>
              <td>{{ filters.truncate(incident.title, 20) }}</td>
              <td>
                <span v-if="incident.lastMessageTimestamp != ''">
                  {{
                    filters.displayRelativeTimestamp(
                      incident.lastMessageTimestamp.replace(' ', 'T'),
                    )
                  }}
                </span>
              </td>
              <td>
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-sm btn-orange"
                    @click="viewIncidentMessages(index)"
                  >
                    <font-awesome-icon icon="fa-regular fa-comment-dots" />
                    Chat
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-orange dropdown-toggle dropdown-toggle-split"
                    id="dropdownMenuIncident"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-bs-reference="parent"
                  >
                    <span class="sr-only">Dropdown</span>
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuIncident"
                  >
                    <a
                      class="dropdown-item"
                      @click="viewIncident(index)"
                      href="#"
                    >
                      Ver detalhes
                    </a>
                    <a
                      v-if="state.isManager"
                      class="dropdown-item"
                      @click="viewIncidentStatus(index)"
                      href="#"
                    >
                      Alterar status
                    </a>
                    <a
                      v-if="
                        state.accountTypeId == globals.ACCOUNT_TYPE_MANAGER ||
                        state.accountTypeId == globals.ACCOUNT_TYPE_PARTNER
                      "
                      class="dropdown-item"
                      @click="deleteIncident(index)"
                      href="#"
                    >
                      Excluir
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="state.isManager" class="text-center">
          <a
            v-if="incidents.length > 0 && state.pageSeeMore"
            @click="listIncidentsNewPage()"
            class="btn btn-orange ms-auto me-auto mt-4"
          >
            <font-awesome-icon icon="fa-regular fa-plus" />
            Ver mais
          </a>
        </div>
      </div>
      <div v-if="state.isDriver" class="text-center">
        <button
          :disabled="state.disablePrevious"
          @click="loadPage(-1)"
          class="btn btn-orange ms-auto me-auto mt-4"
        >
          <font-awesome-icon icon="fa-regular fa-angle-left" />
          Página anterior
        </button>
        <span style="margin: 10px"></span>
        <button
          :disabled="state.disableNext"
          @click="loadPage(+1)"
          class="btn btn-orange ms-auto me-auto mt-4"
        >
          Próxima página
          <font-awesome-icon icon="fa-regular fa-angle-right" />
        </button>
      </div>
    </div>

    <div class="modals">
      <div
        class="modal modal-md fade"
        id="modal-new-incident"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content border-0">
            <div class="modal-header text-white bg-blue">
              <h5 class="modal-title">
                <font-awesome-icon icon="fa-regular fa-plus" />
                Registrar ocorrência
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Fechar"
              ></button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="newIncidentRoute">Rota:</label>
                <select
                  v-if="routes.length == 0"
                  v-model="newIncidentData.routeId"
                  class="form-select"
                  :disabled="true"
                ></select>
                <select
                  v-else
                  v-model="newIncidentData.routeId"
                  id="selector-route"
                  class="form-select"
                >
                  <option
                    v-for="route in routes"
                    :key="'routesList-' + route.id"
                    :value="route.id"
                  >
                    ID {{ route.routeId }} em
                    {{ filters.dateDisplay(route.initialDate) }} na onda
                    {{ route.wave }}
                  </option>
                </select>
                <small class="text-muted">
                  Só é possível criar ocorrências sobre rotas em andamento.
                </small>
              </div>

              <div class="form-group">
                <label for="newIncidentTitle">Título:</label>
                <input
                  v-model="newIncidentData.title"
                  type="text"
                  class="form-control"
                  id="newIncidentTitle"
                />
                <small class="text-muted">
                  Seja claro e objetivo. Se houver valores financeiros,
                  descreva-os aqui.
                </small>
              </div>

              <div class="form-group">
                <label for="newIncidentMessage">Mensagem:</label>
                <textarea
                  maxlength="2000"
                  v-model="newIncidentData.message"
                  rows="5"
                  class="form-control"
                  id="newIncidentFirstMessage"
                ></textarea>
                <small class="text-muted">
                  Esta será a primeira mensagem do histórico.
                </small>
              </div>

              <div class="form-group">
                <label>Insira alguns anexos, se desejar:</label>
                <div class="images-container">
                  <div
                    v-for="(
                      attachment, attachmentIndex
                    ) in newIncidentData.attachments"
                    :key="attachment"
                  >
                    <img
                      v-if="
                        attachment.extension === 'png' ||
                        attachment.extension === 'jpg' ||
                        attachment.extension === 'jpeg'
                      "
                      :src="attachment.attachment"
                      alt=""
                    />
                    <img
                      v-else-if="attachment.extension === 'mp4'"
                      src="/assets/img/play.png"
                      style="width: 70px; height: 70px"
                    />
                    <img
                      v-else
                      :src="attachment.icon"
                      style="width: 70px; height: 70px"
                    />
                    <button
                      @click="removeAttachmentNewIncident(attachmentIndex)"
                      type="button"
                      class="remove-button"
                    >
                      <font-awesome-icon icon="fa-regular fa-xmark" />
                    </button>
                  </div>

                  <label htmlFor="newPostAttachment" className="new-image">
                    <font-awesome-icon icon="fa-regular fa-plus" />
                  </label>
                </div>
                <input
                  class="d-none"
                  multiple
                  @change="insertAttachmentNewIncident($event)"
                  type="file"
                  id="newPostAttachment"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success"
                @click="createIncident()"
              >
                Registrar ocorrência
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="modal-new-responsible"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content border-0">
            <div class="modal-header text-white bg-orange">
              <h5 class="modal-title">
                <font-awesome-icon icon="fa-regular fa-plus" />
                Adicionar responsável
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Fechar"
              ></button>
            </div>
            <div class="modal-body">
              <form class="form-blue" v-if="state.incidentIndex != null">
                <h5 class="text-orange fw-bold">
                  Selecione um Administrador ou um Leader como responsável:
                </h5>
                <div class="form-group">
                  <label for="incidentRouteResponsible">Responsáveis:</label>
                  <select
                    id="incidentRouteResponsible"
                    class="form-select form-select-sm"
                    v-model="state.newResponsibleAccountId"
                    aria-label=".form-select-lg example"
                  >
                    <option
                      v-for="(
                        responsible, responsibleIndex
                      ) in responsiblesFiltered"
                      :value="responsible.accountId"
                      :key="responsibleIndex"
                    >
                      {{ responsible.accountName }} ({{
                        responsible.accountTypeId ==
                        globals.ACCOUNT_TYPE_MANAGER
                          ? 'Administrador'
                          : 'Leader'
                      }})
                    </option>
                  </select>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success"
                @click="addResponsible()"
              >
                <font-awesome-icon icon="fa-regular fa-check" />
                Selecionar Responsável
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal modal-md fade"
        id="modal-incident"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content border-0">
            <div class="modal-header text-white bg-blue">
              <h5 class="modal-title">
                <font-awesome-icon icon="fa-regular fa-bullhorn" />
                Detalhes ocorrência
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Fechar"
              ></button>
            </div>
            <div class="modal-body ps-5 pe-5 pt-4 pb-3">
              <form class="form-blue" v-if="state.incidentIndex != null">
                <div class="form-group">
                  <label for="incidentRoute">Rota:</label>
                  <input
                    v-model="incidents[state.incidentIndex].routeInfo"
                    :disabled="true"
                    type="text"
                    class="form-control"
                    id="incidentRoute"
                  />
                </div>

                <div class="form-group">
                  <label for="incidentTitle">Título:</label>
                  <input
                    v-model="incidents[state.incidentIndex].title"
                    type="text"
                    class="form-control"
                    :disabled="
                      state.accountId !=
                      incidents[state.incidentIndex].authorAccountId
                    "
                    id="incidentTitle"
                  />
                  <small class="text-muted">
                    Seja claro e objetivo. Se houver valores financeiros,
                    descreva-os aqui.
                  </small>
                </div>

                <div class="form-group text-end mt-4">
                  <a class="btn btn-secondary" data-bs-dismiss="modal">
                    Cancelar
                  </a>
                  <a
                    v-if="
                      state.accountId ==
                      incidents[state.incidentIndex].authorAccountId
                    "
                    class="btn btn-success ms-3"
                    @click="updateIncident()"
                  >
                    <font-awesome-icon icon="fa-regular fa-check" />
                    Salvar alterações
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="modal-chat"
        tabindex="-1"
        aria-labelledby="modalChatLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header bg-blue text-white">
              <h5 class="modal-title" id="modalChatLabel">
                <font-awesome-icon icon="fa-regular fa-comment-dots" />
                Chat
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              class="modal-body p-3 container-message"
              v-if="state.incidentIndex != null"
              :key="keyChange.refreshMessages"
            >
              <span
                v-if="incidents[state.incidentIndex].messages.length == 0"
                class="d-block text-center text-grey pt-4 pb-4"
              >
                As mensagens aparecerão aqui.
              </span>
              <ul
                class="list-unstyled mb-1 pe-1 ps-1"
                v-else
                style="
                  max-height: 60vh;
                  overflow-y: auto;
                  overflow-x: hidden;
                  display: flex;
                  flex-direction: column-reverse;
                "
              >
                <li
                  v-for="(message, indexMessage) of incidents[
                    state.incidentIndex
                  ].messages"
                  :key="indexMessage"
                  class=""
                >
                  <div
                    :class="
                      'message-baloon ' +
                      (message.isMyMessage ? 'message-right' : 'message-left') +
                      ' p-3 '
                    "
                    style="position: relative"
                  >
                    <span
                      v-if="
                        indexMessage == 0
                          ? true
                          : incidents[state.incidentIndex].messages[
                              indexMessage - 1
                            ].accountName !=
                            incidents[state.incidentIndex].messages[
                              indexMessage
                            ].accountName
                      "
                    >
                      <figure
                        class="m-0"
                        :style="
                          '  position:absolute; bottom: -5px;' +
                          (message.isMyMessage ? 'right: -3px;' : 'left: -3px;')
                        "
                      >
                        <img
                          v-if="message.avatar != '' && message.avatar != null"
                          class="thumbnail-logo rounded-circle shadow m-auto"
                          style="
                            max-width: 15px;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                          "
                          :src="message.avatar"
                          :title="message.accountName"
                        />
                        <img
                          v-else
                          id="img-logo"
                          class="thumbnail-logo rounded-circle shadow m-auto"
                          style="
                            max-width: 15px;
                            border: 1px solid rgba(0, 0, 0, 0.3);
                          "
                          src="/assets/img/unknow.png"
                          :title="message.accountName"
                        />
                      </figure>
                    </span>
                    <div v-if="message.message && message.message != ''">
                      {{ message.message }}
                    </div>
                    <div
                      v-for="(
                        attachment, indexAttachment
                      ) of message.attachments"
                      :key="indexAttachment"
                      class="btn text-dark border-0"
                    >
                      <div v-if="attachment.type == globals.TYPE_IMAGE">
                        <a @click="openLightboxImage(indexMessage)">
                          <figure>
                            <img
                              :class="
                                'border-0 attachment-' +
                                indexMessage +
                                '-' +
                                indexAttachment +
                                ' w-100'
                              "
                              :src="attachment.attachment"
                            />
                          </figure>
                        </a>
                      </div>

                      <div v-else-if="attachment.type == globals.TYPE_VIDEO">
                        <a @click="playVideo(attachment)">
                          <figure>
                            <img
                              src="/assets/img/play.jpg"
                              style="width: 100%"
                            />
                          </figure>
                        </a>
                      </div>

                      <div v-else class="p-4 text-center">
                        <figure>
                          <img
                            :src="attachment.icon"
                            style="width: 70px; height: 70px"
                            class="border-0"
                          />
                        </figure>
                        <a
                          class="btn btn-sm btn-success text-white p-1"
                          @click="downloadFile(attachment)"
                        >
                          <font-awesome-icon icon="fa-regular fa-download" />
                          Download
                        </a>
                      </div>
                    </div>
                    <small
                      v-if="
                        message.id == null ||
                        message.date == null ||
                        message.time == null
                      "
                      class="d-block w-100"
                    >
                      <font-awesome-icon icon="fa-regular fa-clock" />
                      Enviando...
                    </small>
                    <small v-else class="d-block w-100">
                      {{
                        filters.displayRelativeTimestamp(
                          formatIncidentDateAndTime(message),
                        )
                      }}
                    </small>
                    <small
                      :class="message.isMyMessage ? 'float-end' : 'float-start'"
                      v-if="
                        indexMessage == 0
                          ? true
                          : incidents[state.incidentIndex].messages[
                              indexMessage - 1
                            ].accountName !=
                            incidents[state.incidentIndex].messages[
                              indexMessage
                            ].accountName
                      "
                    >
                      {{ message.accountName }}
                    </small>
                  </div>
                </li>
              </ul>
              <div class="input-group form-chat">
                <input
                  type="text"
                  class="form-control"
                  v-model="state.newIncidentMessage"
                  placeholder="Digite aqui..."
                />

                <input
                  type="file"
                  class="d-none"
                  id="newMessageAttachment"
                  @change="insertAttachmentIncident($event)"
                />
                <label for="newMessageAttachment">
                  <a class="btn btn-secondary rounded-0">
                    <font-awesome-icon
                      icon="fa-regular fa-paperclip"
                      class="mt-2"
                    />
                  </a>
                </label>

                <button
                  class="btn btn-blue"
                  type="button"
                  @click="createIncidentMessage()"
                >
                  <font-awesome-icon icon="fa-regular fa-paper-plane" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal modal-md fade"
        id="modal-incident-status"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content border-0">
            <div class="modal-header text-white bg-blue">
              <h5 class="modal-title">
                <font-awesome-icon icon="fa-regular fa-bullhorn" />
                Editar status
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Fechar"
              ></button>
            </div>
            <div class="modal-body ps-5 pe-5 pt-4 pb-3">
              <form class="form-blue" v-if="state.incidentIndex != null">
                <div class="form-group">
                  <label for="incidentTime">Status da ocorrência:</label>
                  <select v-model="state.updateStatusId" class="form-select">
                    <option
                      v-for="incidentsStatuses in incidentsStatuses"
                      :key="incidentsStatuses.id"
                      :value="incidentsStatuses.id"
                    >
                      {{ incidentsStatuses.status }}
                    </option>
                  </select>
                </div>

                <div class="form-group text-end mt-4">
                  <a class="btn btn-secondary" data-bs-dismiss="modal">
                    Cancelar
                  </a>
                  <a
                    class="btn btn-success ms-3"
                    @click="updateIncidentStatus()"
                  >
                    <font-awesome-icon icon="fa-regular fa-check" />
                    Salvar alterações
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <VueEasyLightbox
        :escDisabled="false"
        moveDisabled
        :visible="lightboxImage.visible"
        :imgs="lightboxImage.imgs"
        :index="0"
        @hide="closeLightboxImage"
      />

      <div
        class="modal fade"
        id="modal-video"
        tabindex="-2"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl" role="document">
          <a
            data-bs-dismiss="modal"
            class="text-white font-size-1-5"
            style="position: fixed; top: 15px; right: 30px"
          >
            <i class="fa fa-times"></i>
          </a>
          <div class="modal-content border-0 w-100">
            <div class="modal-body d-block m-auto text-center w-100 p-0">
              <video
                id="video-streaming"
                width="100%"
                controls
                autoplay
                style="background: black; max-height: 80vh"
              >
                <source id="video-streaming-source" src="" type="video/mp4" />
                Your browser does not support HTML video.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import globals from '@/utils/globals';
import Upload from '@/utils/upload';
import filters from '@/utils/vueFilters';
import { useStorage } from '@vueuse/core';
import { Modal } from 'bootstrap';
import { saveAs } from 'file-saver';
import $ from 'jquery';
import NProgress from 'nprogress';
import { onMounted, reactive, ref } from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox';
import VueElementLoading from 'vue-element-loading';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { IncidentService } from '../services/IncidentService';
import { ServiceCenterService } from '../services/ServiceCenterService';

const incidentService = new IncidentService();
const serviceCenterService = new ServiceCenterService();

const toast = useToast();
const store = useStore();

const state = reactive({
  newIncidentMessage: '',
  serviceCenterId: 0,
  serviceCenterFilterText: 'Todos',
  showLoading: true,
  lastSize: 0,
  updateStatusId: 1,
  incidentIndex: null,
  messageIndex: null,
  accountId: null,
  disableNext: false,
  disablePrevious: false,
  isManager: false,
  isDriver: false,
  newResponsibleAccountId: '',
  accountTypeId: false,
  tempRouteInfo: '',
  refreshing: null,
  refreshChat: null,
  page: 0,
  pageSeeMore: true,
});

const responsibles = ref([]);
const responsiblesFiltered = ref([]);
const routes = ref([]);
const incidentsStatuses = ref([]);
const serviceCenters = ref([]);
const incidents = ref([]);
const newIncidentMessageAttachments = ref([]);

const loading = ref(false);

const lightboxImage = reactive({
  imgs: [],
  visible: false,
});

const newIncidentData = reactive({
  statusId: '',
  routeId: '',
  title: '',
  message: '',
  attachments: [],
});

const keyChange = reactive({
  refreshIncidents: 0,
  refreshMessages: 0,

  failRefreshChat: 0,
});

const modals = reactive({
  modalNewIncident: null,
  modalIncident: null,
  modalIncidentStatus: null,
  modalChat: null,
  modalVideo: null,
  modalAddResponsible: null,
});

const preferences = useStorage(
  `@${sessionStorage.getItem('accountName')}-preferences`,
  {
    baseId: 0,
    name: '',
  },
);

onMounted(async () => {
  clearInterval(state.refreshing);
  setModals();

  configAccounts();
  await getParams();
  refreshingIncidents();
});

function loadPage(page) {
  clearInterval(state.refreshing);
  page == -1 ? (state.page -= 1) : (state.page += 1);
  refreshingIncidents();
}

async function configAccounts() {
  state.accountId = sessionStorage.getItem('accountId');
  state.accountTypeId = sessionStorage.getItem('accountTypeId');

  if (state.accountTypeId == globals.ACCOUNT_TYPE_DRIVER) {
    state.isDriver = true;
  }
  if (state.accountTypeId == globals.ACCOUNT_TYPE_MANAGER) {
    state.isManager = true;
    getResponsibles();
  }
}

function formatIncidentDateAndTime(incident) {
  return `${incident.date}T${incident.time}`;
}

function playVideo(attachment) {
  document.getElementById('video-streaming-source').src = attachment.attachment;
  document.getElementById('video-streaming').load();
  modals.modalVideo.show();
}

async function createIncidentMessage() {
  incidents.value[state.incidentIndex].messages.unshift({
    id: -1,
    message: state.newIncidentMessage,
    accountName: sessionStorage.getItem('accountName'),
    authorAccountId: sessionStorage.getItem('accountId'),
    attachments: [],
    avatar: sessionStorage.getItem('accountAvatar'),
    date: null,
    time: null,
    isMyMessage: true,
  });
  keyChange.refreshIncidents += 1;
  keyChange.refreshMessages += 1;

  const params = {
    message: state.newIncidentMessage,
  };

  const form = new FormData();
  Object.keys(params).forEach(function (key) {
    form.append(key, params[key]);
  });

  newIncidentMessageAttachments.value.forEach(function (attachment) {
    form.append('attachments', attachment.blob, attachment.name);
  });

  const response = await incidentService.createIncidentMessage({
    id: incidents.value[state.incidentIndex].id,
    form,
  });

  if (response && response.status === 201) {
    incidents.value[state.incidentIndex].messages[0].id =
      response.data.messageId;
    incidents.value[state.incidentIndex].messages[0].date = response.data.date;
    incidents.value[state.incidentIndex].messages[0].time = response.data.time;

    for (let i = 0; i < newIncidentMessageAttachments.value.length; i += 1) {
      incidents.value[state.incidentIndex].messages[0].attachments.push({
        attachment: newIncidentMessageAttachments.value[i].attachment,
        name: newIncidentMessageAttachments.value[i].name,
        type: newIncidentMessageAttachments.value[i].type,
        icon: newIncidentMessageAttachments.value[i].icon,
      });
    }

    state.newIncidentMessage = '';
    newIncidentMessageAttachments.value = [];

    keyChange.refreshIncidents += 1;
  } else {
    incidents[state.incidentIndex].messages.shift();
  }
}

async function getResponsibles() {
  NProgress.start();
  const managersResponse = await incidentService.getManagers();
  if (managersResponse && managersResponse.status === 200) {
    managersResponse.data.sort((a, b) =>
      a.accountName.localeCompare(b.accountName),
    );
  }

  for (let i = 0; i < managersResponse.data.length; i += 1) {
    managersResponse.data[i].accountTypeId = globals.ACCOUNT_TYPE_MANAGER;
  }

  responsiblesFiltered.value = responsibles.value;
  for (let j = 0; j < managersResponse.data.length; j += 1) {
    responsiblesFiltered.value.push(managersResponse.data[j]);
  }
}

function refreshingChat() {
  clearInterval(state.refreshChat);
  if ($('.modal.show').length > 0) {
    if ($('.modal.show')[0].id == 'modal-chat') {
      viewIncidentMessagesRefresh();
    }
  } else {
    keyChange.failRefreshChat += 1;
  }

  if (keyChange.failRefreshChat < 2) {
    state.refreshChat = window.setInterval(function () {
      refreshingChat();
    }, 20000);
  } else {
    clearInterval(state.refreshChat);
    keyChange.failRefreshChat = 0;
  }
}

function refreshingIncidents() {
  clearInterval(state.refreshing);
  if (!$('.modal.in, .modal.show').length) {
    listIncidents();
  }

  state.refreshing = window.setInterval(function () {
    refreshingIncidents();
  }, 30000);
}

async function insertAttachmentIncident(event) {
  const input = event.target;
  const fileName = event.target.value.substring(12);

  if (input.files && input.files[0]) {
    let reader = new FileReader();
    reader.onload = async (e) => {
      let file = e.target.result;
      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      let type = '';
      let icon = '';
      if (
        extension == 'png' ||
        extension == 'jpg' ||
        extension == 'jpeg' ||
        extension == 'webp'
      ) {
        type = globals.TYPE_IMAGE;
      } else if (extension == 'mp4') {
        type = globals.TYPE_VIDEO;
      } else {
        type = globals.TYPE_DOCUMENT;
        icon = Upload.getIconFromFile(fileName);
      }
      newIncidentMessageAttachments.value.push({
        blob: await (await fetch(file)).blob(),
        attachment: file,
        type: type,
        icon: icon,
        extension: extension,
        name: fileName,
      });
      createIncidentMessage();
    };
    reader.readAsDataURL(input.files[0]);
  }
  $('#newMessageAttachment').prop('value', '');
}

async function insertAttachmentNewIncident(event) {
  const input = event.target;

  const fileName = event.target.value.substring(12);

  if (input.files && input.files[0]) {
    let reader = new FileReader();
    reader.onload = async (e) => {
      let file = e.target.result;

      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      let type = '';
      let icon = '';
      if (
        extension == 'png' ||
        extension == 'jpg' ||
        extension == 'jpeg' ||
        extension == 'webp'
      ) {
        type = globals.TYPE_IMAGE;
      } else if (extension == 'mp4') {
        type = globals.TYPE_VIDEO;
      } else {
        type = globals.TYPE_DOCUMENT;
        icon = Upload.getIconFromFile(fileName);
      }

      newIncidentData.attachments.push({
        blob: await (await fetch(file)).blob(),
        attachment: file,
        type: type,
        icon: icon,
        extension: extension,
        name: fileName,
      });
    };
    reader.readAsDataURL(input.files[0]);
  }

  $('#newPostAttachment').prop('value', '');
}

function removeAttachmentNewIncident(attachmentIndex) {
  newIncidentData.attachments.splice(attachmentIndex, 1);
}

async function getParams() {
  NProgress.start();

  const incidentStatusesResponse = await incidentService.getIncidentStatuses();

  if (incidentStatusesResponse.status === 200) {
    incidentsStatuses.value = incidentStatusesResponse.data;
  }

  const routesCurrentResponse = await incidentService.getRoutesCurrent();
  if (routesCurrentResponse.status === 200) {
    routes.value = routesCurrentResponse.data;
  }

  const myServiceCentersResponse =
    await serviceCenterService.myServiceCenters();

  if (myServiceCentersResponse && myServiceCentersResponse.status == 200) {
    serviceCenters.value = myServiceCentersResponse.data;
  }

  if (preferences.value.baseId) {
    filterIncidents(preferences.value.baseId);
  }

  NProgress.done();
}

async function filterIncidents(serviceCenterId) {
  state.incidentIndex = null;
  state.page = 0;
  state.lastSize = 0;
  state.pageSeeMore = true;
  if (state.isManager) {
    state.serviceCenterId = serviceCenterId;
  }
  // this.showLoading = true;

  if (serviceCenterId == 0) {
    state.serviceCenterFilterText = 'Todos';
  } else {
    for (let i = 0; i < serviceCenters.value.length; i += 1) {
      if (serviceCenters.value[i].id === serviceCenterId) {
        state.serviceCenterFilterText = serviceCenters.value[i].serviceCenter;
      }
    }
  }

  listIncidents();
}

async function createIncident() {
  loading.value = true;

  const params = {
    routeId: newIncidentData.routeId,
    title: newIncidentData.title,
    message: newIncidentData.message,
  };

  const form = new FormData();
  Object.keys(params).forEach(function (key) {
    form.append(key, params[key]);
  });

  newIncidentData.attachments.forEach(function (attachment) {
    form.append('attachments', attachment.blob, attachment.name);
  });

  const response = await incidentService.createIncident({ form });

  if (response && response.status === 201) {
    toast.success('Ocorrência criada com sucesso.');

    let selected = document.getElementById('selector-route');
    state.tempRouteInfo = selected.options[selected.selectedIndex].text;

    let newRouteId = '';
    let newServiceCenter = '';
    for (let i = 0; i < routes.value.length; i += 1) {
      if (newIncidentData.routeId === routes.value[i].id) {
        newRouteId = routes.value[i].routeId;
        newServiceCenter = routes.value[i].serviceCenter;
      }
    }
    incidents.value.unshift({
      id: response.data.incidentId,
      meliRouteId: newRouteId,
      serviceCenter: newServiceCenter,
      accountName: sessionStorage.getItem('accountName'),
      title: newIncidentData.title,
      date: response.data.date,
      time: response.data.time,
      lastMessageTimestamp: `${response.data.date} ${response.data.time}`,
      authorAccountId: state.accountId,
      avatar: sessionStorage.getItem('accountAvatar'),
      statusId: 1,
      messages: [],
      routeInfo: state.tempRouteInfo,
    });
    incidents.value[0].messages.push({
      id: response.data.messageId,
      message: newIncidentData.message,
      accountName: sessionStorage.getItem('accountName'),
      authorAccountId: sessionStorage.getItem('accountId'),
      avatar: store.getters.accountAvatar,
      attachments: [],
      date: response.data.date,
      time: response.data.time,
    });

    for (let i = 0; i < newIncidentData.attachments.length; i += 1) {
      incidents.value[0].messages[0].attachments.push({
        attachment: newIncidentData.attachments[i].attachment,
        name: newIncidentData.attachments[i].name,
        type: newIncidentData.attachments[i].type,
        icon: newIncidentData.attachments[i].icon,
      });
    }

    newIncidentData.routeId = '';
    newIncidentData.title = '';
    newIncidentData.message = '';
    newIncidentData.attachments = [];

    keyChange.refreshIncidents += 1;
    loading.value = false;
    modals.modalNewIncident.hide();
  }
}

async function updateIncident() {
  loading.value = true;

  const params = {
    routeId: incidents.value[state.incidentIndex].routeId,
    title: incidents.value[state.incidentIndex].title,
  };

  const response = await incidentService.updateIncident({
    id: incidents.value[state.incidentIndex].id,
    params,
  });

  if (response && response.status === 204) {
    toast.success('Atualizado com sucesso.');
    modals.modalIncident.hide();
  }

  loading.value = false;
}

async function updateIncidentStatus() {
  loading.value = true;

  const params = {
    statusId: state.updateStatusId,
  };

  const response = await incidentService.updateIncidentStatus({
    id: incidents.value[state.incidentIndex].id,
    params,
  });

  if (response && response.status === 204) {
    incidents.value[state.incidentIndex].statusId = state.updateStatusId;
    state.updateStatusId = 1;
    toast.success('Atualizado com sucesso.');
    modals.modalIncidentStatus.hide();
  }

  loading.value = false;
}

async function listIncidentsNewPage() {
  clearInterval(state.refreshing);

  state.page += 1;
  let url = `${'admin/incidents' + '/'}${state.page}`;
  if (state.serviceCenterId != 0 && state.isManager) {
    url = `${url}?serviceCenterId=${state.serviceCenterId}`;
  }

  const response = await incidentService.listIncidentsNewPage({ url });

  if (response && response.status === 200) {
    if (response.data.length == state.lastSize) {
      state.pageSeeMore = false;
    }
    state.lastSize = response.data.length;
    incidents.value = response.data;
    refreshingIncidents();
  } else {
    state.page -= 1;
    refreshingIncidents();
  }
}

async function listIncidents() {
  NProgress.start();

  const headers =
    state.accountTypeId == globals.ACCOUNT_TYPE_DRIVER
      ? {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            new: true,
          },
        }
      : {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        };

  if (state.page <= 0) {
    state.page = 0;
  }
  let url =
    state.accountTypeId == globals.ACCOUNT_TYPE_DRIVER
      ? `admin/my-incidents/${state.accountId}/${state.page}`
      : `${'admin/incidents/'}${state.page}`;

  if (state.serviceCenterId != 0 && state.isManager) {
    url = `${url}?serviceCenterId=${state.serviceCenterId}`;
  }

  const response = await incidentService.listIncidents({
    url,
    headers,
  });

  if (response && response.status === 200) {
    if (state.isDriver) {
      if (response.data.length == 0 && state.page > 0) {
        state.disablePrevious = false;
        state.disableNext = true;
      } else if (response.data.length == 0 && state.page == 0) {
        state.disablePrevious = true;
        state.disableNext = true;
      } else if (response.data.length > 0 && state.page == 0) {
        state.disablePrevious = true;
        state.disableNext = false;
      } else if (response.data.length > 0 && state.page > 0) {
        state.disablePrevious = false;
        state.disableNext = false;
      }
      if (response.data.length < 30) {
        state.disableNext = true;
      }
    }

    incidents.value = response.data;
    keyChange.refreshIncidents += 1;
  }

  //  this.showLoading = false;
  NProgress.done();
}

async function deleteIncident(index) {
  loading.value = true;

  const response = await incidentService.deleteIncident({
    id: incidents.value[index].id,
  });

  if (response && response.status === 204) {
    incidents.value.splice(index, 1);
    keyChange.refreshIncidents += 1;
    toast.success('Ocorrência excluída com sucesso!');
  }

  loading.value = false;
}

// async function deleteIncidentMessage(index) {
//   loading.value = true;

//   const response = await incidentService.deleteIncidentMessage({
//     id: incidents.value[state.incidentIndex].messages[index].id,
//   });

//   if (response && response.status === 204) {
//     incidents.value[state.incidentIndex].messages.splice(index, 1);
//     keyChange.refreshIncidents += 1;
//     toast.success('Mensagem excluída com sucesso!');
//   }

//   loading.value = false;
// }

async function viewIncidentMessagesRefresh() {
  loading.value = true;

  const response = await incidentService.viewIncidentMessagesRefresh({
    id: incidents.value[state.incidentIndex].id,
  });
  if (response && response.status === 200) {
    response.data.map((message) => {
      if (message.attachments.length > 0) {
        message.attachments.map((attachment) => {
          let extension = attachment.name.substring(
            attachment.name.lastIndexOf('.') + 1,
          );
          if (
            extension == 'png' ||
            extension == 'jpg' ||
            extension == 'jpeg' ||
            extension == 'webp'
          ) {
            attachment.type = globals.TYPE_IMAGE;
          } else if (extension == 'mp4') {
            attachment.type = globals.TYPE_VIDEO;
          } else {
            attachment.type = globals.TYPE_DOCUMENT;
            attachment.icon = Upload.getIconFromFile(attachment.name);
          }
        });
      }
      if (message.accountName == sessionStorage.getItem('accountName')) {
        message.isMyMessage = true;
      } else {
        message.isMyMessage = false;
      }
    });
    incidents.value[state.incidentIndex].messages = response.data;
  }

  loading.value = false;
}

async function viewIncidentMessages(index) {
  state.incidentIndex = index;
  loading.value = true;

  const response = await incidentService.viewIncidentMessages({
    id: incidents.value[index].id,
  });

  if (response && response.status === 200) {
    response.data.map((message) => {
      if (message.attachments.length > 0) {
        message.attachments.map((attachment) => {
          let extension = attachment.name.substring(
            attachment.name.lastIndexOf('.') + 1,
          );
          if (
            extension == 'png' ||
            extension == 'jpg' ||
            extension == 'jpeg' ||
            extension == 'webp'
          ) {
            attachment.type = globals.TYPE_IMAGE;
          } else if (extension == 'mp4') {
            attachment.type = globals.TYPE_VIDEO;
          } else {
            attachment.type = globals.TYPE_DOCUMENT;
            attachment.icon = Upload.getIconFromFile(attachment.name);
          }
        });
      }
      if (message.accountName == sessionStorage.getItem('accountName')) {
        message.isMyMessage = true;
      } else {
        message.isMyMessage = false;
      }
    });
    incidents.value[state.incidentIndex].messages = response.data;
    refreshingChat();
  }
  loading.value = false;
  modals.modalChat.show();
}

function setModals() {
  modals.modalNewIncident = new Modal(
    document.getElementById('modal-new-incident'),
  );
  modals.modalVideo = new Modal(document.getElementById('modal-video'));
  modals.modalIncident = new Modal(document.getElementById('modal-incident'));
  modals.modalIncidentStatus = new Modal(
    document.getElementById('modal-incident-status'),
  );
  modals.modalChat = new Modal(document.getElementById('modal-chat'));
  modals.modalAddResponsible = new Modal(
    document.getElementById('modal-new-responsible'),
  );
}

function viewIncident(index) {
  state.incidentIndex = index;
  modals.modalIncident.show();
}

function viewAddResponsible(index) {
  state.incidentIndex = index;
  modals.modalAddResponsible.show();
}

async function addResponsible() {
  loading.value = true;

  const params = {
    responsibleAccountId: state.newResponsibleAccountId,
  };

  const response = await incidentService.addResponsible({
    id: incidents.value[state.incidentIndex].id,
    params,
  });

  if (response && response.status === 201) {
    for (let i = 0; i < responsiblesFiltered.value.length; i += 1) {
      if (
        responsiblesFiltered.value[i].accountId == state.newResponsibleAccountId
      ) {
        incidents.value[state.incidentIndex].responsibleAccountName =
          responsiblesFiltered.value[i].accountName;
        incidents.value[state.incidentIndex].responsibleAccountAvatar =
          responsiblesFiltered.value[i].avatar;
        incidents.value[state.incidentIndex].responsibleAccountId =
          responsiblesFiltered.value[i].accountId;
      }
    }

    state.newResponsibleAccountId = '';
    modals.modalAddResponsible.hide();
    toast.success('Responsável adicionado com sucesso.');
  }

  loading.value = false;
}

function viewIncidentStatus(index) {
  state.incidentIndex = index;
  modals.modalIncidentStatus.show();
}

function downloadFile(attachment) {
  saveAs(attachment.attachment, attachment.name);
}

function openLightboxImage(messageIndex) {
  lightboxImage.imgs = [];

  for (
    let i = 0;
    i <
    incidents.value[state.incidentIndex].messages[messageIndex].attachments
      .length;
    i += 1
  ) {
    if (
      incidents.value[state.incidentIndex].messages[messageIndex].attachments[i]
        .type == globals.TYPE_IMAGE
    ) {
      lightboxImage.imgs.push({
        title: '',
        src: incidents.value[state.incidentIndex].messages[messageIndex]
          .attachments[i].attachment,
      });
    }
  }

  lightboxImage.visible = true;
}

function closeLightboxImage() {
  lightboxImage.visible = false;
}
</script>

<style scoped>
.border-left {
  border-left: 5px solid #f26321 !important;
}

.arrowMiddle {
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  width: 100%;
}
.form-chat .btn-secondary {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #555;
}
.message-baloon {
  border-radius: 15px;
  max-width: 60%;
  margin: 5px 0 5px 0;
}
.message-baloon small {
  margin-top: 5px;
  font-size: 12px;
}
.message-baloon img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
}
.form-chat {
  padding-top: 20px;
}

.form-chat input {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #555;
  height: 50px;
}
.form-chat input:focus {
  border: none;
  box-shadow: none;
}
.form-chat label a,
.form-chat button {
  height: 50px;
}

.message-left {
  background-color: #f0f0f0;
  color: #555;
  float: left;
  clear: both;
}
.message-left small {
  text-align: left;
}
.message-right {
  background-color: var(--blue-650);
  color: #f0f0f0;
  float: right;
  clear: both;
}
.message-right small {
  text-align: right;
}

.images-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.images-container div {
  position: relative;
}

.images-container img {
  width: 100%;
  height: 96px;
  object-fit: cover;
  border-radius: 20px;
}

.images-container .new-image {
  height: 96px;
  width: 96px;
  background: var(--white-400);
  border: 1px dashed var(--orange-450);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-button {
  position: absolute;
  width: 40px;
  height: 40px;
  background: '#FFF';
  right: 0;
  top: 0;
  cursor: pointer;
  outline: none;
  border: 1px solid var(--orange-300);
  box-sizing: border-box;
  border-radius: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-button:hover {
  box-shadow: 0px 0px 18px -2px rgb(235, 19, 19);
}

@media (max-width: 760px) {
  .images-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 760px) and (max-width: 1024px) {
  .images-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
