import { api } from '../api';
import errors from '@/utils/errors';

class InvitationService {
  async checkNDrivers({ routeDate, wave, serviceCenterId }) {
    try {
      const { data, status } = await api.get(`meli/searchDriversNotInvited/`, {
        params: {
          routeDate,
          wave,
          serviceCenterId,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar checkNDrivers.');
    }
  }

  async getRoutesToday({ query }) {
    try {
      const { data, status } = await api.get(`/meli/searchRoutes/${query}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível listar getRoutesToday.');
    }
  }

  async sendInvitations({ id, drivers }) {
    try {
      const { data, status } = await api.post(
        `/meli/invitationDriver/${id}`,
        { drivers },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível enviar convites.');
    }
  }

  async createInvitation({
    wave,
    routeDate,
    routeTime,
    numberVehicles,
    serviceCenterId,
    comments,
  }) {
    try {
      const { data, status } = await api.post(
        'meli/invitation/',
        {
          wave,
          routeDate,
          routeTime,
          numberVehicles,
          serviceCenterId,
          comments,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível criar convites.');
    }
  }

  async updateInvitation({
    id,
    wave,
    routeDate,
    routeTime,
    numberVehicles,
    serviceCenterId,
    comments,
  }) {
    try {
      const { data, status } = await api.put(
        `meli/invitation/${id}`,
        {
          wave,
          routeDate,
          routeTime,
          numberVehicles,
          serviceCenterId,
          comments,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível atualizar convites.');
    }
  }

  async deleteInvitation({ id }) {
    try {
      const { data, status } = await api.delete(`meli/invitation/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível deletar convites.');
    }
  }

  async deleteInvitationDriver({ driverId }) {
    try {
      const { status } = await api.delete(
        `/meli/invitationDriver/${driverId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      return { status };
    } catch (error) {
      errors.handler(error, 'Não foi possível deletar convite.');
    }
  }

  async filterInvitations({ date, serviceCenterId }) {
    try {
      const { data, status } = await api.get(`/meli/searchInvitations/`, {
        params: {
          date,
          serviceCenterId,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      return { data, status };
    } catch (error) {
      errors.handler(error, 'Não foi possível filtrar convites.');
    }
  }
}

export { InvitationService };
